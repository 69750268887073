.accordion-button:hover {
	background: $light;
}

.accordion-button:not(.collapsed) {
	color: $brand-primary;
	background: $accent-2;
	box-shadow: none;
	&:after {
		filter: grayscale(1);
	}
}

.accordion-button:focus {
	border-color: $accent;
	box-shadow: none;
}
