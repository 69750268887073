@import "common/variables";

/** Import everything from autoload */
@import "./autoload/_bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

/* purgecss start ignore */
@import '~aos/dist/aos.css';
@import '~@splidejs/splide/dist/css/splide.min.css';
@import '~lightgallery/css/lightgallery-bundle.min.css';

/** Import theme styles */
@import "common/fonts";
@import "common/grid";
@import "common/typography";
@import "common/color-classes";
@import "common/global";
@import "common/seo";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";

/** Import special layouts */
@import "components/accordion";
@import "components/offcanvas-contact-form";
@import "components/offcanvas-order-now-locations";
@import "components/splider";
@import "components/weed-spinner";
@import "components/link-box";
@import "components/locations-grid";
@import "components/jumbo-link-box";
@import "components/side-by-side";

// Navigation
@import "layouts/header/global";
@import "layouts/header/default";
@import "layouts/header/centered";
//@import "layouts/header/off-canvas";
@import "layouts/header/mega-menu-extension";
/* purgecss end ignore */

////////////////////////////
// Internal Page Builder
////////////////////////////

@import "range-page-builder/hero";
@import "range-page-builder/simple-content-with-image";
@import "range-page-builder/timeline";
@import "range-page-builder/flexible-content-block/column-content";
@import "range-page-builder/flexible-content-block/bootstrap-cards";
@import "range-page-builder/flexible-content-block/accordion";
@import "range-page-builder/flexible-content-block/image-slider";
@import "range-page-builder/flexible-content-block/photo-gallery";
@import "range-page-builder/internal-page";
@import "range-page-builder/customer-reviews";
@import "range-page-builder/team";
@import "range-page-builder/blog-feed";

////////////////////////////
// Core Layouts
////////////////////////////

@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/search";
@import "layouts/page-header";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/homepage";
@import "layouts/coupons";
@import "layouts/zip-code-search";

////////////////////////////
// Location Styles
////////////////////////////

@import "locations/location-map";
@import "locations/single-location";

////////////////////////////
// WooCommerce
////////////////////////////

@import "woocommerce/global";
@import "woocommerce/shop-page";
@import "woocommerce/single-product";
@import "woocommerce/cart";
@import "woocommerce/checkout";


////////////////////////////
// Cannabis Specific Styles
////////////////////////////
@import "cannabis/menu-page";
@import "layouts/agegate";

@import "layouts/headless";
