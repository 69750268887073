/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/
// set type
$menu-font: 'AkzidenzGrotesk', "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$heading-font: 'Walden', "Tinos", Georgia, Arial, serif;
$subheading-font: 'AkzidenzGrotesk', "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$paragraph-font: 'AkzidenzGrotesk', "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; // set weights
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 600;
$heavy-weight: 700;
$ultra-weight: 900;

body {
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: $body-font;
  font-family: $paragraph-font;
  font-display:swap;
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
    line-height: 1.85rem;
  }
}

a {
  color: $brand-primary;
  font-weight: $heavy-weight;
  text-decoration: none;
  font-display:swap;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;

  &:hover {
    text-decoration: none;
    color: $brand-secondary;
  }
}

.make-links-fancy a {
	color: $gray;
	font-weight: $normal-weight;
  font-display:swap;
	&:hover {
		color: $brand-primary;
	}
}

.bold,
strong {
  font-weight: 700;
}

.sub-heading {
  font-size: 1.25rem;
  font-family: $subheading-font;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  font-display:swap;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: $medium-weight;
  font-display:swap;
}

.h1,
h1 {
  font-size: 2.369rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.157rem;
  }
}

.h2,
h2 {
  font-size: 1.777rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.369rem;
  }
}

.h3,
h3 {
  font-size: 1.333rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.777rem;
  }
}

.h4,
h4 {
  font-size: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.333rem;
  }
}

.h5,
h5 {
  font-size: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

.display-1 {
  @include media-breakpoint-down(md) {
    font-size: calc(1.625rem + 3.5vw);
  }
}

.display-2 {
  @include media-breakpoint-down(md) {
    font-size: calc(1.575rem + 3vw);
  }
}

.display-3 {
  @include media-breakpoint-down(md) {
    font-size: calc(1.525rem + 2.3vw);
  }
}




// Line height

.reduce-line-height {
  line-height: 1 !important;
}

// animated links

a.animated-link,
.nav-item a,
.make-links-fancy a,
.widget_nav_menu ul li a {
	position: relative;
	overflow: hidden;
	display: inline-block;
	text-decoration: none;
	transition: color 0.3s cubic-bezier(0.5, 0.5, 0.3, 1);
	top: 7px;
  font-display:swap;
}

a.animated-link::before,
.nav-item a::before,
.make-links-fancy a::before,
.widget_nav_menu ul li a::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 4px;
	background: currentColor;
	bottom: 0;
	left: 0;
	pointer-events: none;
	transform-origin: 100% 50%;
	transform: scale3d(0, 1, 1);
	transition: transform 0.3s cubic-bezier(0.5, 0.5, 0.3, 1);
  font-display:swap;
}

a.animated-link:hover::before,
.make-links-fancy a:hover::before,
.nav-item a:hover::before,
.widget_nav_menu ul li a:hover::before {
	transform-origin: 0% 50%;
	transform: scale3d(1, 1, 1);
  font-display:swap;
}

a.btn::before {
	display: none;
  font-display:swap;
}

.page-container a:not(.btn), .page-title-container a:not(.btn) {
	font-weight: 400;
	color: $gray;
	
	&:hover {
		color: $accent;
	}
}
