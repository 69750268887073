// Main Coupon Area

.coupon-wrapper {
  .coupon-col {
    border: 0;
    box-shadow: 0;
    border-radius: 0;
    overflow: hidden;

    .coupon-outer {
      padding: $padding-light;
      border: 2px dashed $black;

      * {
        transition: $transition-med;
      }

      .coupon-inner {
        padding: 0;
        background: $light;
        border-radius: $rounded-border;
        box-shadow: 0 0 10px -3px rgba($black,0.1);
        top: 0;
        position: relative;

        .coupon-header {
          background: $dark;
          color: $white;
          margin-bottom: 1.5rem;
          padding: 1rem 0.5rem calc(0.5rem + 15px);
          border-radius: 3px 3px 0 0;
          position: relative;

          .coupon-title {
            font-family: $heading-font;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 32px;
            @include media-breakpoint-down(sm) {
              font-size: 24px;
            }
          }
        }

        .coupon-middle {
          padding: 0.25rem 1rem;
          color: $black;

          .coupon-subtitle {
            font-weight: 700;
          }

          .coupon-text {
            padding: 1rem 2rem;
            font-size: 16px;

            p {
              margin-bottom: 0;
            }
          }
        }

        .coupon-footer {
          padding: 0.25rem 2rem;

          .counpon-fine-print {
            color: darken($light, 25%);
            font-size: 12px;
            margin: 1rem auto;
          }

          .coupon-expiration {
            font-size: 16px;
            font-weight: bold;
          }
        }

        .coupon-print {
          background: $dark;
          color: white;
          padding: 5px 15px;
          position: absolute;
          bottom: 0;
          left: 0;

          &:hover {
            cursor: pointer;
          }
        }

        .coupon-type-image {}
      }

      &:hover {
        .coupon-inner {
          box-shadow: 0 0 10px -5px rgba($black,0.8);
          top: -5px;

          &:after {}
        }
      }
    }
  }
}
// PRINT COUPON CSS
@page {
  margin: 3.5cm;
}
@media print {
  .coupon-outer {
    cursor: pointer !important;
    padding: $padding-light;
    border: 2px dashed $black;
    max-width: 50%;
    margin: 25% auto;
    display: block;
    left: 0;
    right: 0;
    position: absolute;

    * {
      transition: $transition-med;
    }

    .coupon-inner {
      padding: $padding-light;
      background: $light;
      border-radius: $rounded-border;
      box-shadow: 0 0 10px -3px rgba($black,0.1);
      top: 0;

      &:after {
        content: '\2702';
        position: absolute;
        top: -10px;
        left: -10px;
        border-radius: 100%;
        background: $accent;
        height: 30px;
        width: 30px;
        vertical-align: middle;
        line-height: 30px;
        color: $white;
        transition: $transition-med;
      }

      .coupon-header {
        .coupon-title {
          font-family: $heading-font;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 32px;
        }
      }

      .coupon-middle {
        .coupon-subtitle {
          font-weight: 700;
        }
      }

      .coupon-footer {
        .counpon-fine-print {
          color: darken($light, 15%);
          font-size: 11px;
          margin: 0;
        }
      }

      .coupon-type-image {}
    }

    &:hover {
      .coupon-inner {
        box-shadow: 0 0 10px -5px rgba($black,0.8);
        top: -5px;

        &:after {}
      }
    }
  }
}
.Coupon{
  min-height: 250px;
  border:4px dashed $brand-primary;
}
.ticket {
  height: 100%;
  right: 0px;
  top: 0px;
}

.ball {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  z-index: 1;
  background-color: white;
  width: 14px;
  height: 30px;
  margin-bottom: 5px;
}

.code {
  width: fit-content;
  background-color: #E81100;
  color: white;
  border: #E81100 3px solid;
  &:hover{
    background-color: transparent;
    color: #E81100;
  }
}
