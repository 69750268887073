.footer-topper {
    height: 17rem;
    @include media-breakpoint-up(md) {
   		height: 34rem;
    }
}

.site-footer {

    .super-small {
        font-size: 13px;
    	line-height: 13px;
    }

	&.footer-style-standard {
		.footer-col {
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					padding-bottom:7.5px;
					&:last-of-type {
						padding-bottom:0;
					}
					a {
						text-decoration: none;
						font-weight: normal;
					}
				}
			}
			a {
			}
			.footer-info {
				i,svg {
					min-width:30px;
				}
			}
			.footer-social {

			}
			.gform_wrapper {
				.gform_description {
					margin-bottom:1rem;
					display:block;
				}
				.gfield_required_text {
				}
			}
// 			.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
// 				background:white;
// 				color:$brand-primary;
// 				&:hover {
// 					background:lighten($brand-primary,15%);
// 					border:4px solid lighten($brand-primary,15%);
// 				}
// 			}
		}
	}
	&.footer-style-minimal {
		.footer-col {
			.widget {
				@include media-breakpoint-up(lg) {
					display:inline;
					float:right;
				}
				div {
					@include media-breakpoint-up(lg) {
						display:inline;
					}
				}
			}
			ul {
				list-style:none;
				padding:0;
				margin:0;
				text-align:center;
				@include media-breakpoint-up(lg) {
					display:inline;
					text-align:left;
				}
				li {
					margin-bottom:1rem;
					&:last-of-type {
						margin-right:0;
						margin-bottom:0;
					}
					a {
						text-decoration: none;
						font-weight: normal;
					}
					@include media-breakpoint-up(lg) {
						display:inline;
						margin-right:1rem;
						margin-bottom:0;
					}
				}
			}
		}
	}
}

.footer-brands {
	border-top: 1px solid $light;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-top: 30px;

	img {
		max-width: 100%;
	}
}

.footer-social-link {
	&:hover {
		opacity: .8;
	}
}

#copyright {
	font-size: 18px;
	line-height: 25px;
}