.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus {
	outline: none;
}

.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
	position: absolute;
	background: transparent;
	border: none;
	color: #333;
}

.flickity-button:hover {
	background: #fff;
	cursor: pointer;
}

.flickity-button:focus {
	outline: none;
	box-shadow: none;
}

.flickity-button:active {
	opacity: 0.6;
}

.flickity-button:disabled {
	opacity: 0.3;
	cursor: auto;

	/* prevent disabled button from capturing pointer up event. #716 */
	pointer-events: none;
}

.flickity-button-icon {
	fill: currentColor;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
	top: 50%;
	width: 44px;
	height: 44px;
	border-radius: 50%;

	/* vertically center */
	transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
	left: 10px;
}

.flickity-prev-next-button.next {
	right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: -25px;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1;
}

.flickity-rtl .flickity-page-dots {
	direction: rtl;
}

.flickity-page-dots .dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 8px;
	background: #333;
	border-radius: 50%;
	opacity: 0.25;
	cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
	opacity: 1;
}



.weed-widget {
	position: relative;
	height: 38rem;
	background-color: #fff;
}

@media (min-width: 992px) {

	.weed-widget {
		height: 80vh;
		min-height: 34rem;
	}
}

.weed-widget:not(.is-admin) {
	z-index: 1010;
}

.weed-widget .flickity-button {
	background-color: transparent;
	outline: none !important;
}

@media (max-width: 991.98px) {

	.weed-widget .flickity-button {
		top: 33%;
	}
}

@media (min-width: 992px) {

	.weed-widget .flickity-button.previous {
		left: 6rem;
	}

	.weed-widget .flickity-button.next {
		right: 6rem;
	}
}

.weed-widget__title--side {
	position: absolute;
	z-index: 1020;
	font-weight: 500;
	top: 1rem;
	width: 100%;
	text-align: center;
}

@media (min-width: 992px) {

	.weed-widget__title--side {
		transform-origin: 0 0;
		transform: translateY(100%) rotate(-90deg);
		left: 1rem;
		top: 60%;
		width: auto;
		text-align: left;
	}
}

.weed-widget__name {
	font-size: 4rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.weed-widget__name h3 {
// 	font-family: "Akzidenz-Grotesk Pro", "Open Sans", "Helvetica Neue", sans-serif !important;
	font-weight: bold;
	margin: 0;
	line-height: 20rem;
	letter-spacing: -5px;
}

@media (min-width: 992px) {

	.weed-widget__name h3 {
		line-height: 25rem;
	}
}

.weed-widget__name h3 .word {
	white-space: nowrap;
}

.weed-widget__name h3 .letter {
	display: inline-block;
	line-height: 1;
	transform: rotateX(-90deg);
}

.weed-widget__arrows {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
}

.weed-widget__arrow {
	appearance: none;
	border: 0 solid;
	border-radius: 0;
	background: transparent;
	color: rgba(255, 255, 255, 0.8);
	line-height: 1;
	padding: 1rem 0.5rem;
	font-size: 2rem;
	cursor: pointer;
	outline: none;
}

.weed-widget__arrow:active {
	outline: none;
}

.weed-widget__arrow svg {
	transition: transform 150ms ease-in-out;
}

.weed-widget__arrow--left:hover svg {
	transform: translateX(-5px);
}

.weed-widget__arrow--right:hover svg {
	transform: translateX(5px);
}

.weed-widget__slides {
	width: 100%;
	overflow: hidden;
	height: 38rem;
}

@media (min-width: 992px) {

	.weed-widget__slides {
		height: 80vh;
		min-height: 34rem;
	}
}

.weed-widget__slides .weed-widget__lettering {
	opacity: 0;
}

.weed-widget__slides.flickity-enabled .weed-widget__lettering {
	opacity: 1;
}

.weed-widget__slide {
	position: relative;
	z-index: 1010;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	outline: none;
	width: 100%;
}

.weed-widget__slide .weed-widget__video {
	position: absolute;
	top: 1rem;
	bottom: 11rem;
	left: 0;
	right: 0;
	z-index: 1;
}

.weed-widget__slide .weed-widget__video video {
	width: 100%;
	height: 100%;
}

@media (min-width: 992px) {

	.weed-widget__slide .weed-widget__video {
		bottom: 0;
	}
}

.weed-widget__slide .weed-widget__lettering .word {
	white-space: nowrap;
}

.weed-widget__slide .weed-widget__lettering .letter {
	display: inline-block;
	line-height: 1;
	transform-origin: 0 0;
	transform: rotateY(-90deg);
}

.weed-widget__slide .label {
	transform: translateY(-100%);
	display: inline-block;
}

.weed-widget__slide .char-label {
	transform: translateY(100%);
	display: inline-block;
}

.weed-widget__overlay {
	position: relative;
	z-index: 2;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 38rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (min-width: 992px) {

	.weed-widget__overlay {
		height: 80vh;
		min-height: 34rem;
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

.weed-widget__overlay-top {
	padding-top: 1rem;
	padding-bottom: 1rem;
	flex-grow: 1;
}

@media (max-width: 991.98px) {

	.weed-widget__overlay-top {
		display: block;
		width: 100%;
	}
}

.weed-widget__strain {
	font-size: 1.75rem;
	font-weight: 500;
	text-align: center;
	justify-content: space-evenly;
	overflow: hidden;
}

.weed-widget__strain .char-label {
	margin-left: 1rem;
}

@media (min-width: 992px) {

	.weed-widget__strain .char-label {
		margin-left: 0;
	}
}

.weed-widget__stats {
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

@media (min-width: 992px) {

	.weed-widget__stats {
		font-size: 1.5rem;
		margin-bottom: 0;
	}
}

.weed-widget__stats .label {
	width: 5rem;
}

@media (min-width: 992px) {

	.weed-widget__stats .label {
		width: 7rem;
	}
}

.weed-widget__stats > div {
	overflow: hidden;
}

.weed-widget__stats > div .weed-widget__progress-bar--container > div {
	width: 0%;
	transition: width 750ms ease-out;
}

.weed-widget__stats > div:nth-child(1) .weed-widget__progress-bar--container > div {
	transition-delay: 150ms;
}

.weed-widget__stats > div:nth-child(2) .weed-widget__progress-bar--container > div {
	transition-delay: 300ms;
}

.weed-widget__stats > div:nth-child(3) .weed-widget__progress-bar--container > div {
	transition-delay: 450ms;
}

.weed-widget__characteristics {
	font-size: 1rem;
	font-weight: 500;
}

@media (min-width: 992px) {

	.weed-widget__characteristics {
		font-size: 1.5rem;
	}
}

.weed-widget__characteristics > div {
	overflow: hidden;
}

.weed-widget__progress-bar--container {
	position: relative;
	height: 0.5rem;
	margin-left: 1rem;
	flex-grow: 1;
}

.weed-widget__progress-bar--container > div {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: #1a1616;
}

.weed-widget.is-admin {
	height: 600px;
}

.weed-widget.is-admin .weed-widget__slides,
.weed-widget.is-admin .weed-widget__overlay {
	width: 100%;
	height: 600px;
}

.weed-widget.is-admin .weed-widget__overlay {
	pointer-events: none;
}

.weed-widget.is-admin .weed-widget__title--side {
	z-index: 1;
}

.weed-widget.is-admin .letter {
	transform: rotateY(0deg) !important;
	opacity: 1;
}

.weed-widget.is-admin .label {
	transform: translateY(0%);
}

.weed-widget.is-admin .char-label {
	transform: translateY(0%);
}
