.side-by-side-image-background-column {
	min-height: 250px;	

	@include media-breakpoint-up(lg) {
		min-height: 30vh;	
	}

	@include media-breakpoint-up(xl) {
		min-height: 35vh;	
	}

}