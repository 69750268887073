
.range-headless {
  div, * {
    border-radius:0!important;
  }
  font-family:AkzidenzGrotesk,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif!important;
  .headless-shortcode-title {
    font-family:Walden,Tinos,Georgia,Arial,serif!important;
    font-size:30px;
  }

  .headless-strain-icon {
    font-size:16px;
  }

  .headless-product-card {
    box-shadow:none!important;
    border:2px solid $accent;
    padding:0!important;

    .headless-product-title {
      font-family:Walden,Tinos,Georgia,Arial,serif!important;
      line-height:1;
      margin-bottom:10px!important;
      padding-left:15px;
      padding-right:15px;
      a {
        color:black!important;
        font-weight:bold!important;
        font-size:26px;
      }
    }

    .headless-add-to-cart {
      background:black;
      padding:21px!important;
      margin-left:-15px;
      margin-right:-15px;
      box-sizing:content-box;
      .headless-cart-button {
        @extend .btn;
        @extend .btn-primary;
        @extend .orange-button;
        @extend .btn-sm;
      }
    }
  }

  .filter-sidebar-title {
    font-family:Walden,Tinos,Georgia,Arial,serif!important;
  }

  .filter-sidebar-module {
    background:$brand-primary!important;
    color:white!important;
    border:0!important;
  }

  .filter-sidebar-options {
    label {
      margin-left: 10px;
      font-size: 17px;
    }
  }

  .headless-product-brands {
    padding-left:15px !important;
    padding-right:15px !important;
  }

  .headless-product-brands, .headless-product-categories {
    color:$accent!important;
  }

  .headless-product-bottom {
    margin-left:15px;
    margin-right:15px;
  }

  .headless-hr {
    border-color:$accent!important;
  }

  .headless-load-more {
    margin-top:15px;
  }
}
