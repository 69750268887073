// Container Fluid Adjustments
.container-fluid {
  padding-left: 25px;
  padding-right: 25px;
  @include media-breakpoint-up(md) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 75px;
    padding-right: 75px;
  }
  @include media-breakpoint-up(xxxl) {
    padding-left: 150px;
    padding-right: 150px;
  }
  @media(screen and min-width:2400px) {
    padding-left:18%;
    padding-right:18%;
  }
  @media(screen and min-width:3000px) {
    padding-left:20%;
    padding-right:20%;
  }
}

// ordering utilities
@include media-breakpoint-up(lg) {
	.order-lg-6 {
		-webkit-box-ordinal-group: 7!important;
		-ms-flex-order: 6!important;
		order: 6!important;
	}
	.order-lg-7 {
		-webkit-box-ordinal-group: 8!important;
		-ms-flex-order: 7!important;
		order: 7!important;
	}
	.order-lg-8 {
		-webkit-box-ordinal-group: 9!important;
		-ms-flex-order: 8!important;
		order: 8!important;
	}
	.order-lg-9 {
		-webkit-box-ordinal-group: 10!important;
		-ms-flex-order: 9!important;
		order: 9!important;
	}
	.order-lg-10 {
		-webkit-box-ordinal-group: 11!important;
		-ms-flex-order: 10!important;
		order: 10!important;
	}
	.order-lg-11 {
		-webkit-box-ordinal-group: 12!important;
		-ms-flex-order: 11!important;
		order: 11!important;
	}
	.order-lg-12 {
		-webkit-box-ordinal-group: 13!important;
		-ms-flex-order: 12!important;
		order: 12!important;
	}
	.order-lg-13 {
		-webkit-box-ordinal-group: 14!important;
		-ms-flex-order: 13!important;
		order: 13!important;
	}
	.order-lg-14 {
		-webkit-box-ordinal-group: 15!important;
		-ms-flex-order: 14!important;
		order: 14!important;
	}
	.order-lg-15 {
		-webkit-box-ordinal-group: 16!important;
		-ms-flex-order: 15!important;
		order: 15!important;
	}
	.order-lg-16 {
		-webkit-box-ordinal-group: 17!important;
		-ms-flex-order: 16!important;
		order: 16!important;
	}
}