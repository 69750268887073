@charset "UTF-8";

/** Import Bootstrap functions */

/** Import everything from autoload */

/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import '~aos/dist/aos.css';

@import '~@splidejs/splide/dist/css/splide.min.css';

@import '~lightgallery/css/lightgallery-bundle.min.css';

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #ff9e1b;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f5f5f5;
  --bs-dark: #1A1616;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.5rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}

a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr ;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled,
.comment-list {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid,
.wp-caption img {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure,
.wp-caption {
  display: inline-block;
}

.figure-img,
.wp-caption img {
  margin-bottom: 0.75rem;
  line-height: 1;
}

.figure-caption,
.wp-caption-text {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

@media (min-width: 1800px) {
  .container-xxxl,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1700px;
  }
}

.row,
.search-form {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.row > *,
.search-form > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1800px) {
  .col-xxxl {
    flex: 1 0 0%;
  }

  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.375rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.375rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.75rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.75rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1.5rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1.5rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 4.5rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 4.5rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 6.75rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 6.75rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 9rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 9rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 11.25rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 11.25rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 15rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 15rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 18.75rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 18.75rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 22.5rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 22.5rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 26.25rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 26.25rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 30rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 30rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 37.5rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 37.5rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 45rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 45rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.375rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.375rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 6.75rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 6.75rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 9rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 9rem;
  }

  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 11.25rem;
  }

  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 11.25rem;
  }

  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 15rem;
  }

  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 15rem;
  }

  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 18.75rem;
  }

  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 18.75rem;
  }

  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 22.5rem;
  }

  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 22.5rem;
  }

  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 26.25rem;
  }

  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 26.25rem;
  }

  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 30rem;
  }

  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 30rem;
  }

  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 37.5rem;
  }

  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 37.5rem;
  }

  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 45rem;
  }

  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 45rem;
  }
}

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.375rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.375rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 6.75rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 6.75rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 9rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 9rem;
  }

  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 11.25rem;
  }

  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 11.25rem;
  }

  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 15rem;
  }

  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 15rem;
  }

  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 18.75rem;
  }

  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 18.75rem;
  }

  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 22.5rem;
  }

  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 22.5rem;
  }

  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 26.25rem;
  }

  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 26.25rem;
  }

  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 30rem;
  }

  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 30rem;
  }

  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 37.5rem;
  }

  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 37.5rem;
  }

  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 45rem;
  }

  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 45rem;
  }
}

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.375rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.375rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 6.75rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 6.75rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 9rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 9rem;
  }

  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 11.25rem;
  }

  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 11.25rem;
  }

  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 15rem;
  }

  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 15rem;
  }

  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 18.75rem;
  }

  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 18.75rem;
  }

  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 22.5rem;
  }

  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 22.5rem;
  }

  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 26.25rem;
  }

  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 26.25rem;
  }

  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 30rem;
  }

  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 30rem;
  }

  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 37.5rem;
  }

  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 37.5rem;
  }

  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 45rem;
  }

  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 45rem;
  }
}

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.375rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.375rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 6.75rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 6.75rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 9rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 9rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 11.25rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 11.25rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 15rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 15rem;
  }

  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 18.75rem;
  }

  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 18.75rem;
  }

  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 22.5rem;
  }

  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 22.5rem;
  }

  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 26.25rem;
  }

  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 26.25rem;
  }

  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 30rem;
  }

  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 30rem;
  }

  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 37.5rem;
  }

  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 37.5rem;
  }

  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 45rem;
  }

  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 45rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.375rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.375rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 6.75rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 6.75rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 9rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 9rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 11.25rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 11.25rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 15rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 15rem;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 18.75rem;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 18.75rem;
  }

  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 22.5rem;
  }

  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 22.5rem;
  }

  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 26.25rem;
  }

  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 26.25rem;
  }

  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 30rem;
  }

  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 30rem;
  }

  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 37.5rem;
  }

  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 37.5rem;
  }

  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 45rem;
  }

  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 45rem;
  }
}

@media (min-width: 1800px) {
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxxl-0 {
    margin-left: 0;
  }

  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxxl-3 {
    margin-left: 25%;
  }

  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxxl-6 {
    margin-left: 50%;
  }

  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxxl-9 {
    margin-left: 75%;
  }

  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.375rem;
  }

  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.375rem;
  }

  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxxl-6,
  .gx-xxxl-6 {
    --bs-gutter-x: 6.75rem;
  }

  .g-xxxl-6,
  .gy-xxxl-6 {
    --bs-gutter-y: 6.75rem;
  }

  .g-xxxl-7,
  .gx-xxxl-7 {
    --bs-gutter-x: 9rem;
  }

  .g-xxxl-7,
  .gy-xxxl-7 {
    --bs-gutter-y: 9rem;
  }

  .g-xxxl-8,
  .gx-xxxl-8 {
    --bs-gutter-x: 11.25rem;
  }

  .g-xxxl-8,
  .gy-xxxl-8 {
    --bs-gutter-y: 11.25rem;
  }

  .g-xxxl-9,
  .gx-xxxl-9 {
    --bs-gutter-x: 15rem;
  }

  .g-xxxl-9,
  .gy-xxxl-9 {
    --bs-gutter-y: 15rem;
  }

  .g-xxxl-10,
  .gx-xxxl-10 {
    --bs-gutter-x: 18.75rem;
  }

  .g-xxxl-10,
  .gy-xxxl-10 {
    --bs-gutter-y: 18.75rem;
  }

  .g-xxxl-11,
  .gx-xxxl-11 {
    --bs-gutter-x: 22.5rem;
  }

  .g-xxxl-11,
  .gy-xxxl-11 {
    --bs-gutter-y: 22.5rem;
  }

  .g-xxxl-12,
  .gx-xxxl-12 {
    --bs-gutter-x: 26.25rem;
  }

  .g-xxxl-12,
  .gy-xxxl-12 {
    --bs-gutter-y: 26.25rem;
  }

  .g-xxxl-13,
  .gx-xxxl-13 {
    --bs-gutter-x: 30rem;
  }

  .g-xxxl-13,
  .gy-xxxl-13 {
    --bs-gutter-y: 30rem;
  }

  .g-xxxl-14,
  .gx-xxxl-14 {
    --bs-gutter-x: 37.5rem;
  }

  .g-xxxl-14,
  .gy-xxxl-14 {
    --bs-gutter-y: 37.5rem;
  }

  .g-xxxl-15,
  .gx-xxxl-15 {
    --bs-gutter-x: 45rem;
  }

  .g-xxxl-15,
  .gy-xxxl-15 {
    --bs-gutter-y: 45rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1.5rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f5f5f5;
  --bs-table-striped-bg: #e9e9e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dddddd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3e3e3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dddddd;
}

.table-dark {
  --bs-table-bg: #1A1616;
  --bs-table-striped-bg: #252222;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #312d2d;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2b2727;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #312d2d;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1799.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control,
.search-form .search-field,
.comment-form input[type=text],
.comment-form input[type=email],
.comment-form input[type=url],
.comment-form textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control,
  .search-form .search-field,
  .comment-form input[type=text],
  .comment-form input[type=email],
  .comment-form input[type=url],
  .comment-form textarea {
    transition: none;
  }
}

.form-control[type=file],
.search-form [type=file].search-field,
.comment-form input[type=file][type=text],
.comment-form input[type=file][type=email],
.comment-form input[type=file][type=url],
.comment-form textarea[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]),
.search-form [type=file].search-field:not(:disabled):not([readonly]),
.comment-form input[type=file][type=text]:not(:disabled):not([readonly]),
.comment-form input[type=file][type=email]:not(:disabled):not([readonly]),
.comment-form input[type=file][type=url]:not(:disabled):not([readonly]),
.comment-form textarea[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus,
.search-form .search-field:focus,
.comment-form input[type=text]:focus,
.comment-form input[type=email]:focus,
.comment-form input[type=url]:focus,
.comment-form textarea:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value,
.search-form .search-field::-webkit-date-and-time-value,
.comment-form input[type=text]::-webkit-date-and-time-value,
.comment-form input[type=email]::-webkit-date-and-time-value,
.comment-form input[type=url]::-webkit-date-and-time-value,
.comment-form textarea::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder,
.search-form .search-field::placeholder,
.comment-form input[type=text]::placeholder,
.comment-form input[type=email]::placeholder,
.comment-form input[type=url]::placeholder,
.comment-form textarea::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.search-form .search-field:disabled,
.comment-form input[type=text]:disabled,
.comment-form input[type=email]:disabled,
.comment-form input[type=url]:disabled,
.comment-form textarea:disabled,
.form-control[readonly],
.search-form [readonly].search-field,
.comment-form input[readonly][type=text],
.comment-form input[readonly][type=email],
.comment-form input[readonly][type=url],
.comment-form textarea[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button,
.search-form .search-field::file-selector-button,
.comment-form input[type=text]::file-selector-button,
.comment-form input[type=email]::file-selector-button,
.comment-form input[type=url]::file-selector-button,
.comment-form textarea::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button,
  .search-form .search-field::file-selector-button,
  .comment-form input[type=text]::file-selector-button,
  .comment-form input[type=email]::file-selector-button,
  .comment-form input[type=url]::file-selector-button,
  .comment-form textarea::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.search-form .search-field:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-form input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-form input[type=email]:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-form input[type=url]:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-form textarea:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button,
.search-form .search-field::-webkit-file-upload-button,
.comment-form input[type=text]::-webkit-file-upload-button,
.comment-form input[type=email]::-webkit-file-upload-button,
.comment-form input[type=url]::-webkit-file-upload-button,
.comment-form textarea::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button,
  .search-form .search-field::-webkit-file-upload-button,
  .comment-form input[type=text]::-webkit-file-upload-button,
  .comment-form input[type=email]::-webkit-file-upload-button,
  .comment-form input[type=url]::-webkit-file-upload-button,
  .comment-form textarea::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.search-form .search-field:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-form input[type=text]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-form input[type=email]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-form input[type=url]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-form textarea:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control,
.search-form textarea.search-field,
.comment-form textarea {
  min-height: calc(1.5em + (0.75rem + 2px));
}

textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-check[disabled] + .headless-cart-button,
.search-form .btn-check[disabled] + .search-submit,
.comment-form .btn-check[disabled] + input[type=submit],
.btn-check:disabled + .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-check:disabled + .headless-cart-button,
.search-form .btn-check:disabled + .search-submit,
.comment-form .btn-check:disabled + input[type=submit] {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.search-form .form-floating > .search-field,
.comment-form .form-floating > input[type=text],
.comment-form .form-floating > input[type=email],
.comment-form .form-floating > input[type=url],
.comment-form .form-floating > textarea,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control,
.search-form .form-floating > .search-field,
.comment-form .form-floating > input[type=text],
.comment-form .form-floating > input[type=email],
.comment-form .form-floating > input[type=url],
.comment-form .form-floating > textarea {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder,
.search-form .form-floating > .search-field::placeholder,
.comment-form .form-floating > input[type=text]::placeholder,
.comment-form .form-floating > input[type=email]::placeholder,
.comment-form .form-floating > input[type=url]::placeholder,
.comment-form .form-floating > textarea::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus,
.search-form .form-floating > .search-field:focus,
.comment-form .form-floating > input[type=text]:focus,
.comment-form .form-floating > input[type=email]:focus,
.comment-form .form-floating > input[type=url]:focus,
.comment-form .form-floating > textarea:focus,
.form-floating > .form-control:not(:placeholder-shown),
.search-form .form-floating > .search-field:not(:placeholder-shown),
.comment-form .form-floating > input[type=text]:not(:placeholder-shown),
.comment-form .form-floating > input[type=email]:not(:placeholder-shown),
.comment-form .form-floating > input[type=url]:not(:placeholder-shown),
.comment-form .form-floating > textarea:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill,
.search-form .form-floating > .search-field:-webkit-autofill,
.comment-form .form-floating > input[type=text]:-webkit-autofill,
.comment-form .form-floating > input[type=email]:-webkit-autofill,
.comment-form .form-floating > input[type=url]:-webkit-autofill,
.comment-form .form-floating > textarea:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.search-form .form-floating > .search-field:focus ~ label,
.comment-form .form-floating > input[type=text]:focus ~ label,
.comment-form .form-floating > input[type=email]:focus ~ label,
.comment-form .form-floating > input[type=url]:focus ~ label,
.comment-form .form-floating > textarea:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.search-form .form-floating > .search-field:not(:placeholder-shown) ~ label,
.comment-form .form-floating > input[type=text]:not(:placeholder-shown) ~ label,
.comment-form .form-floating > input[type=email]:not(:placeholder-shown) ~ label,
.comment-form .form-floating > input[type=url]:not(:placeholder-shown) ~ label,
.comment-form .form-floating > textarea:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label,
.search-form .form-floating > .search-field:-webkit-autofill ~ label,
.comment-form .form-floating > input[type=text]:-webkit-autofill ~ label,
.comment-form .form-floating > input[type=email]:-webkit-autofill ~ label,
.comment-form .form-floating > input[type=url]:-webkit-autofill ~ label,
.comment-form .form-floating > textarea:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.search-form .input-group > .search-field,
.comment-form .input-group > input[type=text],
.comment-form .input-group > input[type=email],
.comment-form .input-group > input[type=url],
.comment-form .input-group > textarea,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.search-form .input-group > .search-field:focus,
.comment-form .input-group > input[type=text]:focus,
.comment-form .input-group > input[type=email]:focus,
.comment-form .input-group > input[type=url]:focus,
.comment-form .input-group > textarea:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn,
.input-group .range-headless .headless-product-card .headless-add-to-cart .headless-cart-button,
.range-headless .headless-product-card .headless-add-to-cart .input-group .headless-cart-button,
.input-group .search-form .search-submit,
.search-form .input-group .search-submit,
.input-group .comment-form input[type=submit],
.comment-form .input-group input[type=submit] {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus,
.input-group .range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:focus,
.range-headless .headless-product-card .headless-add-to-cart .input-group .headless-cart-button:focus,
.input-group .search-form .search-submit:focus,
.search-form .input-group .search-submit:focus,
.input-group .comment-form input[type=submit]:focus,
.comment-form .input-group input[type=submit]:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.search-form .input-group-lg > .search-field,
.comment-form .input-group-lg > input[type=text],
.comment-form .input-group-lg > input[type=email],
.comment-form .input-group-lg > input[type=url],
.comment-form .input-group-lg > textarea,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.range-headless .headless-product-card .headless-add-to-cart .input-group-lg > .headless-cart-button,
.search-form .input-group-lg > .search-submit,
.comment-form .input-group-lg > input[type=submit] {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.search-form .input-group-sm > .search-field,
.comment-form .input-group-sm > input[type=text],
.comment-form .input-group-sm > input[type=email],
.comment-form .input-group-sm > input[type=url],
.comment-form .input-group-sm > textarea,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.range-headless .headless-product-card .headless-add-to-cart .input-group-sm > .headless-cart-button,
.search-form .input-group-sm > .search-submit,
.comment-form .input-group-sm > input[type=submit] {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated .search-form .search-field:valid,
.search-form .was-validated .search-field:valid,
.was-validated .comment-form input[type=text]:valid,
.comment-form .was-validated input[type=text]:valid,
.was-validated .comment-form input[type=email]:valid,
.comment-form .was-validated input[type=email]:valid,
.was-validated .comment-form input[type=url]:valid,
.comment-form .was-validated input[type=url]:valid,
.was-validated .comment-form textarea:valid,
.comment-form .was-validated textarea:valid,
.form-control.is-valid,
.search-form .is-valid.search-field,
.comment-form input.is-valid[type=text],
.comment-form input.is-valid[type=email],
.comment-form input.is-valid[type=url],
.comment-form textarea.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.was-validated .search-form .search-field:valid:focus,
.search-form .was-validated .search-field:valid:focus,
.was-validated .comment-form input[type=text]:valid:focus,
.comment-form .was-validated input[type=text]:valid:focus,
.was-validated .comment-form input[type=email]:valid:focus,
.comment-form .was-validated input[type=email]:valid:focus,
.was-validated .comment-form input[type=url]:valid:focus,
.comment-form .was-validated input[type=url]:valid:focus,
.was-validated .comment-form textarea:valid:focus,
.comment-form .was-validated textarea:valid:focus,
.form-control.is-valid:focus,
.search-form .is-valid.search-field:focus,
.comment-form input.is-valid[type=text]:focus,
.comment-form input.is-valid[type=email]:focus,
.comment-form input.is-valid[type=url]:focus,
.comment-form textarea.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated .search-form textarea.search-field:valid,
.search-form .was-validated textarea.search-field:valid,
.was-validated .comment-form textarea:valid,
.comment-form .was-validated textarea:valid,
textarea.form-control.is-valid,
.search-form textarea.is-valid.search-field,
.comment-form textarea.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.was-validated .input-group .search-form .search-field:valid,
.search-form .was-validated .input-group .search-field:valid,
.was-validated .input-group .comment-form input[type=text]:valid,
.comment-form .was-validated .input-group input[type=text]:valid,
.was-validated .input-group .comment-form input[type=email]:valid,
.comment-form .was-validated .input-group input[type=email]:valid,
.was-validated .input-group .comment-form input[type=url]:valid,
.comment-form .was-validated .input-group input[type=url]:valid,
.was-validated .input-group .comment-form textarea:valid,
.comment-form .was-validated .input-group textarea:valid,
.input-group .form-control.is-valid,
.input-group .search-form .is-valid.search-field,
.search-form .input-group .is-valid.search-field,
.input-group .comment-form input.is-valid[type=text],
.comment-form .input-group input.is-valid[type=text],
.input-group .comment-form input.is-valid[type=email],
.comment-form .input-group input.is-valid[type=email],
.input-group .comment-form input.is-valid[type=url],
.comment-form .input-group input.is-valid[type=url],
.input-group .comment-form textarea.is-valid,
.comment-form .input-group textarea.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .search-form .search-field:valid:focus,
.search-form .was-validated .input-group .search-field:valid:focus,
.was-validated .input-group .comment-form input[type=text]:valid:focus,
.comment-form .was-validated .input-group input[type=text]:valid:focus,
.was-validated .input-group .comment-form input[type=email]:valid:focus,
.comment-form .was-validated .input-group input[type=email]:valid:focus,
.was-validated .input-group .comment-form input[type=url]:valid:focus,
.comment-form .was-validated .input-group input[type=url]:valid:focus,
.was-validated .input-group .comment-form textarea:valid:focus,
.comment-form .was-validated .input-group textarea:valid:focus,
.input-group .form-control.is-valid:focus,
.input-group .search-form .is-valid.search-field:focus,
.search-form .input-group .is-valid.search-field:focus,
.input-group .comment-form input.is-valid[type=text]:focus,
.comment-form .input-group input.is-valid[type=text]:focus,
.input-group .comment-form input.is-valid[type=email]:focus,
.comment-form .input-group input.is-valid[type=email]:focus,
.input-group .comment-form input.is-valid[type=url]:focus,
.comment-form .input-group input.is-valid[type=url]:focus,
.input-group .comment-form textarea.is-valid:focus,
.comment-form .input-group textarea.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated .search-form .search-field:invalid,
.search-form .was-validated .search-field:invalid,
.was-validated .comment-form input[type=text]:invalid,
.comment-form .was-validated input[type=text]:invalid,
.was-validated .comment-form input[type=email]:invalid,
.comment-form .was-validated input[type=email]:invalid,
.was-validated .comment-form input[type=url]:invalid,
.comment-form .was-validated input[type=url]:invalid,
.was-validated .comment-form textarea:invalid,
.comment-form .was-validated textarea:invalid,
.form-control.is-invalid,
.search-form .is-invalid.search-field,
.comment-form input.is-invalid[type=text],
.comment-form input.is-invalid[type=email],
.comment-form input.is-invalid[type=url],
.comment-form textarea.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.was-validated .search-form .search-field:invalid:focus,
.search-form .was-validated .search-field:invalid:focus,
.was-validated .comment-form input[type=text]:invalid:focus,
.comment-form .was-validated input[type=text]:invalid:focus,
.was-validated .comment-form input[type=email]:invalid:focus,
.comment-form .was-validated input[type=email]:invalid:focus,
.was-validated .comment-form input[type=url]:invalid:focus,
.comment-form .was-validated input[type=url]:invalid:focus,
.was-validated .comment-form textarea:invalid:focus,
.comment-form .was-validated textarea:invalid:focus,
.form-control.is-invalid:focus,
.search-form .is-invalid.search-field:focus,
.comment-form input.is-invalid[type=text]:focus,
.comment-form input.is-invalid[type=email]:focus,
.comment-form input.is-invalid[type=url]:focus,
.comment-form textarea.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated .search-form textarea.search-field:invalid,
.search-form .was-validated textarea.search-field:invalid,
.was-validated .comment-form textarea:invalid,
.comment-form .was-validated textarea:invalid,
textarea.form-control.is-invalid,
.search-form textarea.is-invalid.search-field,
.comment-form textarea.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.was-validated .input-group .search-form .search-field:invalid,
.search-form .was-validated .input-group .search-field:invalid,
.was-validated .input-group .comment-form input[type=text]:invalid,
.comment-form .was-validated .input-group input[type=text]:invalid,
.was-validated .input-group .comment-form input[type=email]:invalid,
.comment-form .was-validated .input-group input[type=email]:invalid,
.was-validated .input-group .comment-form input[type=url]:invalid,
.comment-form .was-validated .input-group input[type=url]:invalid,
.was-validated .input-group .comment-form textarea:invalid,
.comment-form .was-validated .input-group textarea:invalid,
.input-group .form-control.is-invalid,
.input-group .search-form .is-invalid.search-field,
.search-form .input-group .is-invalid.search-field,
.input-group .comment-form input.is-invalid[type=text],
.comment-form .input-group input.is-invalid[type=text],
.input-group .comment-form input.is-invalid[type=email],
.comment-form .input-group input.is-invalid[type=email],
.input-group .comment-form input.is-invalid[type=url],
.comment-form .input-group input.is-invalid[type=url],
.input-group .comment-form textarea.is-invalid,
.comment-form .input-group textarea.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .search-form .search-field:invalid:focus,
.search-form .was-validated .input-group .search-field:invalid:focus,
.was-validated .input-group .comment-form input[type=text]:invalid:focus,
.comment-form .was-validated .input-group input[type=text]:invalid:focus,
.was-validated .input-group .comment-form input[type=email]:invalid:focus,
.comment-form .was-validated .input-group input[type=email]:invalid:focus,
.was-validated .input-group .comment-form input[type=url]:invalid:focus,
.comment-form .was-validated .input-group input[type=url]:invalid:focus,
.was-validated .input-group .comment-form textarea:invalid:focus,
.comment-form .was-validated .input-group textarea:invalid:focus,
.input-group .form-control.is-invalid:focus,
.input-group .search-form .is-invalid.search-field:focus,
.search-form .input-group .is-invalid.search-field:focus,
.input-group .comment-form input.is-invalid[type=text]:focus,
.comment-form .input-group input.is-invalid[type=text]:focus,
.input-group .comment-form input.is-invalid[type=email]:focus,
.comment-form .input-group input.is-invalid[type=email]:focus,
.input-group .comment-form input.is-invalid[type=url]:focus,
.comment-form .input-group input.is-invalid[type=url]:focus,
.input-group .comment-form textarea.is-invalid:focus,
.comment-form .input-group textarea.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button,
.search-form .search-submit,
.comment-form input[type=submit] {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn,
  .range-headless .headless-product-card .headless-add-to-cart .headless-cart-button,
  .search-form .search-submit,
  .comment-form input[type=submit] {
    transition: none;
  }
}

.btn:hover,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:hover,
.search-form .search-submit:hover,
.comment-form input[type=submit]:hover {
  color: #212529;
}

.btn-check:focus + .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-check:focus + .headless-cart-button,
.search-form .btn-check:focus + .search-submit,
.comment-form .btn-check:focus + input[type=submit],
.btn:focus,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:focus,
.search-form .search-submit:focus,
.comment-form input[type=submit]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn:disabled,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:disabled,
.search-form .search-submit:disabled,
.comment-form input[type=submit]:disabled,
.btn.disabled,
.range-headless .headless-product-card .headless-add-to-cart .disabled.headless-cart-button,
.search-form .disabled.search-submit,
.comment-form input.disabled[type=submit],
fieldset:disabled .btn,
fieldset:disabled .range-headless .headless-product-card .headless-add-to-cart .headless-cart-button,
.range-headless .headless-product-card .headless-add-to-cart fieldset:disabled .headless-cart-button,
fieldset:disabled .search-form .search-submit,
.search-form fieldset:disabled .search-submit,
fieldset:disabled .comment-form input[type=submit],
.comment-form fieldset:disabled input[type=submit] {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.btn-check:focus + .btn-primary,
.range-headless .headless-product-card .headless-add-to-cart .btn-check:focus + .headless-cart-button,
.btn-primary:focus,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-check:checked + .btn-primary,
.range-headless .headless-product-card .headless-add-to-cart .btn-check:checked + .headless-cart-button,
.btn-check:active + .btn-primary,
.range-headless .headless-product-card .headless-add-to-cart .btn-check:active + .headless-cart-button,
.btn-primary:active,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:active,
.btn-primary.active,
.range-headless .headless-product-card .headless-add-to-cart .active.headless-cart-button,
.show > .btn-primary.dropdown-toggle,
.range-headless .headless-product-card .headless-add-to-cart .show > .dropdown-toggle.headless-cart-button {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}

.btn-check:checked + .btn-primary:focus,
.range-headless .headless-product-card .headless-add-to-cart .btn-check:checked + .headless-cart-button:focus,
.btn-check:active + .btn-primary:focus,
.range-headless .headless-product-card .headless-add-to-cart .btn-check:active + .headless-cart-button:focus,
.btn-primary:active:focus,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:active:focus,
.btn-primary.active:focus,
.range-headless .headless-product-card .headless-add-to-cart .active.headless-cart-button:focus,
.show > .btn-primary.dropdown-toggle:focus,
.range-headless .headless-product-card .headless-add-to-cart .show > .dropdown-toggle.headless-cart-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-primary:disabled,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:disabled,
.btn-primary.disabled,
.range-headless .headless-product-card .headless-add-to-cart .disabled.headless-cart-button {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary,
.search-form .search-submit,
.comment-form input[type=submit] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover,
.search-form .search-submit:hover,
.comment-form input[type=submit]:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary,
.search-form .btn-check:focus + .search-submit,
.comment-form .btn-check:focus + input[type=submit],
.btn-secondary:focus,
.search-form .search-submit:focus,
.comment-form input[type=submit]:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-check:checked + .btn-secondary,
.search-form .btn-check:checked + .search-submit,
.comment-form .btn-check:checked + input[type=submit],
.btn-check:active + .btn-secondary,
.search-form .btn-check:active + .search-submit,
.comment-form .btn-check:active + input[type=submit],
.btn-secondary:active,
.search-form .search-submit:active,
.comment-form input[type=submit]:active,
.btn-secondary.active,
.search-form .active.search-submit,
.comment-form input.active[type=submit],
.show > .btn-secondary.dropdown-toggle,
.search-form .show > .dropdown-toggle.search-submit,
.comment-form .show > input.dropdown-toggle[type=submit] {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:checked + .btn-secondary:focus,
.search-form .btn-check:checked + .search-submit:focus,
.comment-form .btn-check:checked + input[type=submit]:focus,
.btn-check:active + .btn-secondary:focus,
.search-form .btn-check:active + .search-submit:focus,
.comment-form .btn-check:active + input[type=submit]:focus,
.btn-secondary:active:focus,
.search-form .search-submit:active:focus,
.comment-form input[type=submit]:active:focus,
.btn-secondary.active:focus,
.search-form .active.search-submit:focus,
.comment-form input.active[type=submit]:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.search-form .show > .dropdown-toggle.search-submit:focus,
.comment-form .show > input.dropdown-toggle[type=submit]:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled,
.search-form .search-submit:disabled,
.comment-form input[type=submit]:disabled,
.btn-secondary.disabled,
.search-form .disabled.search-submit,
.comment-form input.disabled[type=submit] {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-light:hover {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
  box-shadow: 0 0 0 0.25rem rgba(208, 208, 208, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(208, 208, 208, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-dark {
  color: #fff;
  background-color: #1A1616;
  border-color: #1A1616;
}

.btn-dark:hover {
  color: #fff;
  background-color: #161313;
  border-color: #151212;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #161313;
  border-color: #151212;
  box-shadow: 0 0 0 0.25rem rgba(60, 57, 57, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #151212;
  border-color: #141111;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 57, 57, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #1A1616;
  border-color: #1A1616;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 245, 245, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 245, 245, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #f5f5f5;
  background-color: transparent;
}

.btn-outline-dark {
  color: #1A1616;
  border-color: #1A1616;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #1A1616;
  border-color: #1A1616;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 22, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #1A1616;
  border-color: #1A1616;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 22, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #1A1616;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0a58ca;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-lg > .headless-cart-button,
.search-form .btn-group-lg > .search-submit,
.comment-form .btn-group-lg > input[type=submit] {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button,
.btn-group-sm > .btn,
.search-form .btn-group-sm > .search-submit,
.comment-form .btn-group-sm > input[type=submit] {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1800px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .headless-cart-button,
.search-form .btn-group > .search-submit,
.comment-form .btn-group > input[type=submit],
.btn-group-vertical > .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button,
.search-form .btn-group-vertical > .search-submit,
.comment-form .btn-group-vertical > input[type=submit] {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .btn-check:checked + .headless-cart-button,
.search-form .btn-group > .btn-check:checked + .search-submit,
.comment-form .btn-group > .btn-check:checked + input[type=submit],
.btn-group > .btn-check:focus + .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .btn-check:focus + .headless-cart-button,
.search-form .btn-group > .btn-check:focus + .search-submit,
.comment-form .btn-group > .btn-check:focus + input[type=submit],
.btn-group > .btn:hover,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .headless-cart-button:hover,
.search-form .btn-group > .search-submit:hover,
.comment-form .btn-group > input[type=submit]:hover,
.btn-group > .btn:focus,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .headless-cart-button:focus,
.search-form .btn-group > .search-submit:focus,
.comment-form .btn-group > input[type=submit]:focus,
.btn-group > .btn:active,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .headless-cart-button:active,
.search-form .btn-group > .search-submit:active,
.comment-form .btn-group > input[type=submit]:active,
.btn-group > .btn.active,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .active.headless-cart-button,
.search-form .btn-group > .active.search-submit,
.comment-form .btn-group > input.active[type=submit],
.btn-group-vertical > .btn-check:checked + .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .btn-check:checked + .headless-cart-button,
.search-form .btn-group-vertical > .btn-check:checked + .search-submit,
.comment-form .btn-group-vertical > .btn-check:checked + input[type=submit],
.btn-group-vertical > .btn-check:focus + .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .btn-check:focus + .headless-cart-button,
.search-form .btn-group-vertical > .btn-check:focus + .search-submit,
.comment-form .btn-group-vertical > .btn-check:focus + input[type=submit],
.btn-group-vertical > .btn:hover,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button:hover,
.search-form .btn-group-vertical > .search-submit:hover,
.comment-form .btn-group-vertical > input[type=submit]:hover,
.btn-group-vertical > .btn:focus,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button:focus,
.search-form .btn-group-vertical > .search-submit:focus,
.comment-form .btn-group-vertical > input[type=submit]:focus,
.btn-group-vertical > .btn:active,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button:active,
.search-form .btn-group-vertical > .search-submit:active,
.comment-form .btn-group-vertical > input[type=submit]:active,
.btn-group-vertical > .btn.active,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .active.headless-cart-button,
.search-form .btn-group-vertical > .active.search-submit,
.comment-form .btn-group-vertical > input.active[type=submit] {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .headless-cart-button:not(:first-child),
.search-form .btn-group > .search-submit:not(:first-child),
.comment-form .btn-group > input[type=submit]:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .headless-cart-button:not(:last-child):not(.dropdown-toggle),
.search-form .btn-group > .search-submit:not(:last-child):not(.dropdown-toggle),
.comment-form .btn-group > input[type=submit]:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .btn-group:not(:last-child) > .headless-cart-button,
.search-form .btn-group > .btn-group:not(:last-child) > .search-submit,
.comment-form .btn-group > .btn-group:not(:last-child) > input[type=submit] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3),
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .headless-cart-button:nth-child(n+3),
.search-form .btn-group > .search-submit:nth-child(n+3),
.comment-form .btn-group > input[type=submit]:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > :not(.btn-check) + .headless-cart-button,
.search-form .btn-group > :not(.btn-check) + .search-submit,
.comment-form .btn-group > :not(.btn-check) + input[type=submit],
.btn-group > .btn-group:not(:first-child) > .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group > .btn-group:not(:first-child) > .headless-cart-button,
.search-form .btn-group > .btn-group:not(:first-child) > .search-submit,
.comment-form .btn-group > .btn-group:not(:first-child) > input[type=submit] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-sm > .headless-cart-button + .dropdown-toggle-split,
.search-form .btn-group-sm > .search-submit + .dropdown-toggle-split,
.comment-form .btn-group-sm > input[type=submit] + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-lg > .headless-cart-button + .dropdown-toggle-split,
.search-form .btn-group-lg > .search-submit + .dropdown-toggle-split,
.comment-form .btn-group-lg > input[type=submit] + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button,
.search-form .btn-group-vertical > .search-submit,
.comment-form .btn-group-vertical > input[type=submit],
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button:not(:first-child),
.search-form .btn-group-vertical > .search-submit:not(:first-child),
.comment-form .btn-group-vertical > input[type=submit]:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button:not(:last-child):not(.dropdown-toggle),
.search-form .btn-group-vertical > .search-submit:not(:last-child):not(.dropdown-toggle),
.comment-form .btn-group-vertical > input[type=submit]:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .btn-group:not(:last-child) > .headless-cart-button,
.search-form .btn-group-vertical > .btn-group:not(:last-child) > .search-submit,
.comment-form .btn-group-vertical > .btn-group:not(:last-child) > input[type=submit] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button ~ .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .btn ~ .headless-cart-button,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button ~ .headless-cart-button,
.search-form .btn-group-vertical > .search-submit ~ .btn,
.search-form .range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .search-submit ~ .headless-cart-button,
.range-headless .headless-product-card .headless-add-to-cart .search-form .btn-group-vertical > .search-submit ~ .headless-cart-button,
.search-form .btn-group-vertical > .btn ~ .search-submit,
.search-form .range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button ~ .search-submit,
.range-headless .headless-product-card .headless-add-to-cart .search-form .btn-group-vertical > .headless-cart-button ~ .search-submit,
.search-form .btn-group-vertical > .search-submit ~ .search-submit,
.comment-form .btn-group-vertical > input[type=submit] ~ .btn,
.comment-form .range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > input[type=submit] ~ .headless-cart-button,
.range-headless .headless-product-card .headless-add-to-cart .comment-form .btn-group-vertical > input[type=submit] ~ .headless-cart-button,
.comment-form .search-form .btn-group-vertical > input[type=submit] ~ .search-submit,
.search-form .comment-form .btn-group-vertical > input[type=submit] ~ .search-submit,
.comment-form .btn-group-vertical > .btn ~ input[type=submit],
.comment-form .range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .headless-cart-button ~ input[type=submit],
.range-headless .headless-product-card .headless-add-to-cart .comment-form .btn-group-vertical > .headless-cart-button ~ input[type=submit],
.comment-form .search-form .btn-group-vertical > .search-submit ~ input[type=submit],
.search-form .comment-form .btn-group-vertical > .search-submit ~ input[type=submit],
.comment-form .btn-group-vertical > input[type=submit] ~ input[type=submit],
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.range-headless .headless-product-card .headless-add-to-cart .btn-group-vertical > .btn-group:not(:first-child) > .headless-cart-button,
.search-form .btn-group-vertical > .btn-group:not(:first-child) > .search-submit,
.comment-form .btn-group-vertical > .btn-group:not(:first-child) > input[type=submit] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: #0a58ca;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl,
.navbar > .container-xxxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1800px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") ;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button .badge,
.search-form .search-submit .badge,
.comment-form input[type=submit] .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1.5rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.5rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.875rem 1.5rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #626262;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.alert-light .alert-link {
  color: #4e4e4e;
}

.alert-dark {
  color: #100d0d;
  background-color: #d1d0d0;
  border-color: #bab9b9;
}

.alert-dark .alert-link {
  color: #0d0a0a;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.5rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1800px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #626262;
  background-color: #fdfdfd;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #626262;
  background-color: #e4e4e4;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #626262;
  border-color: #626262;
}

.list-group-item-dark {
  color: #100d0d;
  background-color: #d1d0d0;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #100d0d;
  background-color: #bcbbbb;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #100d0d;
  border-color: #100d0d;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.75rem 0.75rem;
  margin: -0.75rem -0.75rem -0.75rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1799.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.375rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 ;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1.5rem 1.5rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) ;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
}

.offcanvas-header .btn-close {
  padding: 0.75rem 0.75rem;
  margin-top: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1.5rem 1.5rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}

.link-primary:hover,
.link-primary:focus {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}

.link-success:hover,
.link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover,
.link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover,
.link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover,
.link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f5f5f5;
}

.link-light:hover,
.link-light:focus {
  color: #f7f7f7;
}

.link-dark {
  color: #1A1616;
}

.link-dark:hover,
.link-dark:focus {
  color: #151212;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1800px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.screen-reader-text,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.border-dark {
  border-color: #1A1616 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.375rem !important;
}

.gap-2 {
  gap: 0.75rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 4.5rem !important;
}

.gap-6 {
  gap: 6.75rem !important;
}

.gap-7 {
  gap: 9rem !important;
}

.gap-8 {
  gap: 11.25rem !important;
}

.gap-9 {
  gap: 15rem !important;
}

.gap-10 {
  gap: 18.75rem !important;
}

.gap-11 {
  gap: 22.5rem !important;
}

.gap-12 {
  gap: 26.25rem !important;
}

.gap-13 {
  gap: 30rem !important;
}

.gap-14 {
  gap: 37.5rem !important;
}

.gap-15 {
  gap: 45rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.m-6 {
  margin: 6.75rem !important;
}

.m-7 {
  margin: 9rem !important;
}

.m-8 {
  margin: 11.25rem !important;
}

.m-9 {
  margin: 15rem !important;
}

.m-10 {
  margin: 18.75rem !important;
}

.m-11 {
  margin: 22.5rem !important;
}

.m-12 {
  margin: 26.25rem !important;
}

.m-13 {
  margin: 30rem !important;
}

.m-14 {
  margin: 37.5rem !important;
}

.m-15 {
  margin: 45rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}

.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-5 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-6 {
  margin-right: 6.75rem !important;
  margin-left: 6.75rem !important;
}

.mx-7 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-8 {
  margin-right: 11.25rem !important;
  margin-left: 11.25rem !important;
}

.mx-9 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.mx-10 {
  margin-right: 18.75rem !important;
  margin-left: 18.75rem !important;
}

.mx-11 {
  margin-right: 22.5rem !important;
  margin-left: 22.5rem !important;
}

.mx-12 {
  margin-right: 26.25rem !important;
  margin-left: 26.25rem !important;
}

.mx-13 {
  margin-right: 30rem !important;
  margin-left: 30rem !important;
}

.mx-14 {
  margin-right: 37.5rem !important;
  margin-left: 37.5rem !important;
}

.mx-15 {
  margin-right: 45rem !important;
  margin-left: 45rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-6 {
  margin-top: 6.75rem !important;
  margin-bottom: 6.75rem !important;
}

.my-7 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-8 {
  margin-top: 11.25rem !important;
  margin-bottom: 11.25rem !important;
}

.my-9 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.my-10 {
  margin-top: 18.75rem !important;
  margin-bottom: 18.75rem !important;
}

.my-11 {
  margin-top: 22.5rem !important;
  margin-bottom: 22.5rem !important;
}

.my-12 {
  margin-top: 26.25rem !important;
  margin-bottom: 26.25rem !important;
}

.my-13 {
  margin-top: 30rem !important;
  margin-bottom: 30rem !important;
}

.my-14 {
  margin-top: 37.5rem !important;
  margin-bottom: 37.5rem !important;
}

.my-15 {
  margin-top: 45rem !important;
  margin-bottom: 45rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.375rem !important;
}

.mt-2 {
  margin-top: 0.75rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 4.5rem !important;
}

.mt-6 {
  margin-top: 6.75rem !important;
}

.mt-7 {
  margin-top: 9rem !important;
}

.mt-8 {
  margin-top: 11.25rem !important;
}

.mt-9 {
  margin-top: 15rem !important;
}

.mt-10 {
  margin-top: 18.75rem !important;
}

.mt-11 {
  margin-top: 22.5rem !important;
}

.mt-12 {
  margin-top: 26.25rem !important;
}

.mt-13 {
  margin-top: 30rem !important;
}

.mt-14 {
  margin-top: 37.5rem !important;
}

.mt-15 {
  margin-top: 45rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.375rem !important;
}

.me-2 {
  margin-right: 0.75rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 4.5rem !important;
}

.me-6 {
  margin-right: 6.75rem !important;
}

.me-7 {
  margin-right: 9rem !important;
}

.me-8 {
  margin-right: 11.25rem !important;
}

.me-9 {
  margin-right: 15rem !important;
}

.me-10 {
  margin-right: 18.75rem !important;
}

.me-11 {
  margin-right: 22.5rem !important;
}

.me-12 {
  margin-right: 26.25rem !important;
}

.me-13 {
  margin-right: 30rem !important;
}

.me-14 {
  margin-right: 37.5rem !important;
}

.me-15 {
  margin-right: 45rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.375rem !important;
}

.mb-2 {
  margin-bottom: 0.75rem !important;
}

.mb-3,
.search-form label,
.comment-form p {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 4.5rem !important;
}

.mb-6 {
  margin-bottom: 6.75rem !important;
}

.mb-7 {
  margin-bottom: 9rem !important;
}

.mb-8 {
  margin-bottom: 11.25rem !important;
}

.mb-9 {
  margin-bottom: 15rem !important;
}

.mb-10 {
  margin-bottom: 18.75rem !important;
}

.mb-11 {
  margin-bottom: 22.5rem !important;
}

.mb-12 {
  margin-bottom: 26.25rem !important;
}

.mb-13 {
  margin-bottom: 30rem !important;
}

.mb-14 {
  margin-bottom: 37.5rem !important;
}

.mb-15 {
  margin-bottom: 45rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.375rem !important;
}

.ms-2 {
  margin-left: 0.75rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 4.5rem !important;
}

.ms-6 {
  margin-left: 6.75rem !important;
}

.ms-7 {
  margin-left: 9rem !important;
}

.ms-8 {
  margin-left: 11.25rem !important;
}

.ms-9 {
  margin-left: 15rem !important;
}

.ms-10 {
  margin-left: 18.75rem !important;
}

.ms-11 {
  margin-left: 22.5rem !important;
}

.ms-12 {
  margin-left: 26.25rem !important;
}

.ms-13 {
  margin-left: 30rem !important;
}

.ms-14 {
  margin-left: 37.5rem !important;
}

.ms-15 {
  margin-left: 45rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.p-6 {
  padding: 6.75rem !important;
}

.p-7 {
  padding: 9rem !important;
}

.p-8 {
  padding: 11.25rem !important;
}

.p-9 {
  padding: 15rem !important;
}

.p-10 {
  padding: 18.75rem !important;
}

.p-11 {
  padding: 22.5rem !important;
}

.p-12 {
  padding: 26.25rem !important;
}

.p-13 {
  padding: 30rem !important;
}

.p-14 {
  padding: 37.5rem !important;
}

.p-15 {
  padding: 45rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}

.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-5 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-6 {
  padding-right: 6.75rem !important;
  padding-left: 6.75rem !important;
}

.px-7 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-8 {
  padding-right: 11.25rem !important;
  padding-left: 11.25rem !important;
}

.px-9 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.px-10 {
  padding-right: 18.75rem !important;
  padding-left: 18.75rem !important;
}

.px-11 {
  padding-right: 22.5rem !important;
  padding-left: 22.5rem !important;
}

.px-12 {
  padding-right: 26.25rem !important;
  padding-left: 26.25rem !important;
}

.px-13 {
  padding-right: 30rem !important;
  padding-left: 30rem !important;
}

.px-14 {
  padding-right: 37.5rem !important;
  padding-left: 37.5rem !important;
}

.px-15 {
  padding-right: 45rem !important;
  padding-left: 45rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-6 {
  padding-top: 6.75rem !important;
  padding-bottom: 6.75rem !important;
}

.py-7 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-8 {
  padding-top: 11.25rem !important;
  padding-bottom: 11.25rem !important;
}

.py-9 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.py-10 {
  padding-top: 18.75rem !important;
  padding-bottom: 18.75rem !important;
}

.py-11 {
  padding-top: 22.5rem !important;
  padding-bottom: 22.5rem !important;
}

.py-12 {
  padding-top: 26.25rem !important;
  padding-bottom: 26.25rem !important;
}

.py-13 {
  padding-top: 30rem !important;
  padding-bottom: 30rem !important;
}

.py-14 {
  padding-top: 37.5rem !important;
  padding-bottom: 37.5rem !important;
}

.py-15 {
  padding-top: 45rem !important;
  padding-bottom: 45rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.375rem !important;
}

.pt-2 {
  padding-top: 0.75rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 4.5rem !important;
}

.pt-6 {
  padding-top: 6.75rem !important;
}

.pt-7 {
  padding-top: 9rem !important;
}

.pt-8 {
  padding-top: 11.25rem !important;
}

.pt-9 {
  padding-top: 15rem !important;
}

.pt-10 {
  padding-top: 18.75rem !important;
}

.pt-11 {
  padding-top: 22.5rem !important;
}

.pt-12 {
  padding-top: 26.25rem !important;
}

.pt-13 {
  padding-top: 30rem !important;
}

.pt-14 {
  padding-top: 37.5rem !important;
}

.pt-15 {
  padding-top: 45rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.375rem !important;
}

.pe-2 {
  padding-right: 0.75rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 4.5rem !important;
}

.pe-6 {
  padding-right: 6.75rem !important;
}

.pe-7 {
  padding-right: 9rem !important;
}

.pe-8 {
  padding-right: 11.25rem !important;
}

.pe-9 {
  padding-right: 15rem !important;
}

.pe-10 {
  padding-right: 18.75rem !important;
}

.pe-11 {
  padding-right: 22.5rem !important;
}

.pe-12 {
  padding-right: 26.25rem !important;
}

.pe-13 {
  padding-right: 30rem !important;
}

.pe-14 {
  padding-right: 37.5rem !important;
}

.pe-15 {
  padding-right: 45rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.375rem !important;
}

.pb-2 {
  padding-bottom: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 4.5rem !important;
}

.pb-6 {
  padding-bottom: 6.75rem !important;
}

.pb-7 {
  padding-bottom: 9rem !important;
}

.pb-8 {
  padding-bottom: 11.25rem !important;
}

.pb-9 {
  padding-bottom: 15rem !important;
}

.pb-10 {
  padding-bottom: 18.75rem !important;
}

.pb-11 {
  padding-bottom: 22.5rem !important;
}

.pb-12 {
  padding-bottom: 26.25rem !important;
}

.pb-13 {
  padding-bottom: 30rem !important;
}

.pb-14 {
  padding-bottom: 37.5rem !important;
}

.pb-15 {
  padding-bottom: 45rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.375rem !important;
}

.ps-2 {
  padding-left: 0.75rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 4.5rem !important;
}

.ps-6 {
  padding-left: 6.75rem !important;
}

.ps-7 {
  padding-left: 9rem !important;
}

.ps-8 {
  padding-left: 11.25rem !important;
}

.ps-9 {
  padding-left: 15rem !important;
}

.ps-10 {
  padding-left: 18.75rem !important;
}

.ps-11 {
  padding-left: 22.5rem !important;
}

.ps-12 {
  padding-left: 26.25rem !important;
}

.ps-13 {
  padding-left: 30rem !important;
}

.ps-14 {
  padding-left: 37.5rem !important;
}

.ps-15 {
  padding-left: 45rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */

.text-primary {
  color: #0d6efd !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f5f5f5 !important;
}

.text-dark {
  color: #1A1616 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

.bg-dark {
  background-color: #1A1616 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.375rem !important;
  }

  .gap-sm-2 {
    gap: 0.75rem !important;
  }

  .gap-sm-3 {
    gap: 1.5rem !important;
  }

  .gap-sm-4 {
    gap: 2.25rem !important;
  }

  .gap-sm-5 {
    gap: 4.5rem !important;
  }

  .gap-sm-6 {
    gap: 6.75rem !important;
  }

  .gap-sm-7 {
    gap: 9rem !important;
  }

  .gap-sm-8 {
    gap: 11.25rem !important;
  }

  .gap-sm-9 {
    gap: 15rem !important;
  }

  .gap-sm-10 {
    gap: 18.75rem !important;
  }

  .gap-sm-11 {
    gap: 22.5rem !important;
  }

  .gap-sm-12 {
    gap: 26.25rem !important;
  }

  .gap-sm-13 {
    gap: 30rem !important;
  }

  .gap-sm-14 {
    gap: 37.5rem !important;
  }

  .gap-sm-15 {
    gap: 45rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.375rem !important;
  }

  .m-sm-2 {
    margin: 0.75rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2.25rem !important;
  }

  .m-sm-5 {
    margin: 4.5rem !important;
  }

  .m-sm-6 {
    margin: 6.75rem !important;
  }

  .m-sm-7 {
    margin: 9rem !important;
  }

  .m-sm-8 {
    margin: 11.25rem !important;
  }

  .m-sm-9 {
    margin: 15rem !important;
  }

  .m-sm-10 {
    margin: 18.75rem !important;
  }

  .m-sm-11 {
    margin: 22.5rem !important;
  }

  .m-sm-12 {
    margin: 26.25rem !important;
  }

  .m-sm-13 {
    margin: 30rem !important;
  }

  .m-sm-14 {
    margin: 37.5rem !important;
  }

  .m-sm-15 {
    margin: 45rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-6 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }

  .mx-sm-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-sm-8 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-sm-9 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-sm-10 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }

  .mx-sm-11 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-sm-12 {
    margin-right: 26.25rem !important;
    margin-left: 26.25rem !important;
  }

  .mx-sm-13 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }

  .mx-sm-14 {
    margin-right: 37.5rem !important;
    margin-left: 37.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 45rem !important;
    margin-left: 45rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .my-sm-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-6 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }

  .my-sm-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-sm-8 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-sm-9 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-sm-10 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }

  .my-sm-11 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-sm-12 {
    margin-top: 26.25rem !important;
    margin-bottom: 26.25rem !important;
  }

  .my-sm-13 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .my-sm-14 {
    margin-top: 37.5rem !important;
    margin-bottom: 37.5rem !important;
  }

  .my-sm-15 {
    margin-top: 45rem !important;
    margin-bottom: 45rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.375rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-4 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-5 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-6 {
    margin-top: 6.75rem !important;
  }

  .mt-sm-7 {
    margin-top: 9rem !important;
  }

  .mt-sm-8 {
    margin-top: 11.25rem !important;
  }

  .mt-sm-9 {
    margin-top: 15rem !important;
  }

  .mt-sm-10 {
    margin-top: 18.75rem !important;
  }

  .mt-sm-11 {
    margin-top: 22.5rem !important;
  }

  .mt-sm-12 {
    margin-top: 26.25rem !important;
  }

  .mt-sm-13 {
    margin-top: 30rem !important;
  }

  .mt-sm-14 {
    margin-top: 37.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 45rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.375rem !important;
  }

  .me-sm-2 {
    margin-right: 0.75rem !important;
  }

  .me-sm-3 {
    margin-right: 1.5rem !important;
  }

  .me-sm-4 {
    margin-right: 2.25rem !important;
  }

  .me-sm-5 {
    margin-right: 4.5rem !important;
  }

  .me-sm-6 {
    margin-right: 6.75rem !important;
  }

  .me-sm-7 {
    margin-right: 9rem !important;
  }

  .me-sm-8 {
    margin-right: 11.25rem !important;
  }

  .me-sm-9 {
    margin-right: 15rem !important;
  }

  .me-sm-10 {
    margin-right: 18.75rem !important;
  }

  .me-sm-11 {
    margin-right: 22.5rem !important;
  }

  .me-sm-12 {
    margin-right: 26.25rem !important;
  }

  .me-sm-13 {
    margin-right: 30rem !important;
  }

  .me-sm-14 {
    margin-right: 37.5rem !important;
  }

  .me-sm-15 {
    margin-right: 45rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.375rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 6.75rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 9rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 11.25rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 15rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 18.75rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 22.5rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 26.25rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 30rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 37.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 45rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.375rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-4 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-5 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-6 {
    margin-left: 6.75rem !important;
  }

  .ms-sm-7 {
    margin-left: 9rem !important;
  }

  .ms-sm-8 {
    margin-left: 11.25rem !important;
  }

  .ms-sm-9 {
    margin-left: 15rem !important;
  }

  .ms-sm-10 {
    margin-left: 18.75rem !important;
  }

  .ms-sm-11 {
    margin-left: 22.5rem !important;
  }

  .ms-sm-12 {
    margin-left: 26.25rem !important;
  }

  .ms-sm-13 {
    margin-left: 30rem !important;
  }

  .ms-sm-14 {
    margin-left: 37.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 45rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.375rem !important;
  }

  .p-sm-2 {
    padding: 0.75rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2.25rem !important;
  }

  .p-sm-5 {
    padding: 4.5rem !important;
  }

  .p-sm-6 {
    padding: 6.75rem !important;
  }

  .p-sm-7 {
    padding: 9rem !important;
  }

  .p-sm-8 {
    padding: 11.25rem !important;
  }

  .p-sm-9 {
    padding: 15rem !important;
  }

  .p-sm-10 {
    padding: 18.75rem !important;
  }

  .p-sm-11 {
    padding: 22.5rem !important;
  }

  .p-sm-12 {
    padding: 26.25rem !important;
  }

  .p-sm-13 {
    padding: 30rem !important;
  }

  .p-sm-14 {
    padding: 37.5rem !important;
  }

  .p-sm-15 {
    padding: 45rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }

  .px-sm-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-6 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }

  .px-sm-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-sm-8 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .px-sm-9 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-sm-10 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }

  .px-sm-11 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .px-sm-12 {
    padding-right: 26.25rem !important;
    padding-left: 26.25rem !important;
  }

  .px-sm-13 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }

  .px-sm-14 {
    padding-right: 37.5rem !important;
    padding-left: 37.5rem !important;
  }

  .px-sm-15 {
    padding-right: 45rem !important;
    padding-left: 45rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .py-sm-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-6 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }

  .py-sm-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-sm-8 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .py-sm-9 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-sm-10 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }

  .py-sm-11 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .py-sm-12 {
    padding-top: 26.25rem !important;
    padding-bottom: 26.25rem !important;
  }

  .py-sm-13 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .py-sm-14 {
    padding-top: 37.5rem !important;
    padding-bottom: 37.5rem !important;
  }

  .py-sm-15 {
    padding-top: 45rem !important;
    padding-bottom: 45rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.375rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-4 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-5 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-6 {
    padding-top: 6.75rem !important;
  }

  .pt-sm-7 {
    padding-top: 9rem !important;
  }

  .pt-sm-8 {
    padding-top: 11.25rem !important;
  }

  .pt-sm-9 {
    padding-top: 15rem !important;
  }

  .pt-sm-10 {
    padding-top: 18.75rem !important;
  }

  .pt-sm-11 {
    padding-top: 22.5rem !important;
  }

  .pt-sm-12 {
    padding-top: 26.25rem !important;
  }

  .pt-sm-13 {
    padding-top: 30rem !important;
  }

  .pt-sm-14 {
    padding-top: 37.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 45rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.375rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-4 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-5 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-6 {
    padding-right: 6.75rem !important;
  }

  .pe-sm-7 {
    padding-right: 9rem !important;
  }

  .pe-sm-8 {
    padding-right: 11.25rem !important;
  }

  .pe-sm-9 {
    padding-right: 15rem !important;
  }

  .pe-sm-10 {
    padding-right: 18.75rem !important;
  }

  .pe-sm-11 {
    padding-right: 22.5rem !important;
  }

  .pe-sm-12 {
    padding-right: 26.25rem !important;
  }

  .pe-sm-13 {
    padding-right: 30rem !important;
  }

  .pe-sm-14 {
    padding-right: 37.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 45rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.375rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 6.75rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 9rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 11.25rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 15rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 18.75rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 22.5rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 26.25rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 30rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 37.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 45rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.375rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-4 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-5 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-6 {
    padding-left: 6.75rem !important;
  }

  .ps-sm-7 {
    padding-left: 9rem !important;
  }

  .ps-sm-8 {
    padding-left: 11.25rem !important;
  }

  .ps-sm-9 {
    padding-left: 15rem !important;
  }

  .ps-sm-10 {
    padding-left: 18.75rem !important;
  }

  .ps-sm-11 {
    padding-left: 22.5rem !important;
  }

  .ps-sm-12 {
    padding-left: 26.25rem !important;
  }

  .ps-sm-13 {
    padding-left: 30rem !important;
  }

  .ps-sm-14 {
    padding-left: 37.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 45rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.375rem !important;
  }

  .gap-md-2 {
    gap: 0.75rem !important;
  }

  .gap-md-3 {
    gap: 1.5rem !important;
  }

  .gap-md-4 {
    gap: 2.25rem !important;
  }

  .gap-md-5 {
    gap: 4.5rem !important;
  }

  .gap-md-6 {
    gap: 6.75rem !important;
  }

  .gap-md-7 {
    gap: 9rem !important;
  }

  .gap-md-8 {
    gap: 11.25rem !important;
  }

  .gap-md-9 {
    gap: 15rem !important;
  }

  .gap-md-10 {
    gap: 18.75rem !important;
  }

  .gap-md-11 {
    gap: 22.5rem !important;
  }

  .gap-md-12 {
    gap: 26.25rem !important;
  }

  .gap-md-13 {
    gap: 30rem !important;
  }

  .gap-md-14 {
    gap: 37.5rem !important;
  }

  .gap-md-15 {
    gap: 45rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.375rem !important;
  }

  .m-md-2 {
    margin: 0.75rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2.25rem !important;
  }

  .m-md-5 {
    margin: 4.5rem !important;
  }

  .m-md-6 {
    margin: 6.75rem !important;
  }

  .m-md-7 {
    margin: 9rem !important;
  }

  .m-md-8 {
    margin: 11.25rem !important;
  }

  .m-md-9 {
    margin: 15rem !important;
  }

  .m-md-10 {
    margin: 18.75rem !important;
  }

  .m-md-11 {
    margin: 22.5rem !important;
  }

  .m-md-12 {
    margin: 26.25rem !important;
  }

  .m-md-13 {
    margin: 30rem !important;
  }

  .m-md-14 {
    margin: 37.5rem !important;
  }

  .m-md-15 {
    margin: 45rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }

  .mx-md-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-6 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }

  .mx-md-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-md-8 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-md-9 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-md-10 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }

  .mx-md-11 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-md-12 {
    margin-right: 26.25rem !important;
    margin-left: 26.25rem !important;
  }

  .mx-md-13 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }

  .mx-md-14 {
    margin-right: 37.5rem !important;
    margin-left: 37.5rem !important;
  }

  .mx-md-15 {
    margin-right: 45rem !important;
    margin-left: 45rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .my-md-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-6 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }

  .my-md-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-md-8 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-md-9 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-md-10 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }

  .my-md-11 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-md-12 {
    margin-top: 26.25rem !important;
    margin-bottom: 26.25rem !important;
  }

  .my-md-13 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .my-md-14 {
    margin-top: 37.5rem !important;
    margin-bottom: 37.5rem !important;
  }

  .my-md-15 {
    margin-top: 45rem !important;
    margin-bottom: 45rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.375rem !important;
  }

  .mt-md-2 {
    margin-top: 0.75rem !important;
  }

  .mt-md-3 {
    margin-top: 1.5rem !important;
  }

  .mt-md-4 {
    margin-top: 2.25rem !important;
  }

  .mt-md-5 {
    margin-top: 4.5rem !important;
  }

  .mt-md-6 {
    margin-top: 6.75rem !important;
  }

  .mt-md-7 {
    margin-top: 9rem !important;
  }

  .mt-md-8 {
    margin-top: 11.25rem !important;
  }

  .mt-md-9 {
    margin-top: 15rem !important;
  }

  .mt-md-10 {
    margin-top: 18.75rem !important;
  }

  .mt-md-11 {
    margin-top: 22.5rem !important;
  }

  .mt-md-12 {
    margin-top: 26.25rem !important;
  }

  .mt-md-13 {
    margin-top: 30rem !important;
  }

  .mt-md-14 {
    margin-top: 37.5rem !important;
  }

  .mt-md-15 {
    margin-top: 45rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.375rem !important;
  }

  .me-md-2 {
    margin-right: 0.75rem !important;
  }

  .me-md-3 {
    margin-right: 1.5rem !important;
  }

  .me-md-4 {
    margin-right: 2.25rem !important;
  }

  .me-md-5 {
    margin-right: 4.5rem !important;
  }

  .me-md-6 {
    margin-right: 6.75rem !important;
  }

  .me-md-7 {
    margin-right: 9rem !important;
  }

  .me-md-8 {
    margin-right: 11.25rem !important;
  }

  .me-md-9 {
    margin-right: 15rem !important;
  }

  .me-md-10 {
    margin-right: 18.75rem !important;
  }

  .me-md-11 {
    margin-right: 22.5rem !important;
  }

  .me-md-12 {
    margin-right: 26.25rem !important;
  }

  .me-md-13 {
    margin-right: 30rem !important;
  }

  .me-md-14 {
    margin-right: 37.5rem !important;
  }

  .me-md-15 {
    margin-right: 45rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.375rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-6 {
    margin-bottom: 6.75rem !important;
  }

  .mb-md-7 {
    margin-bottom: 9rem !important;
  }

  .mb-md-8 {
    margin-bottom: 11.25rem !important;
  }

  .mb-md-9 {
    margin-bottom: 15rem !important;
  }

  .mb-md-10 {
    margin-bottom: 18.75rem !important;
  }

  .mb-md-11 {
    margin-bottom: 22.5rem !important;
  }

  .mb-md-12 {
    margin-bottom: 26.25rem !important;
  }

  .mb-md-13 {
    margin-bottom: 30rem !important;
  }

  .mb-md-14 {
    margin-bottom: 37.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 45rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.375rem !important;
  }

  .ms-md-2 {
    margin-left: 0.75rem !important;
  }

  .ms-md-3 {
    margin-left: 1.5rem !important;
  }

  .ms-md-4 {
    margin-left: 2.25rem !important;
  }

  .ms-md-5 {
    margin-left: 4.5rem !important;
  }

  .ms-md-6 {
    margin-left: 6.75rem !important;
  }

  .ms-md-7 {
    margin-left: 9rem !important;
  }

  .ms-md-8 {
    margin-left: 11.25rem !important;
  }

  .ms-md-9 {
    margin-left: 15rem !important;
  }

  .ms-md-10 {
    margin-left: 18.75rem !important;
  }

  .ms-md-11 {
    margin-left: 22.5rem !important;
  }

  .ms-md-12 {
    margin-left: 26.25rem !important;
  }

  .ms-md-13 {
    margin-left: 30rem !important;
  }

  .ms-md-14 {
    margin-left: 37.5rem !important;
  }

  .ms-md-15 {
    margin-left: 45rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.375rem !important;
  }

  .p-md-2 {
    padding: 0.75rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2.25rem !important;
  }

  .p-md-5 {
    padding: 4.5rem !important;
  }

  .p-md-6 {
    padding: 6.75rem !important;
  }

  .p-md-7 {
    padding: 9rem !important;
  }

  .p-md-8 {
    padding: 11.25rem !important;
  }

  .p-md-9 {
    padding: 15rem !important;
  }

  .p-md-10 {
    padding: 18.75rem !important;
  }

  .p-md-11 {
    padding: 22.5rem !important;
  }

  .p-md-12 {
    padding: 26.25rem !important;
  }

  .p-md-13 {
    padding: 30rem !important;
  }

  .p-md-14 {
    padding: 37.5rem !important;
  }

  .p-md-15 {
    padding: 45rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }

  .px-md-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-6 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }

  .px-md-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-md-8 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .px-md-9 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-md-10 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }

  .px-md-11 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .px-md-12 {
    padding-right: 26.25rem !important;
    padding-left: 26.25rem !important;
  }

  .px-md-13 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }

  .px-md-14 {
    padding-right: 37.5rem !important;
    padding-left: 37.5rem !important;
  }

  .px-md-15 {
    padding-right: 45rem !important;
    padding-left: 45rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .py-md-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-6 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }

  .py-md-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-md-8 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .py-md-9 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-md-10 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }

  .py-md-11 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .py-md-12 {
    padding-top: 26.25rem !important;
    padding-bottom: 26.25rem !important;
  }

  .py-md-13 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .py-md-14 {
    padding-top: 37.5rem !important;
    padding-bottom: 37.5rem !important;
  }

  .py-md-15 {
    padding-top: 45rem !important;
    padding-bottom: 45rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.375rem !important;
  }

  .pt-md-2 {
    padding-top: 0.75rem !important;
  }

  .pt-md-3 {
    padding-top: 1.5rem !important;
  }

  .pt-md-4 {
    padding-top: 2.25rem !important;
  }

  .pt-md-5 {
    padding-top: 4.5rem !important;
  }

  .pt-md-6 {
    padding-top: 6.75rem !important;
  }

  .pt-md-7 {
    padding-top: 9rem !important;
  }

  .pt-md-8 {
    padding-top: 11.25rem !important;
  }

  .pt-md-9 {
    padding-top: 15rem !important;
  }

  .pt-md-10 {
    padding-top: 18.75rem !important;
  }

  .pt-md-11 {
    padding-top: 22.5rem !important;
  }

  .pt-md-12 {
    padding-top: 26.25rem !important;
  }

  .pt-md-13 {
    padding-top: 30rem !important;
  }

  .pt-md-14 {
    padding-top: 37.5rem !important;
  }

  .pt-md-15 {
    padding-top: 45rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.375rem !important;
  }

  .pe-md-2 {
    padding-right: 0.75rem !important;
  }

  .pe-md-3 {
    padding-right: 1.5rem !important;
  }

  .pe-md-4 {
    padding-right: 2.25rem !important;
  }

  .pe-md-5 {
    padding-right: 4.5rem !important;
  }

  .pe-md-6 {
    padding-right: 6.75rem !important;
  }

  .pe-md-7 {
    padding-right: 9rem !important;
  }

  .pe-md-8 {
    padding-right: 11.25rem !important;
  }

  .pe-md-9 {
    padding-right: 15rem !important;
  }

  .pe-md-10 {
    padding-right: 18.75rem !important;
  }

  .pe-md-11 {
    padding-right: 22.5rem !important;
  }

  .pe-md-12 {
    padding-right: 26.25rem !important;
  }

  .pe-md-13 {
    padding-right: 30rem !important;
  }

  .pe-md-14 {
    padding-right: 37.5rem !important;
  }

  .pe-md-15 {
    padding-right: 45rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.375rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-5 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-6 {
    padding-bottom: 6.75rem !important;
  }

  .pb-md-7 {
    padding-bottom: 9rem !important;
  }

  .pb-md-8 {
    padding-bottom: 11.25rem !important;
  }

  .pb-md-9 {
    padding-bottom: 15rem !important;
  }

  .pb-md-10 {
    padding-bottom: 18.75rem !important;
  }

  .pb-md-11 {
    padding-bottom: 22.5rem !important;
  }

  .pb-md-12 {
    padding-bottom: 26.25rem !important;
  }

  .pb-md-13 {
    padding-bottom: 30rem !important;
  }

  .pb-md-14 {
    padding-bottom: 37.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 45rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.375rem !important;
  }

  .ps-md-2 {
    padding-left: 0.75rem !important;
  }

  .ps-md-3 {
    padding-left: 1.5rem !important;
  }

  .ps-md-4 {
    padding-left: 2.25rem !important;
  }

  .ps-md-5 {
    padding-left: 4.5rem !important;
  }

  .ps-md-6 {
    padding-left: 6.75rem !important;
  }

  .ps-md-7 {
    padding-left: 9rem !important;
  }

  .ps-md-8 {
    padding-left: 11.25rem !important;
  }

  .ps-md-9 {
    padding-left: 15rem !important;
  }

  .ps-md-10 {
    padding-left: 18.75rem !important;
  }

  .ps-md-11 {
    padding-left: 22.5rem !important;
  }

  .ps-md-12 {
    padding-left: 26.25rem !important;
  }

  .ps-md-13 {
    padding-left: 30rem !important;
  }

  .ps-md-14 {
    padding-left: 37.5rem !important;
  }

  .ps-md-15 {
    padding-left: 45rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.375rem !important;
  }

  .gap-lg-2 {
    gap: 0.75rem !important;
  }

  .gap-lg-3 {
    gap: 1.5rem !important;
  }

  .gap-lg-4 {
    gap: 2.25rem !important;
  }

  .gap-lg-5 {
    gap: 4.5rem !important;
  }

  .gap-lg-6 {
    gap: 6.75rem !important;
  }

  .gap-lg-7 {
    gap: 9rem !important;
  }

  .gap-lg-8 {
    gap: 11.25rem !important;
  }

  .gap-lg-9 {
    gap: 15rem !important;
  }

  .gap-lg-10 {
    gap: 18.75rem !important;
  }

  .gap-lg-11 {
    gap: 22.5rem !important;
  }

  .gap-lg-12 {
    gap: 26.25rem !important;
  }

  .gap-lg-13 {
    gap: 30rem !important;
  }

  .gap-lg-14 {
    gap: 37.5rem !important;
  }

  .gap-lg-15 {
    gap: 45rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.375rem !important;
  }

  .m-lg-2 {
    margin: 0.75rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2.25rem !important;
  }

  .m-lg-5 {
    margin: 4.5rem !important;
  }

  .m-lg-6 {
    margin: 6.75rem !important;
  }

  .m-lg-7 {
    margin: 9rem !important;
  }

  .m-lg-8 {
    margin: 11.25rem !important;
  }

  .m-lg-9 {
    margin: 15rem !important;
  }

  .m-lg-10 {
    margin: 18.75rem !important;
  }

  .m-lg-11 {
    margin: 22.5rem !important;
  }

  .m-lg-12 {
    margin: 26.25rem !important;
  }

  .m-lg-13 {
    margin: 30rem !important;
  }

  .m-lg-14 {
    margin: 37.5rem !important;
  }

  .m-lg-15 {
    margin: 45rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-6 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }

  .mx-lg-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-lg-8 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-lg-9 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-lg-10 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }

  .mx-lg-11 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-lg-12 {
    margin-right: 26.25rem !important;
    margin-left: 26.25rem !important;
  }

  .mx-lg-13 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }

  .mx-lg-14 {
    margin-right: 37.5rem !important;
    margin-left: 37.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 45rem !important;
    margin-left: 45rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .my-lg-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-6 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }

  .my-lg-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-lg-8 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-lg-9 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-lg-10 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }

  .my-lg-11 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-lg-12 {
    margin-top: 26.25rem !important;
    margin-bottom: 26.25rem !important;
  }

  .my-lg-13 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .my-lg-14 {
    margin-top: 37.5rem !important;
    margin-bottom: 37.5rem !important;
  }

  .my-lg-15 {
    margin-top: 45rem !important;
    margin-bottom: 45rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.375rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-4 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-5 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-6 {
    margin-top: 6.75rem !important;
  }

  .mt-lg-7 {
    margin-top: 9rem !important;
  }

  .mt-lg-8 {
    margin-top: 11.25rem !important;
  }

  .mt-lg-9 {
    margin-top: 15rem !important;
  }

  .mt-lg-10 {
    margin-top: 18.75rem !important;
  }

  .mt-lg-11 {
    margin-top: 22.5rem !important;
  }

  .mt-lg-12 {
    margin-top: 26.25rem !important;
  }

  .mt-lg-13 {
    margin-top: 30rem !important;
  }

  .mt-lg-14 {
    margin-top: 37.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 45rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.375rem !important;
  }

  .me-lg-2 {
    margin-right: 0.75rem !important;
  }

  .me-lg-3 {
    margin-right: 1.5rem !important;
  }

  .me-lg-4 {
    margin-right: 2.25rem !important;
  }

  .me-lg-5 {
    margin-right: 4.5rem !important;
  }

  .me-lg-6 {
    margin-right: 6.75rem !important;
  }

  .me-lg-7 {
    margin-right: 9rem !important;
  }

  .me-lg-8 {
    margin-right: 11.25rem !important;
  }

  .me-lg-9 {
    margin-right: 15rem !important;
  }

  .me-lg-10 {
    margin-right: 18.75rem !important;
  }

  .me-lg-11 {
    margin-right: 22.5rem !important;
  }

  .me-lg-12 {
    margin-right: 26.25rem !important;
  }

  .me-lg-13 {
    margin-right: 30rem !important;
  }

  .me-lg-14 {
    margin-right: 37.5rem !important;
  }

  .me-lg-15 {
    margin-right: 45rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.375rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 6.75rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 9rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 11.25rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 15rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 18.75rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 22.5rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 26.25rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 30rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 37.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 45rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.375rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-4 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-5 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-6 {
    margin-left: 6.75rem !important;
  }

  .ms-lg-7 {
    margin-left: 9rem !important;
  }

  .ms-lg-8 {
    margin-left: 11.25rem !important;
  }

  .ms-lg-9 {
    margin-left: 15rem !important;
  }

  .ms-lg-10 {
    margin-left: 18.75rem !important;
  }

  .ms-lg-11 {
    margin-left: 22.5rem !important;
  }

  .ms-lg-12 {
    margin-left: 26.25rem !important;
  }

  .ms-lg-13 {
    margin-left: 30rem !important;
  }

  .ms-lg-14 {
    margin-left: 37.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 45rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.375rem !important;
  }

  .p-lg-2 {
    padding: 0.75rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2.25rem !important;
  }

  .p-lg-5 {
    padding: 4.5rem !important;
  }

  .p-lg-6 {
    padding: 6.75rem !important;
  }

  .p-lg-7 {
    padding: 9rem !important;
  }

  .p-lg-8 {
    padding: 11.25rem !important;
  }

  .p-lg-9 {
    padding: 15rem !important;
  }

  .p-lg-10 {
    padding: 18.75rem !important;
  }

  .p-lg-11 {
    padding: 22.5rem !important;
  }

  .p-lg-12 {
    padding: 26.25rem !important;
  }

  .p-lg-13 {
    padding: 30rem !important;
  }

  .p-lg-14 {
    padding: 37.5rem !important;
  }

  .p-lg-15 {
    padding: 45rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }

  .px-lg-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-6 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }

  .px-lg-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-lg-8 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .px-lg-9 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-lg-10 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }

  .px-lg-11 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .px-lg-12 {
    padding-right: 26.25rem !important;
    padding-left: 26.25rem !important;
  }

  .px-lg-13 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }

  .px-lg-14 {
    padding-right: 37.5rem !important;
    padding-left: 37.5rem !important;
  }

  .px-lg-15 {
    padding-right: 45rem !important;
    padding-left: 45rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .py-lg-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-6 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }

  .py-lg-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-lg-8 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .py-lg-9 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-lg-10 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }

  .py-lg-11 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .py-lg-12 {
    padding-top: 26.25rem !important;
    padding-bottom: 26.25rem !important;
  }

  .py-lg-13 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .py-lg-14 {
    padding-top: 37.5rem !important;
    padding-bottom: 37.5rem !important;
  }

  .py-lg-15 {
    padding-top: 45rem !important;
    padding-bottom: 45rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.375rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-4 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-5 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-6 {
    padding-top: 6.75rem !important;
  }

  .pt-lg-7 {
    padding-top: 9rem !important;
  }

  .pt-lg-8 {
    padding-top: 11.25rem !important;
  }

  .pt-lg-9 {
    padding-top: 15rem !important;
  }

  .pt-lg-10 {
    padding-top: 18.75rem !important;
  }

  .pt-lg-11 {
    padding-top: 22.5rem !important;
  }

  .pt-lg-12 {
    padding-top: 26.25rem !important;
  }

  .pt-lg-13 {
    padding-top: 30rem !important;
  }

  .pt-lg-14 {
    padding-top: 37.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 45rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.375rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-4 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-5 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-6 {
    padding-right: 6.75rem !important;
  }

  .pe-lg-7 {
    padding-right: 9rem !important;
  }

  .pe-lg-8 {
    padding-right: 11.25rem !important;
  }

  .pe-lg-9 {
    padding-right: 15rem !important;
  }

  .pe-lg-10 {
    padding-right: 18.75rem !important;
  }

  .pe-lg-11 {
    padding-right: 22.5rem !important;
  }

  .pe-lg-12 {
    padding-right: 26.25rem !important;
  }

  .pe-lg-13 {
    padding-right: 30rem !important;
  }

  .pe-lg-14 {
    padding-right: 37.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 45rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.375rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 6.75rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 9rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 11.25rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 15rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 18.75rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 22.5rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 26.25rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 30rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 37.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 45rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.375rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-4 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-5 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-6 {
    padding-left: 6.75rem !important;
  }

  .ps-lg-7 {
    padding-left: 9rem !important;
  }

  .ps-lg-8 {
    padding-left: 11.25rem !important;
  }

  .ps-lg-9 {
    padding-left: 15rem !important;
  }

  .ps-lg-10 {
    padding-left: 18.75rem !important;
  }

  .ps-lg-11 {
    padding-left: 22.5rem !important;
  }

  .ps-lg-12 {
    padding-left: 26.25rem !important;
  }

  .ps-lg-13 {
    padding-left: 30rem !important;
  }

  .ps-lg-14 {
    padding-left: 37.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 45rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.375rem !important;
  }

  .gap-xl-2 {
    gap: 0.75rem !important;
  }

  .gap-xl-3 {
    gap: 1.5rem !important;
  }

  .gap-xl-4 {
    gap: 2.25rem !important;
  }

  .gap-xl-5 {
    gap: 4.5rem !important;
  }

  .gap-xl-6 {
    gap: 6.75rem !important;
  }

  .gap-xl-7 {
    gap: 9rem !important;
  }

  .gap-xl-8 {
    gap: 11.25rem !important;
  }

  .gap-xl-9 {
    gap: 15rem !important;
  }

  .gap-xl-10 {
    gap: 18.75rem !important;
  }

  .gap-xl-11 {
    gap: 22.5rem !important;
  }

  .gap-xl-12 {
    gap: 26.25rem !important;
  }

  .gap-xl-13 {
    gap: 30rem !important;
  }

  .gap-xl-14 {
    gap: 37.5rem !important;
  }

  .gap-xl-15 {
    gap: 45rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.375rem !important;
  }

  .m-xl-2 {
    margin: 0.75rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2.25rem !important;
  }

  .m-xl-5 {
    margin: 4.5rem !important;
  }

  .m-xl-6 {
    margin: 6.75rem !important;
  }

  .m-xl-7 {
    margin: 9rem !important;
  }

  .m-xl-8 {
    margin: 11.25rem !important;
  }

  .m-xl-9 {
    margin: 15rem !important;
  }

  .m-xl-10 {
    margin: 18.75rem !important;
  }

  .m-xl-11 {
    margin: 22.5rem !important;
  }

  .m-xl-12 {
    margin: 26.25rem !important;
  }

  .m-xl-13 {
    margin: 30rem !important;
  }

  .m-xl-14 {
    margin: 37.5rem !important;
  }

  .m-xl-15 {
    margin: 45rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-6 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }

  .mx-xl-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xl-8 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-xl-9 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-xl-10 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }

  .mx-xl-11 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-xl-12 {
    margin-right: 26.25rem !important;
    margin-left: 26.25rem !important;
  }

  .mx-xl-13 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }

  .mx-xl-14 {
    margin-right: 37.5rem !important;
    margin-left: 37.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 45rem !important;
    margin-left: 45rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .my-xl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-6 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }

  .my-xl-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xl-8 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-xl-9 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-xl-10 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }

  .my-xl-11 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-xl-12 {
    margin-top: 26.25rem !important;
    margin-bottom: 26.25rem !important;
  }

  .my-xl-13 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .my-xl-14 {
    margin-top: 37.5rem !important;
    margin-bottom: 37.5rem !important;
  }

  .my-xl-15 {
    margin-top: 45rem !important;
    margin-bottom: 45rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.375rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-4 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-5 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-6 {
    margin-top: 6.75rem !important;
  }

  .mt-xl-7 {
    margin-top: 9rem !important;
  }

  .mt-xl-8 {
    margin-top: 11.25rem !important;
  }

  .mt-xl-9 {
    margin-top: 15rem !important;
  }

  .mt-xl-10 {
    margin-top: 18.75rem !important;
  }

  .mt-xl-11 {
    margin-top: 22.5rem !important;
  }

  .mt-xl-12 {
    margin-top: 26.25rem !important;
  }

  .mt-xl-13 {
    margin-top: 30rem !important;
  }

  .mt-xl-14 {
    margin-top: 37.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 45rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.375rem !important;
  }

  .me-xl-2 {
    margin-right: 0.75rem !important;
  }

  .me-xl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xl-4 {
    margin-right: 2.25rem !important;
  }

  .me-xl-5 {
    margin-right: 4.5rem !important;
  }

  .me-xl-6 {
    margin-right: 6.75rem !important;
  }

  .me-xl-7 {
    margin-right: 9rem !important;
  }

  .me-xl-8 {
    margin-right: 11.25rem !important;
  }

  .me-xl-9 {
    margin-right: 15rem !important;
  }

  .me-xl-10 {
    margin-right: 18.75rem !important;
  }

  .me-xl-11 {
    margin-right: 22.5rem !important;
  }

  .me-xl-12 {
    margin-right: 26.25rem !important;
  }

  .me-xl-13 {
    margin-right: 30rem !important;
  }

  .me-xl-14 {
    margin-right: 37.5rem !important;
  }

  .me-xl-15 {
    margin-right: 45rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.375rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 6.75rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 9rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 11.25rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 15rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 18.75rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 22.5rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 26.25rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 30rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 37.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 45rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.375rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-4 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-5 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-6 {
    margin-left: 6.75rem !important;
  }

  .ms-xl-7 {
    margin-left: 9rem !important;
  }

  .ms-xl-8 {
    margin-left: 11.25rem !important;
  }

  .ms-xl-9 {
    margin-left: 15rem !important;
  }

  .ms-xl-10 {
    margin-left: 18.75rem !important;
  }

  .ms-xl-11 {
    margin-left: 22.5rem !important;
  }

  .ms-xl-12 {
    margin-left: 26.25rem !important;
  }

  .ms-xl-13 {
    margin-left: 30rem !important;
  }

  .ms-xl-14 {
    margin-left: 37.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 45rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.375rem !important;
  }

  .p-xl-2 {
    padding: 0.75rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2.25rem !important;
  }

  .p-xl-5 {
    padding: 4.5rem !important;
  }

  .p-xl-6 {
    padding: 6.75rem !important;
  }

  .p-xl-7 {
    padding: 9rem !important;
  }

  .p-xl-8 {
    padding: 11.25rem !important;
  }

  .p-xl-9 {
    padding: 15rem !important;
  }

  .p-xl-10 {
    padding: 18.75rem !important;
  }

  .p-xl-11 {
    padding: 22.5rem !important;
  }

  .p-xl-12 {
    padding: 26.25rem !important;
  }

  .p-xl-13 {
    padding: 30rem !important;
  }

  .p-xl-14 {
    padding: 37.5rem !important;
  }

  .p-xl-15 {
    padding: 45rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }

  .px-xl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-6 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }

  .px-xl-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xl-8 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .px-xl-9 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-xl-10 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }

  .px-xl-11 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .px-xl-12 {
    padding-right: 26.25rem !important;
    padding-left: 26.25rem !important;
  }

  .px-xl-13 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }

  .px-xl-14 {
    padding-right: 37.5rem !important;
    padding-left: 37.5rem !important;
  }

  .px-xl-15 {
    padding-right: 45rem !important;
    padding-left: 45rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .py-xl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-6 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }

  .py-xl-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xl-8 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .py-xl-9 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-xl-10 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }

  .py-xl-11 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .py-xl-12 {
    padding-top: 26.25rem !important;
    padding-bottom: 26.25rem !important;
  }

  .py-xl-13 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .py-xl-14 {
    padding-top: 37.5rem !important;
    padding-bottom: 37.5rem !important;
  }

  .py-xl-15 {
    padding-top: 45rem !important;
    padding-bottom: 45rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.375rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-4 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-5 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-6 {
    padding-top: 6.75rem !important;
  }

  .pt-xl-7 {
    padding-top: 9rem !important;
  }

  .pt-xl-8 {
    padding-top: 11.25rem !important;
  }

  .pt-xl-9 {
    padding-top: 15rem !important;
  }

  .pt-xl-10 {
    padding-top: 18.75rem !important;
  }

  .pt-xl-11 {
    padding-top: 22.5rem !important;
  }

  .pt-xl-12 {
    padding-top: 26.25rem !important;
  }

  .pt-xl-13 {
    padding-top: 30rem !important;
  }

  .pt-xl-14 {
    padding-top: 37.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 45rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.375rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-4 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-5 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-6 {
    padding-right: 6.75rem !important;
  }

  .pe-xl-7 {
    padding-right: 9rem !important;
  }

  .pe-xl-8 {
    padding-right: 11.25rem !important;
  }

  .pe-xl-9 {
    padding-right: 15rem !important;
  }

  .pe-xl-10 {
    padding-right: 18.75rem !important;
  }

  .pe-xl-11 {
    padding-right: 22.5rem !important;
  }

  .pe-xl-12 {
    padding-right: 26.25rem !important;
  }

  .pe-xl-13 {
    padding-right: 30rem !important;
  }

  .pe-xl-14 {
    padding-right: 37.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 45rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.375rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 6.75rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 9rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 11.25rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 15rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 18.75rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 22.5rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 26.25rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 30rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 37.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 45rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.375rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-4 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-5 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-6 {
    padding-left: 6.75rem !important;
  }

  .ps-xl-7 {
    padding-left: 9rem !important;
  }

  .ps-xl-8 {
    padding-left: 11.25rem !important;
  }

  .ps-xl-9 {
    padding-left: 15rem !important;
  }

  .ps-xl-10 {
    padding-left: 18.75rem !important;
  }

  .ps-xl-11 {
    padding-left: 22.5rem !important;
  }

  .ps-xl-12 {
    padding-left: 26.25rem !important;
  }

  .ps-xl-13 {
    padding-left: 30rem !important;
  }

  .ps-xl-14 {
    padding-left: 37.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 45rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.375rem !important;
  }

  .gap-xxl-2 {
    gap: 0.75rem !important;
  }

  .gap-xxl-3 {
    gap: 1.5rem !important;
  }

  .gap-xxl-4 {
    gap: 2.25rem !important;
  }

  .gap-xxl-5 {
    gap: 4.5rem !important;
  }

  .gap-xxl-6 {
    gap: 6.75rem !important;
  }

  .gap-xxl-7 {
    gap: 9rem !important;
  }

  .gap-xxl-8 {
    gap: 11.25rem !important;
  }

  .gap-xxl-9 {
    gap: 15rem !important;
  }

  .gap-xxl-10 {
    gap: 18.75rem !important;
  }

  .gap-xxl-11 {
    gap: 22.5rem !important;
  }

  .gap-xxl-12 {
    gap: 26.25rem !important;
  }

  .gap-xxl-13 {
    gap: 30rem !important;
  }

  .gap-xxl-14 {
    gap: 37.5rem !important;
  }

  .gap-xxl-15 {
    gap: 45rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.375rem !important;
  }

  .m-xxl-2 {
    margin: 0.75rem !important;
  }

  .m-xxl-3 {
    margin: 1.5rem !important;
  }

  .m-xxl-4 {
    margin: 2.25rem !important;
  }

  .m-xxl-5 {
    margin: 4.5rem !important;
  }

  .m-xxl-6 {
    margin: 6.75rem !important;
  }

  .m-xxl-7 {
    margin: 9rem !important;
  }

  .m-xxl-8 {
    margin: 11.25rem !important;
  }

  .m-xxl-9 {
    margin: 15rem !important;
  }

  .m-xxl-10 {
    margin: 18.75rem !important;
  }

  .m-xxl-11 {
    margin: 22.5rem !important;
  }

  .m-xxl-12 {
    margin: 26.25rem !important;
  }

  .m-xxl-13 {
    margin: 30rem !important;
  }

  .m-xxl-14 {
    margin: 37.5rem !important;
  }

  .m-xxl-15 {
    margin: 45rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-6 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }

  .mx-xxl-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xxl-8 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-xxl-9 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-xxl-10 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }

  .mx-xxl-11 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-xxl-12 {
    margin-right: 26.25rem !important;
    margin-left: 26.25rem !important;
  }

  .mx-xxl-13 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }

  .mx-xxl-14 {
    margin-right: 37.5rem !important;
    margin-left: 37.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 45rem !important;
    margin-left: 45rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-6 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }

  .my-xxl-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xxl-8 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-xxl-9 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-xxl-10 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }

  .my-xxl-11 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-xxl-12 {
    margin-top: 26.25rem !important;
    margin-bottom: 26.25rem !important;
  }

  .my-xxl-13 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .my-xxl-14 {
    margin-top: 37.5rem !important;
    margin-bottom: 37.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 45rem !important;
    margin-bottom: 45rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.375rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-4 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-5 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-6 {
    margin-top: 6.75rem !important;
  }

  .mt-xxl-7 {
    margin-top: 9rem !important;
  }

  .mt-xxl-8 {
    margin-top: 11.25rem !important;
  }

  .mt-xxl-9 {
    margin-top: 15rem !important;
  }

  .mt-xxl-10 {
    margin-top: 18.75rem !important;
  }

  .mt-xxl-11 {
    margin-top: 22.5rem !important;
  }

  .mt-xxl-12 {
    margin-top: 26.25rem !important;
  }

  .mt-xxl-13 {
    margin-top: 30rem !important;
  }

  .mt-xxl-14 {
    margin-top: 37.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 45rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.375rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-4 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-5 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-6 {
    margin-right: 6.75rem !important;
  }

  .me-xxl-7 {
    margin-right: 9rem !important;
  }

  .me-xxl-8 {
    margin-right: 11.25rem !important;
  }

  .me-xxl-9 {
    margin-right: 15rem !important;
  }

  .me-xxl-10 {
    margin-right: 18.75rem !important;
  }

  .me-xxl-11 {
    margin-right: 22.5rem !important;
  }

  .me-xxl-12 {
    margin-right: 26.25rem !important;
  }

  .me-xxl-13 {
    margin-right: 30rem !important;
  }

  .me-xxl-14 {
    margin-right: 37.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 45rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.375rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 6.75rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 9rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 11.25rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 15rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 18.75rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 22.5rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 26.25rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 30rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 37.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 45rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.375rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-4 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-5 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-6 {
    margin-left: 6.75rem !important;
  }

  .ms-xxl-7 {
    margin-left: 9rem !important;
  }

  .ms-xxl-8 {
    margin-left: 11.25rem !important;
  }

  .ms-xxl-9 {
    margin-left: 15rem !important;
  }

  .ms-xxl-10 {
    margin-left: 18.75rem !important;
  }

  .ms-xxl-11 {
    margin-left: 22.5rem !important;
  }

  .ms-xxl-12 {
    margin-left: 26.25rem !important;
  }

  .ms-xxl-13 {
    margin-left: 30rem !important;
  }

  .ms-xxl-14 {
    margin-left: 37.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 45rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.375rem !important;
  }

  .p-xxl-2 {
    padding: 0.75rem !important;
  }

  .p-xxl-3 {
    padding: 1.5rem !important;
  }

  .p-xxl-4 {
    padding: 2.25rem !important;
  }

  .p-xxl-5 {
    padding: 4.5rem !important;
  }

  .p-xxl-6 {
    padding: 6.75rem !important;
  }

  .p-xxl-7 {
    padding: 9rem !important;
  }

  .p-xxl-8 {
    padding: 11.25rem !important;
  }

  .p-xxl-9 {
    padding: 15rem !important;
  }

  .p-xxl-10 {
    padding: 18.75rem !important;
  }

  .p-xxl-11 {
    padding: 22.5rem !important;
  }

  .p-xxl-12 {
    padding: 26.25rem !important;
  }

  .p-xxl-13 {
    padding: 30rem !important;
  }

  .p-xxl-14 {
    padding: 37.5rem !important;
  }

  .p-xxl-15 {
    padding: 45rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-6 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }

  .px-xxl-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xxl-8 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .px-xxl-9 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-xxl-10 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }

  .px-xxl-11 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .px-xxl-12 {
    padding-right: 26.25rem !important;
    padding-left: 26.25rem !important;
  }

  .px-xxl-13 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }

  .px-xxl-14 {
    padding-right: 37.5rem !important;
    padding-left: 37.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 45rem !important;
    padding-left: 45rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-6 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }

  .py-xxl-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xxl-8 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .py-xxl-9 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-xxl-10 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }

  .py-xxl-11 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .py-xxl-12 {
    padding-top: 26.25rem !important;
    padding-bottom: 26.25rem !important;
  }

  .py-xxl-13 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .py-xxl-14 {
    padding-top: 37.5rem !important;
    padding-bottom: 37.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 45rem !important;
    padding-bottom: 45rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.375rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-4 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-5 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-6 {
    padding-top: 6.75rem !important;
  }

  .pt-xxl-7 {
    padding-top: 9rem !important;
  }

  .pt-xxl-8 {
    padding-top: 11.25rem !important;
  }

  .pt-xxl-9 {
    padding-top: 15rem !important;
  }

  .pt-xxl-10 {
    padding-top: 18.75rem !important;
  }

  .pt-xxl-11 {
    padding-top: 22.5rem !important;
  }

  .pt-xxl-12 {
    padding-top: 26.25rem !important;
  }

  .pt-xxl-13 {
    padding-top: 30rem !important;
  }

  .pt-xxl-14 {
    padding-top: 37.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 45rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.375rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-4 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-5 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-6 {
    padding-right: 6.75rem !important;
  }

  .pe-xxl-7 {
    padding-right: 9rem !important;
  }

  .pe-xxl-8 {
    padding-right: 11.25rem !important;
  }

  .pe-xxl-9 {
    padding-right: 15rem !important;
  }

  .pe-xxl-10 {
    padding-right: 18.75rem !important;
  }

  .pe-xxl-11 {
    padding-right: 22.5rem !important;
  }

  .pe-xxl-12 {
    padding-right: 26.25rem !important;
  }

  .pe-xxl-13 {
    padding-right: 30rem !important;
  }

  .pe-xxl-14 {
    padding-right: 37.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 45rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.375rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 6.75rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 9rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 11.25rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 15rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 18.75rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 22.5rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 26.25rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 30rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 37.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 45rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.375rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-4 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-5 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-6 {
    padding-left: 6.75rem !important;
  }

  .ps-xxl-7 {
    padding-left: 9rem !important;
  }

  .ps-xxl-8 {
    padding-left: 11.25rem !important;
  }

  .ps-xxl-9 {
    padding-left: 15rem !important;
  }

  .ps-xxl-10 {
    padding-left: 18.75rem !important;
  }

  .ps-xxl-11 {
    padding-left: 22.5rem !important;
  }

  .ps-xxl-12 {
    padding-left: 26.25rem !important;
  }

  .ps-xxl-13 {
    padding-left: 30rem !important;
  }

  .ps-xxl-14 {
    padding-left: 37.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 45rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1800px) {
  .float-xxxl-start {
    float: left !important;
  }

  .float-xxxl-end {
    float: right !important;
  }

  .float-xxxl-none {
    float: none !important;
  }

  .d-xxxl-inline {
    display: inline !important;
  }

  .d-xxxl-inline-block {
    display: inline-block !important;
  }

  .d-xxxl-block {
    display: block !important;
  }

  .d-xxxl-grid {
    display: grid !important;
  }

  .d-xxxl-table {
    display: table !important;
  }

  .d-xxxl-table-row {
    display: table-row !important;
  }

  .d-xxxl-table-cell {
    display: table-cell !important;
  }

  .d-xxxl-flex {
    display: flex !important;
  }

  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxxl-none {
    display: none !important;
  }

  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxxl-row {
    flex-direction: row !important;
  }

  .flex-xxxl-column {
    flex-direction: column !important;
  }

  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxxl-0 {
    gap: 0 !important;
  }

  .gap-xxxl-1 {
    gap: 0.375rem !important;
  }

  .gap-xxxl-2 {
    gap: 0.75rem !important;
  }

  .gap-xxxl-3 {
    gap: 1.5rem !important;
  }

  .gap-xxxl-4 {
    gap: 2.25rem !important;
  }

  .gap-xxxl-5 {
    gap: 4.5rem !important;
  }

  .gap-xxxl-6 {
    gap: 6.75rem !important;
  }

  .gap-xxxl-7 {
    gap: 9rem !important;
  }

  .gap-xxxl-8 {
    gap: 11.25rem !important;
  }

  .gap-xxxl-9 {
    gap: 15rem !important;
  }

  .gap-xxxl-10 {
    gap: 18.75rem !important;
  }

  .gap-xxxl-11 {
    gap: 22.5rem !important;
  }

  .gap-xxxl-12 {
    gap: 26.25rem !important;
  }

  .gap-xxxl-13 {
    gap: 30rem !important;
  }

  .gap-xxxl-14 {
    gap: 37.5rem !important;
  }

  .gap-xxxl-15 {
    gap: 45rem !important;
  }

  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxxl-center {
    justify-content: center !important;
  }

  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxxl-center {
    align-items: center !important;
  }

  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxxl-center {
    align-content: center !important;
  }

  .align-content-xxxl-between {
    align-content: space-between !important;
  }

  .align-content-xxxl-around {
    align-content: space-around !important;
  }

  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxxl-auto {
    align-self: auto !important;
  }

  .align-self-xxxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxxl-center {
    align-self: center !important;
  }

  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }

  .order-xxxl-first {
    order: -1 !important;
  }

  .order-xxxl-0 {
    order: 0 !important;
  }

  .order-xxxl-1 {
    order: 1 !important;
  }

  .order-xxxl-2 {
    order: 2 !important;
  }

  .order-xxxl-3 {
    order: 3 !important;
  }

  .order-xxxl-4 {
    order: 4 !important;
  }

  .order-xxxl-5 {
    order: 5 !important;
  }

  .order-xxxl-last {
    order: 6 !important;
  }

  .m-xxxl-0 {
    margin: 0 !important;
  }

  .m-xxxl-1 {
    margin: 0.375rem !important;
  }

  .m-xxxl-2 {
    margin: 0.75rem !important;
  }

  .m-xxxl-3 {
    margin: 1.5rem !important;
  }

  .m-xxxl-4 {
    margin: 2.25rem !important;
  }

  .m-xxxl-5 {
    margin: 4.5rem !important;
  }

  .m-xxxl-6 {
    margin: 6.75rem !important;
  }

  .m-xxxl-7 {
    margin: 9rem !important;
  }

  .m-xxxl-8 {
    margin: 11.25rem !important;
  }

  .m-xxxl-9 {
    margin: 15rem !important;
  }

  .m-xxxl-10 {
    margin: 18.75rem !important;
  }

  .m-xxxl-11 {
    margin: 22.5rem !important;
  }

  .m-xxxl-12 {
    margin: 26.25rem !important;
  }

  .m-xxxl-13 {
    margin: 30rem !important;
  }

  .m-xxxl-14 {
    margin: 37.5rem !important;
  }

  .m-xxxl-15 {
    margin: 45rem !important;
  }

  .m-xxxl-auto {
    margin: auto !important;
  }

  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }

  .mx-xxxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxxl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxxl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxxl-6 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important;
  }

  .mx-xxxl-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xxxl-8 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }

  .mx-xxxl-9 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }

  .mx-xxxl-10 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }

  .mx-xxxl-11 {
    margin-right: 22.5rem !important;
    margin-left: 22.5rem !important;
  }

  .mx-xxxl-12 {
    margin-right: 26.25rem !important;
    margin-left: 26.25rem !important;
  }

  .mx-xxxl-13 {
    margin-right: 30rem !important;
    margin-left: 30rem !important;
  }

  .mx-xxxl-14 {
    margin-right: 37.5rem !important;
    margin-left: 37.5rem !important;
  }

  .mx-xxxl-15 {
    margin-right: 45rem !important;
    margin-left: 45rem !important;
  }

  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .my-xxxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxxl-6 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important;
  }

  .my-xxxl-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xxxl-8 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }

  .my-xxxl-9 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .my-xxxl-10 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }

  .my-xxxl-11 {
    margin-top: 22.5rem !important;
    margin-bottom: 22.5rem !important;
  }

  .my-xxxl-12 {
    margin-top: 26.25rem !important;
    margin-bottom: 26.25rem !important;
  }

  .my-xxxl-13 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important;
  }

  .my-xxxl-14 {
    margin-top: 37.5rem !important;
    margin-bottom: 37.5rem !important;
  }

  .my-xxxl-15 {
    margin-top: 45rem !important;
    margin-bottom: 45rem !important;
  }

  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxxl-1 {
    margin-top: 0.375rem !important;
  }

  .mt-xxxl-2 {
    margin-top: 0.75rem !important;
  }

  .mt-xxxl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xxxl-4 {
    margin-top: 2.25rem !important;
  }

  .mt-xxxl-5 {
    margin-top: 4.5rem !important;
  }

  .mt-xxxl-6 {
    margin-top: 6.75rem !important;
  }

  .mt-xxxl-7 {
    margin-top: 9rem !important;
  }

  .mt-xxxl-8 {
    margin-top: 11.25rem !important;
  }

  .mt-xxxl-9 {
    margin-top: 15rem !important;
  }

  .mt-xxxl-10 {
    margin-top: 18.75rem !important;
  }

  .mt-xxxl-11 {
    margin-top: 22.5rem !important;
  }

  .mt-xxxl-12 {
    margin-top: 26.25rem !important;
  }

  .mt-xxxl-13 {
    margin-top: 30rem !important;
  }

  .mt-xxxl-14 {
    margin-top: 37.5rem !important;
  }

  .mt-xxxl-15 {
    margin-top: 45rem !important;
  }

  .mt-xxxl-auto {
    margin-top: auto !important;
  }

  .me-xxxl-0 {
    margin-right: 0 !important;
  }

  .me-xxxl-1 {
    margin-right: 0.375rem !important;
  }

  .me-xxxl-2 {
    margin-right: 0.75rem !important;
  }

  .me-xxxl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xxxl-4 {
    margin-right: 2.25rem !important;
  }

  .me-xxxl-5 {
    margin-right: 4.5rem !important;
  }

  .me-xxxl-6 {
    margin-right: 6.75rem !important;
  }

  .me-xxxl-7 {
    margin-right: 9rem !important;
  }

  .me-xxxl-8 {
    margin-right: 11.25rem !important;
  }

  .me-xxxl-9 {
    margin-right: 15rem !important;
  }

  .me-xxxl-10 {
    margin-right: 18.75rem !important;
  }

  .me-xxxl-11 {
    margin-right: 22.5rem !important;
  }

  .me-xxxl-12 {
    margin-right: 26.25rem !important;
  }

  .me-xxxl-13 {
    margin-right: 30rem !important;
  }

  .me-xxxl-14 {
    margin-right: 37.5rem !important;
  }

  .me-xxxl-15 {
    margin-right: 45rem !important;
  }

  .me-xxxl-auto {
    margin-right: auto !important;
  }

  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxxl-1 {
    margin-bottom: 0.375rem !important;
  }

  .mb-xxxl-2 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxxl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxxl-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxxl-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxxl-6 {
    margin-bottom: 6.75rem !important;
  }

  .mb-xxxl-7 {
    margin-bottom: 9rem !important;
  }

  .mb-xxxl-8 {
    margin-bottom: 11.25rem !important;
  }

  .mb-xxxl-9 {
    margin-bottom: 15rem !important;
  }

  .mb-xxxl-10 {
    margin-bottom: 18.75rem !important;
  }

  .mb-xxxl-11 {
    margin-bottom: 22.5rem !important;
  }

  .mb-xxxl-12 {
    margin-bottom: 26.25rem !important;
  }

  .mb-xxxl-13 {
    margin-bottom: 30rem !important;
  }

  .mb-xxxl-14 {
    margin-bottom: 37.5rem !important;
  }

  .mb-xxxl-15 {
    margin-bottom: 45rem !important;
  }

  .mb-xxxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxxl-1 {
    margin-left: 0.375rem !important;
  }

  .ms-xxxl-2 {
    margin-left: 0.75rem !important;
  }

  .ms-xxxl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xxxl-4 {
    margin-left: 2.25rem !important;
  }

  .ms-xxxl-5 {
    margin-left: 4.5rem !important;
  }

  .ms-xxxl-6 {
    margin-left: 6.75rem !important;
  }

  .ms-xxxl-7 {
    margin-left: 9rem !important;
  }

  .ms-xxxl-8 {
    margin-left: 11.25rem !important;
  }

  .ms-xxxl-9 {
    margin-left: 15rem !important;
  }

  .ms-xxxl-10 {
    margin-left: 18.75rem !important;
  }

  .ms-xxxl-11 {
    margin-left: 22.5rem !important;
  }

  .ms-xxxl-12 {
    margin-left: 26.25rem !important;
  }

  .ms-xxxl-13 {
    margin-left: 30rem !important;
  }

  .ms-xxxl-14 {
    margin-left: 37.5rem !important;
  }

  .ms-xxxl-15 {
    margin-left: 45rem !important;
  }

  .ms-xxxl-auto {
    margin-left: auto !important;
  }

  .p-xxxl-0 {
    padding: 0 !important;
  }

  .p-xxxl-1 {
    padding: 0.375rem !important;
  }

  .p-xxxl-2 {
    padding: 0.75rem !important;
  }

  .p-xxxl-3 {
    padding: 1.5rem !important;
  }

  .p-xxxl-4 {
    padding: 2.25rem !important;
  }

  .p-xxxl-5 {
    padding: 4.5rem !important;
  }

  .p-xxxl-6 {
    padding: 6.75rem !important;
  }

  .p-xxxl-7 {
    padding: 9rem !important;
  }

  .p-xxxl-8 {
    padding: 11.25rem !important;
  }

  .p-xxxl-9 {
    padding: 15rem !important;
  }

  .p-xxxl-10 {
    padding: 18.75rem !important;
  }

  .p-xxxl-11 {
    padding: 22.5rem !important;
  }

  .p-xxxl-12 {
    padding: 26.25rem !important;
  }

  .p-xxxl-13 {
    padding: 30rem !important;
  }

  .p-xxxl-14 {
    padding: 37.5rem !important;
  }

  .p-xxxl-15 {
    padding: 45rem !important;
  }

  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }

  .px-xxxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxxl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxxl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxxl-6 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important;
  }

  .px-xxxl-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xxxl-8 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }

  .px-xxxl-9 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }

  .px-xxxl-10 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }

  .px-xxxl-11 {
    padding-right: 22.5rem !important;
    padding-left: 22.5rem !important;
  }

  .px-xxxl-12 {
    padding-right: 26.25rem !important;
    padding-left: 26.25rem !important;
  }

  .px-xxxl-13 {
    padding-right: 30rem !important;
    padding-left: 30rem !important;
  }

  .px-xxxl-14 {
    padding-right: 37.5rem !important;
    padding-left: 37.5rem !important;
  }

  .px-xxxl-15 {
    padding-right: 45rem !important;
    padding-left: 45rem !important;
  }

  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .py-xxxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxxl-6 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important;
  }

  .py-xxxl-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xxxl-8 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }

  .py-xxxl-9 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .py-xxxl-10 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }

  .py-xxxl-11 {
    padding-top: 22.5rem !important;
    padding-bottom: 22.5rem !important;
  }

  .py-xxxl-12 {
    padding-top: 26.25rem !important;
    padding-bottom: 26.25rem !important;
  }

  .py-xxxl-13 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important;
  }

  .py-xxxl-14 {
    padding-top: 37.5rem !important;
    padding-bottom: 37.5rem !important;
  }

  .py-xxxl-15 {
    padding-top: 45rem !important;
    padding-bottom: 45rem !important;
  }

  .pt-xxxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxxl-1 {
    padding-top: 0.375rem !important;
  }

  .pt-xxxl-2 {
    padding-top: 0.75rem !important;
  }

  .pt-xxxl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xxxl-4 {
    padding-top: 2.25rem !important;
  }

  .pt-xxxl-5 {
    padding-top: 4.5rem !important;
  }

  .pt-xxxl-6 {
    padding-top: 6.75rem !important;
  }

  .pt-xxxl-7 {
    padding-top: 9rem !important;
  }

  .pt-xxxl-8 {
    padding-top: 11.25rem !important;
  }

  .pt-xxxl-9 {
    padding-top: 15rem !important;
  }

  .pt-xxxl-10 {
    padding-top: 18.75rem !important;
  }

  .pt-xxxl-11 {
    padding-top: 22.5rem !important;
  }

  .pt-xxxl-12 {
    padding-top: 26.25rem !important;
  }

  .pt-xxxl-13 {
    padding-top: 30rem !important;
  }

  .pt-xxxl-14 {
    padding-top: 37.5rem !important;
  }

  .pt-xxxl-15 {
    padding-top: 45rem !important;
  }

  .pe-xxxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxxl-1 {
    padding-right: 0.375rem !important;
  }

  .pe-xxxl-2 {
    padding-right: 0.75rem !important;
  }

  .pe-xxxl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xxxl-4 {
    padding-right: 2.25rem !important;
  }

  .pe-xxxl-5 {
    padding-right: 4.5rem !important;
  }

  .pe-xxxl-6 {
    padding-right: 6.75rem !important;
  }

  .pe-xxxl-7 {
    padding-right: 9rem !important;
  }

  .pe-xxxl-8 {
    padding-right: 11.25rem !important;
  }

  .pe-xxxl-9 {
    padding-right: 15rem !important;
  }

  .pe-xxxl-10 {
    padding-right: 18.75rem !important;
  }

  .pe-xxxl-11 {
    padding-right: 22.5rem !important;
  }

  .pe-xxxl-12 {
    padding-right: 26.25rem !important;
  }

  .pe-xxxl-13 {
    padding-right: 30rem !important;
  }

  .pe-xxxl-14 {
    padding-right: 37.5rem !important;
  }

  .pe-xxxl-15 {
    padding-right: 45rem !important;
  }

  .pb-xxxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxxl-1 {
    padding-bottom: 0.375rem !important;
  }

  .pb-xxxl-2 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxxl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxxl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxxl-5 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxxl-6 {
    padding-bottom: 6.75rem !important;
  }

  .pb-xxxl-7 {
    padding-bottom: 9rem !important;
  }

  .pb-xxxl-8 {
    padding-bottom: 11.25rem !important;
  }

  .pb-xxxl-9 {
    padding-bottom: 15rem !important;
  }

  .pb-xxxl-10 {
    padding-bottom: 18.75rem !important;
  }

  .pb-xxxl-11 {
    padding-bottom: 22.5rem !important;
  }

  .pb-xxxl-12 {
    padding-bottom: 26.25rem !important;
  }

  .pb-xxxl-13 {
    padding-bottom: 30rem !important;
  }

  .pb-xxxl-14 {
    padding-bottom: 37.5rem !important;
  }

  .pb-xxxl-15 {
    padding-bottom: 45rem !important;
  }

  .ps-xxxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxxl-1 {
    padding-left: 0.375rem !important;
  }

  .ps-xxxl-2 {
    padding-left: 0.75rem !important;
  }

  .ps-xxxl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xxxl-4 {
    padding-left: 2.25rem !important;
  }

  .ps-xxxl-5 {
    padding-left: 4.5rem !important;
  }

  .ps-xxxl-6 {
    padding-left: 6.75rem !important;
  }

  .ps-xxxl-7 {
    padding-left: 9rem !important;
  }

  .ps-xxxl-8 {
    padding-left: 11.25rem !important;
  }

  .ps-xxxl-9 {
    padding-left: 15rem !important;
  }

  .ps-xxxl-10 {
    padding-left: 18.75rem !important;
  }

  .ps-xxxl-11 {
    padding-left: 22.5rem !important;
  }

  .ps-xxxl-12 {
    padding-left: 26.25rem !important;
  }

  .ps-xxxl-13 {
    padding-left: 30rem !important;
  }

  .ps-xxxl-14 {
    padding-left: 37.5rem !important;
  }

  .ps-xxxl-15 {
    padding-left: 45rem !important;
  }

  .text-xxxl-start {
    text-align: left !important;
  }

  .text-xxxl-end {
    text-align: right !important;
  }

  .text-xxxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */

/* purgecss start ignore */

/** Import theme styles */

@font-face {
  font-family: "AkzidenzGrotesk";
  src: url("../fonts/AkzidenzGrotesk-Black.woff2") format("woff2"), url("../fonts/AkzidenzGrotesk-Black.woff") format("woff");
  font-weight: bold;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* body font */

@font-face {
  font-family: "AkzidenzGrotesk";
  src: url("../fonts/AkzidenzGrotesk-Roman.woff2") format("woff2"), url("../fonts/AkzidenzGrotesk-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* heading font */

@font-face {
  font-family: "Walden";
  src: url("../fonts/Walden-Bold.woff2") format("woff2"), url("../fonts/Walden-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* icon font */

@font-face {
  font-family: "bootstrap-icons";
  src: url("../fonts/bootstrap-icons.woff2") format("woff2"), url("../fonts/bootstrap-icons.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-123::before {
  content: "";
}

.bi-alarm-fill::before {
  content: "";
}

.bi-alarm::before {
  content: "";
}

.bi-align-bottom::before {
  content: "";
}

.bi-align-center::before {
  content: "";
}

.bi-align-end::before {
  content: "";
}

.bi-align-middle::before {
  content: "";
}

.bi-align-start::before {
  content: "";
}

.bi-align-top::before {
  content: "";
}

.bi-alt::before {
  content: "";
}

.bi-app-indicator::before {
  content: "";
}

.bi-app::before {
  content: "";
}

.bi-archive-fill::before {
  content: "";
}

.bi-archive::before {
  content: "";
}

.bi-arrow-90deg-down::before {
  content: "";
}

.bi-arrow-90deg-left::before {
  content: "";
}

.bi-arrow-90deg-right::before {
  content: "";
}

.bi-arrow-90deg-up::before {
  content: "";
}

.bi-arrow-bar-down::before {
  content: "";
}

.bi-arrow-bar-left::before {
  content: "";
}

.bi-arrow-bar-right::before {
  content: "";
}

.bi-arrow-bar-up::before {
  content: "";
}

.bi-arrow-clockwise::before {
  content: "";
}

.bi-arrow-counterclockwise::before {
  content: "";
}

.bi-arrow-down-circle-fill::before {
  content: "";
}

.bi-arrow-down-circle::before {
  content: "";
}

.bi-arrow-down-left-circle-fill::before {
  content: "";
}

.bi-arrow-down-left-circle::before {
  content: "";
}

.bi-arrow-down-left-square-fill::before {
  content: "";
}

.bi-arrow-down-left-square::before {
  content: "";
}

.bi-arrow-down-left::before {
  content: "";
}

.bi-arrow-down-right-circle-fill::before {
  content: "";
}

.bi-arrow-down-right-circle::before {
  content: "";
}

.bi-arrow-down-right-square-fill::before {
  content: "";
}

.bi-arrow-down-right-square::before {
  content: "";
}

.bi-arrow-down-right::before {
  content: "";
}

.bi-arrow-down-short::before {
  content: "";
}

.bi-arrow-down-square-fill::before {
  content: "";
}

.bi-arrow-down-square::before {
  content: "";
}

.bi-arrow-down-up::before {
  content: "";
}

.bi-arrow-down::before {
  content: "";
}

.bi-arrow-left-circle-fill::before {
  content: "";
}

.bi-arrow-left-circle::before {
  content: "";
}

.bi-arrow-left-right::before {
  content: "";
}

.bi-arrow-left-short::before {
  content: "";
}

.bi-arrow-left-square-fill::before {
  content: "";
}

.bi-arrow-left-square::before {
  content: "";
}

.bi-arrow-left::before {
  content: "";
}

.bi-arrow-repeat::before {
  content: "";
}

.bi-arrow-return-left::before {
  content: "";
}

.bi-arrow-return-right::before {
  content: "";
}

.bi-arrow-right-circle-fill::before {
  content: "";
}

.bi-arrow-right-circle::before {
  content: "";
}

.bi-arrow-right-short::before {
  content: "";
}

.bi-arrow-right-square-fill::before {
  content: "";
}

.bi-arrow-right-square::before {
  content: "";
}

.bi-arrow-right::before {
  content: "";
}

.bi-arrow-up-circle-fill::before {
  content: "";
}

.bi-arrow-up-circle::before {
  content: "";
}

.bi-arrow-up-left-circle-fill::before {
  content: "";
}

.bi-arrow-up-left-circle::before {
  content: "";
}

.bi-arrow-up-left-square-fill::before {
  content: "";
}

.bi-arrow-up-left-square::before {
  content: "";
}

.bi-arrow-up-left::before {
  content: "";
}

.bi-arrow-up-right-circle-fill::before {
  content: "";
}

.bi-arrow-up-right-circle::before {
  content: "";
}

.bi-arrow-up-right-square-fill::before {
  content: "";
}

.bi-arrow-up-right-square::before {
  content: "";
}

.bi-arrow-up-right::before {
  content: "";
}

.bi-arrow-up-short::before {
  content: "";
}

.bi-arrow-up-square-fill::before {
  content: "";
}

.bi-arrow-up-square::before {
  content: "";
}

.bi-arrow-up::before {
  content: "";
}

.bi-arrows-angle-contract::before {
  content: "";
}

.bi-arrows-angle-expand::before {
  content: "";
}

.bi-arrows-collapse::before {
  content: "";
}

.bi-arrows-expand::before {
  content: "";
}

.bi-arrows-fullscreen::before {
  content: "";
}

.bi-arrows-move::before {
  content: "";
}

.bi-aspect-ratio-fill::before {
  content: "";
}

.bi-aspect-ratio::before {
  content: "";
}

.bi-asterisk::before {
  content: "";
}

.bi-at::before {
  content: "";
}

.bi-award-fill::before {
  content: "";
}

.bi-award::before {
  content: "";
}

.bi-back::before {
  content: "";
}

.bi-backspace-fill::before {
  content: "";
}

.bi-backspace-reverse-fill::before {
  content: "";
}

.bi-backspace-reverse::before {
  content: "";
}

.bi-backspace::before {
  content: "";
}

.bi-badge-3d-fill::before {
  content: "";
}

.bi-badge-3d::before {
  content: "";
}

.bi-badge-4k-fill::before {
  content: "";
}

.bi-badge-4k::before {
  content: "";
}

.bi-badge-8k-fill::before {
  content: "";
}

.bi-badge-8k::before {
  content: "";
}

.bi-badge-ad-fill::before {
  content: "";
}

.bi-badge-ad::before {
  content: "";
}

.bi-badge-ar-fill::before {
  content: "";
}

.bi-badge-ar::before {
  content: "";
}

.bi-badge-cc-fill::before {
  content: "";
}

.bi-badge-cc::before {
  content: "";
}

.bi-badge-hd-fill::before {
  content: "";
}

.bi-badge-hd::before {
  content: "";
}

.bi-badge-tm-fill::before {
  content: "";
}

.bi-badge-tm::before {
  content: "";
}

.bi-badge-vo-fill::before {
  content: "";
}

.bi-badge-vo::before {
  content: "";
}

.bi-badge-vr-fill::before {
  content: "";
}

.bi-badge-vr::before {
  content: "";
}

.bi-badge-wc-fill::before {
  content: "";
}

.bi-badge-wc::before {
  content: "";
}

.bi-bag-check-fill::before {
  content: "";
}

.bi-bag-check::before {
  content: "";
}

.bi-bag-dash-fill::before {
  content: "";
}

.bi-bag-dash::before {
  content: "";
}

.bi-bag-fill::before {
  content: "";
}

.bi-bag-plus-fill::before {
  content: "";
}

.bi-bag-plus::before {
  content: "";
}

.bi-bag-x-fill::before {
  content: "";
}

.bi-bag-x::before {
  content: "";
}

.bi-bag::before {
  content: "";
}

.bi-bar-chart-fill::before {
  content: "";
}

.bi-bar-chart-line-fill::before {
  content: "";
}

.bi-bar-chart-line::before {
  content: "";
}

.bi-bar-chart-steps::before {
  content: "";
}

.bi-bar-chart::before {
  content: "";
}

.bi-basket-fill::before {
  content: "";
}

.bi-basket::before {
  content: "";
}

.bi-basket2-fill::before {
  content: "";
}

.bi-basket2::before {
  content: "";
}

.bi-basket3-fill::before {
  content: "";
}

.bi-basket3::before {
  content: "";
}

.bi-battery-charging::before {
  content: "";
}

.bi-battery-full::before {
  content: "";
}

.bi-battery-half::before {
  content: "";
}

.bi-battery::before {
  content: "";
}

.bi-bell-fill::before {
  content: "";
}

.bi-bell::before {
  content: "";
}

.bi-bezier::before {
  content: "";
}

.bi-bezier2::before {
  content: "";
}

.bi-bicycle::before {
  content: "";
}

.bi-binoculars-fill::before {
  content: "";
}

.bi-binoculars::before {
  content: "";
}

.bi-blockquote-left::before {
  content: "";
}

.bi-blockquote-right::before {
  content: "";
}

.bi-book-fill::before {
  content: "";
}

.bi-book-half::before {
  content: "";
}

.bi-book::before {
  content: "";
}

.bi-bookmark-check-fill::before {
  content: "";
}

.bi-bookmark-check::before {
  content: "";
}

.bi-bookmark-dash-fill::before {
  content: "";
}

.bi-bookmark-dash::before {
  content: "";
}

.bi-bookmark-fill::before {
  content: "";
}

.bi-bookmark-heart-fill::before {
  content: "";
}

.bi-bookmark-heart::before {
  content: "";
}

.bi-bookmark-plus-fill::before {
  content: "";
}

.bi-bookmark-plus::before {
  content: "";
}

.bi-bookmark-star-fill::before {
  content: "";
}

.bi-bookmark-star::before {
  content: "";
}

.bi-bookmark-x-fill::before {
  content: "";
}

.bi-bookmark-x::before {
  content: "";
}

.bi-bookmark::before {
  content: "";
}

.bi-bookmarks-fill::before {
  content: "";
}

.bi-bookmarks::before {
  content: "";
}

.bi-bookshelf::before {
  content: "";
}

.bi-bootstrap-fill::before {
  content: "";
}

.bi-bootstrap-reboot::before {
  content: "";
}

.bi-bootstrap::before {
  content: "";
}

.bi-border-all::before {
  content: "";
}

.bi-border-bottom::before {
  content: "";
}

.bi-border-center::before {
  content: "";
}

.bi-border-inner::before {
  content: "";
}

.bi-border-left::before {
  content: "";
}

.bi-border-middle::before {
  content: "";
}

.bi-border-outer::before {
  content: "";
}

.bi-border-right::before {
  content: "";
}

.bi-border-style::before {
  content: "";
}

.bi-border-top::before {
  content: "";
}

.bi-border-width::before {
  content: "";
}

.bi-border::before {
  content: "";
}

.bi-bounding-box-circles::before {
  content: "";
}

.bi-bounding-box::before {
  content: "";
}

.bi-box-arrow-down-left::before {
  content: "";
}

.bi-box-arrow-down-right::before {
  content: "";
}

.bi-box-arrow-down::before {
  content: "";
}

.bi-box-arrow-in-down-left::before {
  content: "";
}

.bi-box-arrow-in-down-right::before {
  content: "";
}

.bi-box-arrow-in-down::before {
  content: "";
}

.bi-box-arrow-in-left::before {
  content: "";
}

.bi-box-arrow-in-right::before {
  content: "";
}

.bi-box-arrow-in-up-left::before {
  content: "";
}

.bi-box-arrow-in-up-right::before {
  content: "";
}

.bi-box-arrow-in-up::before {
  content: "";
}

.bi-box-arrow-left::before {
  content: "";
}

.bi-box-arrow-right::before {
  content: "";
}

.bi-box-arrow-up-left::before {
  content: "";
}

.bi-box-arrow-up-right::before {
  content: "";
}

.bi-box-arrow-up::before {
  content: "";
}

.bi-box-seam::before {
  content: "";
}

.bi-box::before {
  content: "";
}

.bi-braces::before {
  content: "";
}

.bi-bricks::before {
  content: "";
}

.bi-briefcase-fill::before {
  content: "";
}

.bi-briefcase::before {
  content: "";
}

.bi-brightness-alt-high-fill::before {
  content: "";
}

.bi-brightness-alt-high::before {
  content: "";
}

.bi-brightness-alt-low-fill::before {
  content: "";
}

.bi-brightness-alt-low::before {
  content: "";
}

.bi-brightness-high-fill::before {
  content: "";
}

.bi-brightness-high::before {
  content: "";
}

.bi-brightness-low-fill::before {
  content: "";
}

.bi-brightness-low::before {
  content: "";
}

.bi-broadcast-pin::before {
  content: "";
}

.bi-broadcast::before {
  content: "";
}

.bi-brush-fill::before {
  content: "";
}

.bi-brush::before {
  content: "";
}

.bi-bucket-fill::before {
  content: "";
}

.bi-bucket::before {
  content: "";
}

.bi-bug-fill::before {
  content: "";
}

.bi-bug::before {
  content: "";
}

.bi-building::before {
  content: "";
}

.bi-bullseye::before {
  content: "";
}

.bi-calculator-fill::before {
  content: "";
}

.bi-calculator::before {
  content: "";
}

.bi-calendar-check-fill::before {
  content: "";
}

.bi-calendar-check::before {
  content: "";
}

.bi-calendar-date-fill::before {
  content: "";
}

.bi-calendar-date::before {
  content: "";
}

.bi-calendar-day-fill::before {
  content: "";
}

.bi-calendar-day::before {
  content: "";
}

.bi-calendar-event-fill::before {
  content: "";
}

.bi-calendar-event::before {
  content: "";
}

.bi-calendar-fill::before {
  content: "";
}

.bi-calendar-minus-fill::before {
  content: "";
}

.bi-calendar-minus::before {
  content: "";
}

.bi-calendar-month-fill::before {
  content: "";
}

.bi-calendar-month::before {
  content: "";
}

.bi-calendar-plus-fill::before {
  content: "";
}

.bi-calendar-plus::before {
  content: "";
}

.bi-calendar-range-fill::before {
  content: "";
}

.bi-calendar-range::before {
  content: "";
}

.bi-calendar-week-fill::before {
  content: "";
}

.bi-calendar-week::before {
  content: "";
}

.bi-calendar-x-fill::before {
  content: "";
}

.bi-calendar-x::before {
  content: "";
}

.bi-calendar::before {
  content: "";
}

.bi-calendar2-check-fill::before {
  content: "";
}

.bi-calendar2-check::before {
  content: "";
}

.bi-calendar2-date-fill::before {
  content: "";
}

.bi-calendar2-date::before {
  content: "";
}

.bi-calendar2-day-fill::before {
  content: "";
}

.bi-calendar2-day::before {
  content: "";
}

.bi-calendar2-event-fill::before {
  content: "";
}

.bi-calendar2-event::before {
  content: "";
}

.bi-calendar2-fill::before {
  content: "";
}

.bi-calendar2-minus-fill::before {
  content: "";
}

.bi-calendar2-minus::before {
  content: "";
}

.bi-calendar2-month-fill::before {
  content: "";
}

.bi-calendar2-month::before {
  content: "";
}

.bi-calendar2-plus-fill::before {
  content: "";
}

.bi-calendar2-plus::before {
  content: "";
}

.bi-calendar2-range-fill::before {
  content: "";
}

.bi-calendar2-range::before {
  content: "";
}

.bi-calendar2-week-fill::before {
  content: "";
}

.bi-calendar2-week::before {
  content: "";
}

.bi-calendar2-x-fill::before {
  content: "";
}

.bi-calendar2-x::before {
  content: "";
}

.bi-calendar2::before {
  content: "";
}

.bi-calendar3-event-fill::before {
  content: "";
}

.bi-calendar3-event::before {
  content: "";
}

.bi-calendar3-fill::before {
  content: "";
}

.bi-calendar3-range-fill::before {
  content: "";
}

.bi-calendar3-range::before {
  content: "";
}

.bi-calendar3-week-fill::before {
  content: "";
}

.bi-calendar3-week::before {
  content: "";
}

.bi-calendar3::before {
  content: "";
}

.bi-calendar4-event::before {
  content: "";
}

.bi-calendar4-range::before {
  content: "";
}

.bi-calendar4-week::before {
  content: "";
}

.bi-calendar4::before {
  content: "";
}

.bi-camera-fill::before {
  content: "";
}

.bi-camera-reels-fill::before {
  content: "";
}

.bi-camera-reels::before {
  content: "";
}

.bi-camera-video-fill::before {
  content: "";
}

.bi-camera-video-off-fill::before {
  content: "";
}

.bi-camera-video-off::before {
  content: "";
}

.bi-camera-video::before {
  content: "";
}

.bi-camera::before {
  content: "";
}

.bi-camera2::before {
  content: "";
}

.bi-capslock-fill::before {
  content: "";
}

.bi-capslock::before {
  content: "";
}

.bi-card-checklist::before {
  content: "";
}

.bi-card-heading::before {
  content: "";
}

.bi-card-image::before {
  content: "";
}

.bi-card-list::before {
  content: "";
}

.bi-card-text::before {
  content: "";
}

.bi-caret-down-fill::before {
  content: "";
}

.bi-caret-down-square-fill::before {
  content: "";
}

.bi-caret-down-square::before {
  content: "";
}

.bi-caret-down::before {
  content: "";
}

.bi-caret-left-fill::before {
  content: "";
}

.bi-caret-left-square-fill::before {
  content: "";
}

.bi-caret-left-square::before {
  content: "";
}

.bi-caret-left::before {
  content: "";
}

.bi-caret-right-fill::before {
  content: "";
}

.bi-caret-right-square-fill::before {
  content: "";
}

.bi-caret-right-square::before {
  content: "";
}

.bi-caret-right::before {
  content: "";
}

.bi-caret-up-fill::before {
  content: "";
}

.bi-caret-up-square-fill::before {
  content: "";
}

.bi-caret-up-square::before {
  content: "";
}

.bi-caret-up::before {
  content: "";
}

.bi-cart-check-fill::before {
  content: "";
}

.bi-cart-check::before {
  content: "";
}

.bi-cart-dash-fill::before {
  content: "";
}

.bi-cart-dash::before {
  content: "";
}

.bi-cart-fill::before {
  content: "";
}

.bi-cart-plus-fill::before {
  content: "";
}

.bi-cart-plus::before {
  content: "";
}

.bi-cart-x-fill::before {
  content: "";
}

.bi-cart-x::before {
  content: "";
}

.bi-cart::before {
  content: "";
}

.bi-cart2::before {
  content: "";
}

.bi-cart3::before {
  content: "";
}

.bi-cart4::before {
  content: "";
}

.bi-cash-stack::before {
  content: "";
}

.bi-cash::before {
  content: "";
}

.bi-cast::before {
  content: "";
}

.bi-chat-dots-fill::before {
  content: "";
}

.bi-chat-dots::before {
  content: "";
}

.bi-chat-fill::before {
  content: "";
}

.bi-chat-left-dots-fill::before {
  content: "";
}

.bi-chat-left-dots::before {
  content: "";
}

.bi-chat-left-fill::before {
  content: "";
}

.bi-chat-left-quote-fill::before {
  content: "";
}

.bi-chat-left-quote::before {
  content: "";
}

.bi-chat-left-text-fill::before {
  content: "";
}

.bi-chat-left-text::before {
  content: "";
}

.bi-chat-left::before {
  content: "";
}

.bi-chat-quote-fill::before {
  content: "";
}

.bi-chat-quote::before {
  content: "";
}

.bi-chat-right-dots-fill::before {
  content: "";
}

.bi-chat-right-dots::before {
  content: "";
}

.bi-chat-right-fill::before {
  content: "";
}

.bi-chat-right-quote-fill::before {
  content: "";
}

.bi-chat-right-quote::before {
  content: "";
}

.bi-chat-right-text-fill::before {
  content: "";
}

.bi-chat-right-text::before {
  content: "";
}

.bi-chat-right::before {
  content: "";
}

.bi-chat-square-dots-fill::before {
  content: "";
}

.bi-chat-square-dots::before {
  content: "";
}

.bi-chat-square-fill::before {
  content: "";
}

.bi-chat-square-quote-fill::before {
  content: "";
}

.bi-chat-square-quote::before {
  content: "";
}

.bi-chat-square-text-fill::before {
  content: "";
}

.bi-chat-square-text::before {
  content: "";
}

.bi-chat-square::before {
  content: "";
}

.bi-chat-text-fill::before {
  content: "";
}

.bi-chat-text::before {
  content: "";
}

.bi-chat::before {
  content: "";
}

.bi-check-all::before {
  content: "";
}

.bi-check-circle-fill::before {
  content: "";
}

.bi-check-circle::before {
  content: "";
}

.bi-check-square-fill::before {
  content: "";
}

.bi-check-square::before {
  content: "";
}

.bi-check::before {
  content: "";
}

.bi-check2-all::before {
  content: "";
}

.bi-check2-circle::before {
  content: "";
}

.bi-check2-square::before {
  content: "";
}

.bi-check2::before {
  content: "";
}

.bi-chevron-bar-contract::before {
  content: "";
}

.bi-chevron-bar-down::before {
  content: "";
}

.bi-chevron-bar-expand::before {
  content: "";
}

.bi-chevron-bar-left::before {
  content: "";
}

.bi-chevron-bar-right::before {
  content: "";
}

.bi-chevron-bar-up::before {
  content: "";
}

.bi-chevron-compact-down::before {
  content: "";
}

.bi-chevron-compact-left::before {
  content: "";
}

.bi-chevron-compact-right::before {
  content: "";
}

.bi-chevron-compact-up::before {
  content: "";
}

.bi-chevron-contract::before {
  content: "";
}

.bi-chevron-double-down::before {
  content: "";
}

.bi-chevron-double-left::before {
  content: "";
}

.bi-chevron-double-right::before {
  content: "";
}

.bi-chevron-double-up::before {
  content: "";
}

.bi-chevron-down::before {
  content: "";
}

.bi-chevron-expand::before {
  content: "";
}

.bi-chevron-left::before {
  content: "";
}

.bi-chevron-right::before {
  content: "";
}

.bi-chevron-up::before {
  content: "";
}

.bi-circle-fill::before {
  content: "";
}

.bi-circle-half::before {
  content: "";
}

.bi-circle-square::before {
  content: "";
}

.bi-circle::before {
  content: "";
}

.bi-clipboard-check::before {
  content: "";
}

.bi-clipboard-data::before {
  content: "";
}

.bi-clipboard-minus::before {
  content: "";
}

.bi-clipboard-plus::before {
  content: "";
}

.bi-clipboard-x::before {
  content: "";
}

.bi-clipboard::before {
  content: "";
}

.bi-clock-fill::before {
  content: "";
}

.bi-clock-history::before {
  content: "";
}

.bi-clock::before {
  content: "";
}

.bi-cloud-arrow-down-fill::before {
  content: "";
}

.bi-cloud-arrow-down::before {
  content: "";
}

.bi-cloud-arrow-up-fill::before {
  content: "";
}

.bi-cloud-arrow-up::before {
  content: "";
}

.bi-cloud-check-fill::before {
  content: "";
}

.bi-cloud-check::before {
  content: "";
}

.bi-cloud-download-fill::before {
  content: "";
}

.bi-cloud-download::before {
  content: "";
}

.bi-cloud-drizzle-fill::before {
  content: "";
}

.bi-cloud-drizzle::before {
  content: "";
}

.bi-cloud-fill::before {
  content: "";
}

.bi-cloud-fog-fill::before {
  content: "";
}

.bi-cloud-fog::before {
  content: "";
}

.bi-cloud-fog2-fill::before {
  content: "";
}

.bi-cloud-fog2::before {
  content: "";
}

.bi-cloud-hail-fill::before {
  content: "";
}

.bi-cloud-hail::before {
  content: "";
}

.bi-cloud-haze-1::before {
  content: "";
}

.bi-cloud-haze-fill::before {
  content: "";
}

.bi-cloud-haze::before {
  content: "";
}

.bi-cloud-haze2-fill::before {
  content: "";
}

.bi-cloud-lightning-fill::before {
  content: "";
}

.bi-cloud-lightning-rain-fill::before {
  content: "";
}

.bi-cloud-lightning-rain::before {
  content: "";
}

.bi-cloud-lightning::before {
  content: "";
}

.bi-cloud-minus-fill::before {
  content: "";
}

.bi-cloud-minus::before {
  content: "";
}

.bi-cloud-moon-fill::before {
  content: "";
}

.bi-cloud-moon::before {
  content: "";
}

.bi-cloud-plus-fill::before {
  content: "";
}

.bi-cloud-plus::before {
  content: "";
}

.bi-cloud-rain-fill::before {
  content: "";
}

.bi-cloud-rain-heavy-fill::before {
  content: "";
}

.bi-cloud-rain-heavy::before {
  content: "";
}

.bi-cloud-rain::before {
  content: "";
}

.bi-cloud-slash-fill::before {
  content: "";
}

.bi-cloud-slash::before {
  content: "";
}

.bi-cloud-sleet-fill::before {
  content: "";
}

.bi-cloud-sleet::before {
  content: "";
}

.bi-cloud-snow-fill::before {
  content: "";
}

.bi-cloud-snow::before {
  content: "";
}

.bi-cloud-sun-fill::before {
  content: "";
}

.bi-cloud-sun::before {
  content: "";
}

.bi-cloud-upload-fill::before {
  content: "";
}

.bi-cloud-upload::before {
  content: "";
}

.bi-cloud::before {
  content: "";
}

.bi-clouds-fill::before {
  content: "";
}

.bi-clouds::before {
  content: "";
}

.bi-cloudy-fill::before {
  content: "";
}

.bi-cloudy::before {
  content: "";
}

.bi-code-slash::before {
  content: "";
}

.bi-code-square::before {
  content: "";
}

.bi-code::before {
  content: "";
}

.bi-collection-fill::before {
  content: "";
}

.bi-collection-play-fill::before {
  content: "";
}

.bi-collection-play::before {
  content: "";
}

.bi-collection::before {
  content: "";
}

.bi-columns-gap::before {
  content: "";
}

.bi-columns::before {
  content: "";
}

.bi-command::before {
  content: "";
}

.bi-compass-fill::before {
  content: "";
}

.bi-compass::before {
  content: "";
}

.bi-cone-striped::before {
  content: "";
}

.bi-cone::before {
  content: "";
}

.bi-controller::before {
  content: "";
}

.bi-cpu-fill::before {
  content: "";
}

.bi-cpu::before {
  content: "";
}

.bi-credit-card-2-back-fill::before {
  content: "";
}

.bi-credit-card-2-back::before {
  content: "";
}

.bi-credit-card-2-front-fill::before {
  content: "";
}

.bi-credit-card-2-front::before {
  content: "";
}

.bi-credit-card-fill::before {
  content: "";
}

.bi-credit-card::before {
  content: "";
}

.bi-crop::before {
  content: "";
}

.bi-cup-fill::before {
  content: "";
}

.bi-cup-straw::before {
  content: "";
}

.bi-cup::before {
  content: "";
}

.bi-cursor-fill::before {
  content: "";
}

.bi-cursor-text::before {
  content: "";
}

.bi-cursor::before {
  content: "";
}

.bi-dash-circle-dotted::before {
  content: "";
}

.bi-dash-circle-fill::before {
  content: "";
}

.bi-dash-circle::before {
  content: "";
}

.bi-dash-square-dotted::before {
  content: "";
}

.bi-dash-square-fill::before {
  content: "";
}

.bi-dash-square::before {
  content: "";
}

.bi-dash::before {
  content: "";
}

.bi-diagram-2-fill::before {
  content: "";
}

.bi-diagram-2::before {
  content: "";
}

.bi-diagram-3-fill::before {
  content: "";
}

.bi-diagram-3::before {
  content: "";
}

.bi-diamond-fill::before {
  content: "";
}

.bi-diamond-half::before {
  content: "";
}

.bi-diamond::before {
  content: "";
}

.bi-dice-1-fill::before {
  content: "";
}

.bi-dice-1::before {
  content: "";
}

.bi-dice-2-fill::before {
  content: "";
}

.bi-dice-2::before {
  content: "";
}

.bi-dice-3-fill::before {
  content: "";
}

.bi-dice-3::before {
  content: "";
}

.bi-dice-4-fill::before {
  content: "";
}

.bi-dice-4::before {
  content: "";
}

.bi-dice-5-fill::before {
  content: "";
}

.bi-dice-5::before {
  content: "";
}

.bi-dice-6-fill::before {
  content: "";
}

.bi-dice-6::before {
  content: "";
}

.bi-disc-fill::before {
  content: "";
}

.bi-disc::before {
  content: "";
}

.bi-discord::before {
  content: "";
}

.bi-display-fill::before {
  content: "";
}

.bi-display::before {
  content: "";
}

.bi-distribute-horizontal::before {
  content: "";
}

.bi-distribute-vertical::before {
  content: "";
}

.bi-door-closed-fill::before {
  content: "";
}

.bi-door-closed::before {
  content: "";
}

.bi-door-open-fill::before {
  content: "";
}

.bi-door-open::before {
  content: "";
}

.bi-dot::before {
  content: "";
}

.bi-download::before {
  content: "";
}

.bi-droplet-fill::before {
  content: "";
}

.bi-droplet-half::before {
  content: "";
}

.bi-droplet::before {
  content: "";
}

.bi-earbuds::before {
  content: "";
}

.bi-easel-fill::before {
  content: "";
}

.bi-easel::before {
  content: "";
}

.bi-egg-fill::before {
  content: "";
}

.bi-egg-fried::before {
  content: "";
}

.bi-egg::before {
  content: "";
}

.bi-eject-fill::before {
  content: "";
}

.bi-eject::before {
  content: "";
}

.bi-emoji-angry-fill::before {
  content: "";
}

.bi-emoji-angry::before {
  content: "";
}

.bi-emoji-dizzy-fill::before {
  content: "";
}

.bi-emoji-dizzy::before {
  content: "";
}

.bi-emoji-expressionless-fill::before {
  content: "";
}

.bi-emoji-expressionless::before {
  content: "";
}

.bi-emoji-frown-fill::before {
  content: "";
}

.bi-emoji-frown::before {
  content: "";
}

.bi-emoji-heart-eyes-fill::before {
  content: "";
}

.bi-emoji-heart-eyes::before {
  content: "";
}

.bi-emoji-laughing-fill::before {
  content: "";
}

.bi-emoji-laughing::before {
  content: "";
}

.bi-emoji-neutral-fill::before {
  content: "";
}

.bi-emoji-neutral::before {
  content: "";
}

.bi-emoji-smile-fill::before {
  content: "";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "";
}

.bi-emoji-smile-upside-down::before {
  content: "";
}

.bi-emoji-smile::before {
  content: "";
}

.bi-emoji-sunglasses-fill::before {
  content: "";
}

.bi-emoji-sunglasses::before {
  content: "";
}

.bi-emoji-wink-fill::before {
  content: "";
}

.bi-emoji-wink::before {
  content: "";
}

.bi-envelope-fill::before {
  content: "";
}

.bi-envelope-open-fill::before {
  content: "";
}

.bi-envelope-open::before {
  content: "";
}

.bi-envelope::before {
  content: "";
}

.bi-eraser-fill::before {
  content: "";
}

.bi-eraser::before {
  content: "";
}

.bi-exclamation-circle-fill::before {
  content: "";
}

.bi-exclamation-circle::before {
  content: "";
}

.bi-exclamation-diamond-fill::before {
  content: "";
}

.bi-exclamation-diamond::before {
  content: "";
}

.bi-exclamation-octagon-fill::before {
  content: "";
}

.bi-exclamation-octagon::before {
  content: "";
}

.bi-exclamation-square-fill::before {
  content: "";
}

.bi-exclamation-square::before {
  content: "";
}

.bi-exclamation-triangle-fill::before {
  content: "";
}

.bi-exclamation-triangle::before {
  content: "";
}

.bi-exclamation::before {
  content: "";
}

.bi-exclude::before {
  content: "";
}

.bi-eye-fill::before {
  content: "";
}

.bi-eye-slash-fill::before {
  content: "";
}

.bi-eye-slash::before {
  content: "";
}

.bi-eye::before {
  content: "";
}

.bi-eyedropper::before {
  content: "";
}

.bi-eyeglasses::before {
  content: "";
}

.bi-facebook::before {
  content: "";
}

.bi-file-arrow-down-fill::before {
  content: "";
}

.bi-file-arrow-down::before {
  content: "";
}

.bi-file-arrow-up-fill::before {
  content: "";
}

.bi-file-arrow-up::before {
  content: "";
}

.bi-file-bar-graph-fill::before {
  content: "";
}

.bi-file-bar-graph::before {
  content: "";
}

.bi-file-binary-fill::before {
  content: "";
}

.bi-file-binary::before {
  content: "";
}

.bi-file-break-fill::before {
  content: "";
}

.bi-file-break::before {
  content: "";
}

.bi-file-check-fill::before {
  content: "";
}

.bi-file-check::before {
  content: "";
}

.bi-file-code-fill::before {
  content: "";
}

.bi-file-code::before {
  content: "";
}

.bi-file-diff-fill::before {
  content: "";
}

.bi-file-diff::before {
  content: "";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "";
}

.bi-file-earmark-arrow-down::before {
  content: "";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "";
}

.bi-file-earmark-arrow-up::before {
  content: "";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "";
}

.bi-file-earmark-bar-graph::before {
  content: "";
}

.bi-file-earmark-binary-fill::before {
  content: "";
}

.bi-file-earmark-binary::before {
  content: "";
}

.bi-file-earmark-break-fill::before {
  content: "";
}

.bi-file-earmark-break::before {
  content: "";
}

.bi-file-earmark-check-fill::before {
  content: "";
}

.bi-file-earmark-check::before {
  content: "";
}

.bi-file-earmark-code-fill::before {
  content: "";
}

.bi-file-earmark-code::before {
  content: "";
}

.bi-file-earmark-diff-fill::before {
  content: "";
}

.bi-file-earmark-diff::before {
  content: "";
}

.bi-file-earmark-easel-fill::before {
  content: "";
}

.bi-file-earmark-easel::before {
  content: "";
}

.bi-file-earmark-excel-fill::before {
  content: "";
}

.bi-file-earmark-excel::before {
  content: "";
}

.bi-file-earmark-fill::before {
  content: "";
}

.bi-file-earmark-font-fill::before {
  content: "";
}

.bi-file-earmark-font::before {
  content: "";
}

.bi-file-earmark-image-fill::before {
  content: "";
}

.bi-file-earmark-image::before {
  content: "";
}

.bi-file-earmark-lock-fill::before {
  content: "";
}

.bi-file-earmark-lock::before {
  content: "";
}

.bi-file-earmark-lock2-fill::before {
  content: "";
}

.bi-file-earmark-lock2::before {
  content: "";
}

.bi-file-earmark-medical-fill::before {
  content: "";
}

.bi-file-earmark-medical::before {
  content: "";
}

.bi-file-earmark-minus-fill::before {
  content: "";
}

.bi-file-earmark-minus::before {
  content: "";
}

.bi-file-earmark-music-fill::before {
  content: "";
}

.bi-file-earmark-music::before {
  content: "";
}

.bi-file-earmark-person-fill::before {
  content: "";
}

.bi-file-earmark-person::before {
  content: "";
}

.bi-file-earmark-play-fill::before {
  content: "";
}

.bi-file-earmark-play::before {
  content: "";
}

.bi-file-earmark-plus-fill::before {
  content: "";
}

.bi-file-earmark-plus::before {
  content: "";
}

.bi-file-earmark-post-fill::before {
  content: "";
}

.bi-file-earmark-post::before {
  content: "";
}

.bi-file-earmark-ppt-fill::before {
  content: "";
}

.bi-file-earmark-ppt::before {
  content: "";
}

.bi-file-earmark-richtext-fill::before {
  content: "";
}

.bi-file-earmark-richtext::before {
  content: "";
}

.bi-file-earmark-ruled-fill::before {
  content: "";
}

.bi-file-earmark-ruled::before {
  content: "";
}

.bi-file-earmark-slides-fill::before {
  content: "";
}

.bi-file-earmark-slides::before {
  content: "";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "";
}

.bi-file-earmark-spreadsheet::before {
  content: "";
}

.bi-file-earmark-text-fill::before {
  content: "";
}

.bi-file-earmark-text::before {
  content: "";
}

.bi-file-earmark-word-fill::before {
  content: "";
}

.bi-file-earmark-word::before {
  content: "";
}

.bi-file-earmark-x-fill::before {
  content: "";
}

.bi-file-earmark-x::before {
  content: "";
}

.bi-file-earmark-zip-fill::before {
  content: "";
}

.bi-file-earmark-zip::before {
  content: "";
}

.bi-file-earmark::before {
  content: "";
}

.bi-file-easel-fill::before {
  content: "";
}

.bi-file-easel::before {
  content: "";
}

.bi-file-excel-fill::before {
  content: "";
}

.bi-file-excel::before {
  content: "";
}

.bi-file-fill::before {
  content: "";
}

.bi-file-font-fill::before {
  content: "";
}

.bi-file-font::before {
  content: "";
}

.bi-file-image-fill::before {
  content: "";
}

.bi-file-image::before {
  content: "";
}

.bi-file-lock-fill::before {
  content: "";
}

.bi-file-lock::before {
  content: "";
}

.bi-file-lock2-fill::before {
  content: "";
}

.bi-file-lock2::before {
  content: "";
}

.bi-file-medical-fill::before {
  content: "";
}

.bi-file-medical::before {
  content: "";
}

.bi-file-minus-fill::before {
  content: "";
}

.bi-file-minus::before {
  content: "";
}

.bi-file-music-fill::before {
  content: "";
}

.bi-file-music::before {
  content: "";
}

.bi-file-person-fill::before {
  content: "";
}

.bi-file-person::before {
  content: "";
}

.bi-file-play-fill::before {
  content: "";
}

.bi-file-play::before {
  content: "";
}

.bi-file-plus-fill::before {
  content: "";
}

.bi-file-plus::before {
  content: "";
}

.bi-file-post-fill::before {
  content: "";
}

.bi-file-post::before {
  content: "";
}

.bi-file-ppt-fill::before {
  content: "";
}

.bi-file-ppt::before {
  content: "";
}

.bi-file-richtext-fill::before {
  content: "";
}

.bi-file-richtext::before {
  content: "";
}

.bi-file-ruled-fill::before {
  content: "";
}

.bi-file-ruled::before {
  content: "";
}

.bi-file-slides-fill::before {
  content: "";
}

.bi-file-slides::before {
  content: "";
}

.bi-file-spreadsheet-fill::before {
  content: "";
}

.bi-file-spreadsheet::before {
  content: "";
}

.bi-file-text-fill::before {
  content: "";
}

.bi-file-text::before {
  content: "";
}

.bi-file-word-fill::before {
  content: "";
}

.bi-file-word::before {
  content: "";
}

.bi-file-x-fill::before {
  content: "";
}

.bi-file-x::before {
  content: "";
}

.bi-file-zip-fill::before {
  content: "";
}

.bi-file-zip::before {
  content: "";
}

.bi-file::before {
  content: "";
}

.bi-files-alt::before {
  content: "";
}

.bi-files::before {
  content: "";
}

.bi-film::before {
  content: "";
}

.bi-filter-circle-fill::before {
  content: "";
}

.bi-filter-circle::before {
  content: "";
}

.bi-filter-left::before {
  content: "";
}

.bi-filter-right::before {
  content: "";
}

.bi-filter-square-fill::before {
  content: "";
}

.bi-filter-square::before {
  content: "";
}

.bi-filter::before {
  content: "";
}

.bi-flag-fill::before {
  content: "";
}

.bi-flag::before {
  content: "";
}

.bi-flower1::before {
  content: "";
}

.bi-flower2::before {
  content: "";
}

.bi-flower3::before {
  content: "";
}

.bi-folder-check::before {
  content: "";
}

.bi-folder-fill::before {
  content: "";
}

.bi-folder-minus::before {
  content: "";
}

.bi-folder-plus::before {
  content: "";
}

.bi-folder-symlink-fill::before {
  content: "";
}

.bi-folder-symlink::before {
  content: "";
}

.bi-folder-x::before {
  content: "";
}

.bi-folder::before {
  content: "";
}

.bi-folder2-open::before {
  content: "";
}

.bi-folder2::before {
  content: "";
}

.bi-fonts::before {
  content: "";
}

.bi-forward-fill::before {
  content: "";
}

.bi-forward::before {
  content: "";
}

.bi-front::before {
  content: "";
}

.bi-fullscreen-exit::before {
  content: "";
}

.bi-fullscreen::before {
  content: "";
}

.bi-funnel-fill::before {
  content: "";
}

.bi-funnel::before {
  content: "";
}

.bi-gear-fill::before {
  content: "";
}

.bi-gear-wide-connected::before {
  content: "";
}

.bi-gear-wide::before {
  content: "";
}

.bi-gear::before {
  content: "";
}

.bi-gem::before {
  content: "";
}

.bi-geo-alt-fill::before {
  content: "";
}

.bi-geo-alt::before {
  content: "";
}

.bi-geo-fill::before {
  content: "";
}

.bi-geo::before {
  content: "";
}

.bi-gift-fill::before {
  content: "";
}

.bi-gift::before {
  content: "";
}

.bi-github::before {
  content: "";
}

.bi-globe::before {
  content: "";
}

.bi-globe2::before {
  content: "";
}

.bi-google::before {
  content: "";
}

.bi-graph-down::before {
  content: "";
}

.bi-graph-up::before {
  content: "";
}

.bi-grid-1x2-fill::before {
  content: "";
}

.bi-grid-1x2::before {
  content: "";
}

.bi-grid-3x2-gap-fill::before {
  content: "";
}

.bi-grid-3x2-gap::before {
  content: "";
}

.bi-grid-3x2::before {
  content: "";
}

.bi-grid-3x3-gap-fill::before {
  content: "";
}

.bi-grid-3x3-gap::before {
  content: "";
}

.bi-grid-3x3::before {
  content: "";
}

.bi-grid-fill::before {
  content: "";
}

.bi-grid::before {
  content: "";
}

.bi-grip-horizontal::before {
  content: "";
}

.bi-grip-vertical::before {
  content: "";
}

.bi-hammer::before {
  content: "";
}

.bi-hand-index-fill::before {
  content: "";
}

.bi-hand-index-thumb-fill::before {
  content: "";
}

.bi-hand-index-thumb::before {
  content: "";
}

.bi-hand-index::before {
  content: "";
}

.bi-hand-thumbs-down-fill::before {
  content: "";
}

.bi-hand-thumbs-down::before {
  content: "";
}

.bi-hand-thumbs-up-fill::before {
  content: "";
}

.bi-hand-thumbs-up::before {
  content: "";
}

.bi-handbag-fill::before {
  content: "";
}

.bi-handbag::before {
  content: "";
}

.bi-hash::before {
  content: "";
}

.bi-hdd-fill::before {
  content: "";
}

.bi-hdd-network-fill::before {
  content: "";
}

.bi-hdd-network::before {
  content: "";
}

.bi-hdd-rack-fill::before {
  content: "";
}

.bi-hdd-rack::before {
  content: "";
}

.bi-hdd-stack-fill::before {
  content: "";
}

.bi-hdd-stack::before {
  content: "";
}

.bi-hdd::before {
  content: "";
}

.bi-headphones::before {
  content: "";
}

.bi-headset::before {
  content: "";
}

.bi-heart-fill::before {
  content: "";
}

.bi-heart-half::before {
  content: "";
}

.bi-heart::before {
  content: "";
}

.bi-heptagon-fill::before {
  content: "";
}

.bi-heptagon-half::before {
  content: "";
}

.bi-heptagon::before {
  content: "";
}

.bi-hexagon-fill::before {
  content: "";
}

.bi-hexagon-half::before {
  content: "";
}

.bi-hexagon::before {
  content: "";
}

.bi-hourglass-bottom::before {
  content: "";
}

.bi-hourglass-split::before {
  content: "";
}

.bi-hourglass-top::before {
  content: "";
}

.bi-hourglass::before {
  content: "";
}

.bi-house-door-fill::before {
  content: "";
}

.bi-house-door::before {
  content: "";
}

.bi-house-fill::before {
  content: "";
}

.bi-house::before {
  content: "";
}

.bi-hr::before {
  content: "";
}

.bi-hurricane::before {
  content: "";
}

.bi-image-alt::before {
  content: "";
}

.bi-image-fill::before {
  content: "";
}

.bi-image::before {
  content: "";
}

.bi-images::before {
  content: "";
}

.bi-inbox-fill::before {
  content: "";
}

.bi-inbox::before {
  content: "";
}

.bi-inboxes-fill::before {
  content: "";
}

.bi-inboxes::before {
  content: "";
}

.bi-info-circle-fill::before {
  content: "";
}

.bi-info-circle::before {
  content: "";
}

.bi-info-square-fill::before {
  content: "";
}

.bi-info-square::before {
  content: "";
}

.bi-info::before {
  content: "";
}

.bi-input-cursor-text::before {
  content: "";
}

.bi-input-cursor::before {
  content: "";
}

.bi-instagram::before {
  content: "";
}

.bi-intersect::before {
  content: "";
}

.bi-journal-album::before {
  content: "";
}

.bi-journal-arrow-down::before {
  content: "";
}

.bi-journal-arrow-up::before {
  content: "";
}

.bi-journal-bookmark-fill::before {
  content: "";
}

.bi-journal-bookmark::before {
  content: "";
}

.bi-journal-check::before {
  content: "";
}

.bi-journal-code::before {
  content: "";
}

.bi-journal-medical::before {
  content: "";
}

.bi-journal-minus::before {
  content: "";
}

.bi-journal-plus::before {
  content: "";
}

.bi-journal-richtext::before {
  content: "";
}

.bi-journal-text::before {
  content: "";
}

.bi-journal-x::before {
  content: "";
}

.bi-journal::before {
  content: "";
}

.bi-journals::before {
  content: "";
}

.bi-joystick::before {
  content: "";
}

.bi-justify-left::before {
  content: "";
}

.bi-justify-right::before {
  content: "";
}

.bi-justify::before {
  content: "";
}

.bi-kanban-fill::before {
  content: "";
}

.bi-kanban::before {
  content: "";
}

.bi-key-fill::before {
  content: "";
}

.bi-key::before {
  content: "";
}

.bi-keyboard-fill::before {
  content: "";
}

.bi-keyboard::before {
  content: "";
}

.bi-ladder::before {
  content: "";
}

.bi-lamp-fill::before {
  content: "";
}

.bi-lamp::before {
  content: "";
}

.bi-laptop-fill::before {
  content: "";
}

.bi-laptop::before {
  content: "";
}

.bi-layer-backward::before {
  content: "";
}

.bi-layer-forward::before {
  content: "";
}

.bi-layers-fill::before {
  content: "";
}

.bi-layers-half::before {
  content: "";
}

.bi-layers::before {
  content: "";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "";
}

.bi-layout-sidebar-inset::before {
  content: "";
}

.bi-layout-sidebar-reverse::before {
  content: "";
}

.bi-layout-sidebar::before {
  content: "";
}

.bi-layout-split::before {
  content: "";
}

.bi-layout-text-sidebar-reverse::before {
  content: "";
}

.bi-layout-text-sidebar::before {
  content: "";
}

.bi-layout-text-window-reverse::before {
  content: "";
}

.bi-layout-text-window::before {
  content: "";
}

.bi-layout-three-columns::before {
  content: "";
}

.bi-layout-wtf::before {
  content: "";
}

.bi-life-preserver::before {
  content: "";
}

.bi-lightbulb-fill::before {
  content: "";
}

.bi-lightbulb-off-fill::before {
  content: "";
}

.bi-lightbulb-off::before {
  content: "";
}

.bi-lightbulb::before {
  content: "";
}

.bi-lightning-charge-fill::before {
  content: "";
}

.bi-lightning-charge::before {
  content: "";
}

.bi-lightning-fill::before {
  content: "";
}

.bi-lightning::before {
  content: "";
}

.bi-link-45deg::before {
  content: "";
}

.bi-link::before {
  content: "";
}

.bi-linkedin::before {
  content: "";
}

.bi-list-check::before {
  content: "";
}

.bi-list-nested::before {
  content: "";
}

.bi-list-ol::before {
  content: "";
}

.bi-list-stars::before {
  content: "";
}

.bi-list-task::before {
  content: "";
}

.bi-list-ul::before {
  content: "";
}

.bi-list::before {
  content: "";
}

.bi-lock-fill::before {
  content: "";
}

.bi-lock::before {
  content: "";
}

.bi-mailbox::before {
  content: "";
}

.bi-mailbox2::before {
  content: "";
}

.bi-map-fill::before {
  content: "";
}

.bi-map::before {
  content: "";
}

.bi-markdown-fill::before {
  content: "";
}

.bi-markdown::before {
  content: "";
}

.bi-mask::before {
  content: "";
}

.bi-megaphone-fill::before {
  content: "";
}

.bi-megaphone::before {
  content: "";
}

.bi-menu-app-fill::before {
  content: "";
}

.bi-menu-app::before {
  content: "";
}

.bi-menu-button-fill::before {
  content: "";
}

.bi-menu-button-wide-fill::before {
  content: "";
}

.bi-menu-button-wide::before {
  content: "";
}

.bi-menu-button::before {
  content: "";
}

.bi-menu-down::before {
  content: "";
}

.bi-menu-up::before {
  content: "";
}

.bi-mic-fill::before {
  content: "";
}

.bi-mic-mute-fill::before {
  content: "";
}

.bi-mic-mute::before {
  content: "";
}

.bi-mic::before {
  content: "";
}

.bi-minecart-loaded::before {
  content: "";
}

.bi-minecart::before {
  content: "";
}

.bi-moisture::before {
  content: "";
}

.bi-moon-fill::before {
  content: "";
}

.bi-moon-stars-fill::before {
  content: "";
}

.bi-moon-stars::before {
  content: "";
}

.bi-moon::before {
  content: "";
}

.bi-mouse-fill::before {
  content: "";
}

.bi-mouse::before {
  content: "";
}

.bi-mouse2-fill::before {
  content: "";
}

.bi-mouse2::before {
  content: "";
}

.bi-mouse3-fill::before {
  content: "";
}

.bi-mouse3::before {
  content: "";
}

.bi-music-note-beamed::before {
  content: "";
}

.bi-music-note-list::before {
  content: "";
}

.bi-music-note::before {
  content: "";
}

.bi-music-player-fill::before {
  content: "";
}

.bi-music-player::before {
  content: "";
}

.bi-newspaper::before {
  content: "";
}

.bi-node-minus-fill::before {
  content: "";
}

.bi-node-minus::before {
  content: "";
}

.bi-node-plus-fill::before {
  content: "";
}

.bi-node-plus::before {
  content: "";
}

.bi-nut-fill::before {
  content: "";
}

.bi-nut::before {
  content: "";
}

.bi-octagon-fill::before {
  content: "";
}

.bi-octagon-half::before {
  content: "";
}

.bi-octagon::before {
  content: "";
}

.bi-option::before {
  content: "";
}

.bi-outlet::before {
  content: "";
}

.bi-paint-bucket::before {
  content: "";
}

.bi-palette-fill::before {
  content: "";
}

.bi-palette::before {
  content: "";
}

.bi-palette2::before {
  content: "";
}

.bi-paperclip::before {
  content: "";
}

.bi-paragraph::before {
  content: "";
}

.bi-patch-check-fill::before {
  content: "";
}

.bi-patch-check::before {
  content: "";
}

.bi-patch-exclamation-fill::before {
  content: "";
}

.bi-patch-exclamation::before {
  content: "";
}

.bi-patch-minus-fill::before {
  content: "";
}

.bi-patch-minus::before {
  content: "";
}

.bi-patch-plus-fill::before {
  content: "";
}

.bi-patch-plus::before {
  content: "";
}

.bi-patch-question-fill::before {
  content: "";
}

.bi-patch-question::before {
  content: "";
}

.bi-pause-btn-fill::before {
  content: "";
}

.bi-pause-btn::before {
  content: "";
}

.bi-pause-circle-fill::before {
  content: "";
}

.bi-pause-circle::before {
  content: "";
}

.bi-pause-fill::before {
  content: "";
}

.bi-pause::before {
  content: "";
}

.bi-peace-fill::before {
  content: "";
}

.bi-peace::before {
  content: "";
}

.bi-pen-fill::before {
  content: "";
}

.bi-pen::before {
  content: "";
}

.bi-pencil-fill::before {
  content: "";
}

.bi-pencil-square::before {
  content: "";
}

.bi-pencil::before {
  content: "";
}

.bi-pentagon-fill::before {
  content: "";
}

.bi-pentagon-half::before {
  content: "";
}

.bi-pentagon::before {
  content: "";
}

.bi-people-fill::before {
  content: "";
}

.bi-people::before {
  content: "";
}

.bi-percent::before {
  content: "";
}

.bi-person-badge-fill::before {
  content: "";
}

.bi-person-badge::before {
  content: "";
}

.bi-person-bounding-box::before {
  content: "";
}

.bi-person-check-fill::before {
  content: "";
}

.bi-person-check::before {
  content: "";
}

.bi-person-circle::before {
  content: "";
}

.bi-person-dash-fill::before {
  content: "";
}

.bi-person-dash::before {
  content: "";
}

.bi-person-fill::before {
  content: "";
}

.bi-person-lines-fill::before {
  content: "";
}

.bi-person-plus-fill::before {
  content: "";
}

.bi-person-plus::before {
  content: "";
}

.bi-person-square::before {
  content: "";
}

.bi-person-x-fill::before {
  content: "";
}

.bi-person-x::before {
  content: "";
}

.bi-person::before {
  content: "";
}

.bi-phone-fill::before {
  content: "";
}

.bi-phone-landscape-fill::before {
  content: "";
}

.bi-phone-landscape::before {
  content: "";
}

.bi-phone-vibrate-fill::before {
  content: "";
}

.bi-phone-vibrate::before {
  content: "";
}

.bi-phone::before {
  content: "";
}

.bi-pie-chart-fill::before {
  content: "";
}

.bi-pie-chart::before {
  content: "";
}

.bi-pin-angle-fill::before {
  content: "";
}

.bi-pin-angle::before {
  content: "";
}

.bi-pin-fill::before {
  content: "";
}

.bi-pin::before {
  content: "";
}

.bi-pip-fill::before {
  content: "";
}

.bi-pip::before {
  content: "";
}

.bi-play-btn-fill::before {
  content: "";
}

.bi-play-btn::before {
  content: "";
}

.bi-play-circle-fill::before {
  content: "";
}

.bi-play-circle::before {
  content: "";
}

.bi-play-fill::before {
  content: "";
}

.bi-play::before {
  content: "";
}

.bi-plug-fill::before {
  content: "";
}

.bi-plug::before {
  content: "";
}

.bi-plus-circle-dotted::before {
  content: "";
}

.bi-plus-circle-fill::before {
  content: "";
}

.bi-plus-circle::before {
  content: "";
}

.bi-plus-square-dotted::before {
  content: "";
}

.bi-plus-square-fill::before {
  content: "";
}

.bi-plus-square::before {
  content: "";
}

.bi-plus::before {
  content: "";
}

.bi-power::before {
  content: "";
}

.bi-printer-fill::before {
  content: "";
}

.bi-printer::before {
  content: "";
}

.bi-puzzle-fill::before {
  content: "";
}

.bi-puzzle::before {
  content: "";
}

.bi-question-circle-fill::before {
  content: "";
}

.bi-question-circle::before {
  content: "";
}

.bi-question-diamond-fill::before {
  content: "";
}

.bi-question-diamond::before {
  content: "";
}

.bi-question-octagon-fill::before {
  content: "";
}

.bi-question-octagon::before {
  content: "";
}

.bi-question-square-fill::before {
  content: "";
}

.bi-question-square::before {
  content: "";
}

.bi-question::before {
  content: "";
}

.bi-rainbow::before {
  content: "";
}

.bi-receipt-cutoff::before {
  content: "";
}

.bi-receipt::before {
  content: "";
}

.bi-reception-0::before {
  content: "";
}

.bi-reception-1::before {
  content: "";
}

.bi-reception-2::before {
  content: "";
}

.bi-reception-3::before {
  content: "";
}

.bi-reception-4::before {
  content: "";
}

.bi-record-btn-fill::before {
  content: "";
}

.bi-record-btn::before {
  content: "";
}

.bi-record-circle-fill::before {
  content: "";
}

.bi-record-circle::before {
  content: "";
}

.bi-record-fill::before {
  content: "";
}

.bi-record::before {
  content: "";
}

.bi-record2-fill::before {
  content: "";
}

.bi-record2::before {
  content: "";
}

.bi-reply-all-fill::before {
  content: "";
}

.bi-reply-all::before {
  content: "";
}

.bi-reply-fill::before {
  content: "";
}

.bi-reply::before {
  content: "";
}

.bi-rss-fill::before {
  content: "";
}

.bi-rss::before {
  content: "";
}

.bi-rulers::before {
  content: "";
}

.bi-save-fill::before {
  content: "";
}

.bi-save::before {
  content: "";
}

.bi-save2-fill::before {
  content: "";
}

.bi-save2::before {
  content: "";
}

.bi-scissors::before {
  content: "";
}

.bi-screwdriver::before {
  content: "";
}

.bi-search::before {
  content: "";
}

.bi-segmented-nav::before {
  content: "";
}

.bi-server::before {
  content: "";
}

.bi-share-fill::before {
  content: "";
}

.bi-share::before {
  content: "";
}

.bi-shield-check::before {
  content: "";
}

.bi-shield-exclamation::before {
  content: "";
}

.bi-shield-fill-check::before {
  content: "";
}

.bi-shield-fill-exclamation::before {
  content: "";
}

.bi-shield-fill-minus::before {
  content: "";
}

.bi-shield-fill-plus::before {
  content: "";
}

.bi-shield-fill-x::before {
  content: "";
}

.bi-shield-fill::before {
  content: "";
}

.bi-shield-lock-fill::before {
  content: "";
}

.bi-shield-lock::before {
  content: "";
}

.bi-shield-minus::before {
  content: "";
}

.bi-shield-plus::before {
  content: "";
}

.bi-shield-shaded::before {
  content: "";
}

.bi-shield-slash-fill::before {
  content: "";
}

.bi-shield-slash::before {
  content: "";
}

.bi-shield-x::before {
  content: "";
}

.bi-shield::before {
  content: "";
}

.bi-shift-fill::before {
  content: "";
}

.bi-shift::before {
  content: "";
}

.bi-shop-window::before {
  content: "";
}

.bi-shop::before {
  content: "";
}

.bi-shuffle::before {
  content: "";
}

.bi-signpost-2-fill::before {
  content: "";
}

.bi-signpost-2::before {
  content: "";
}

.bi-signpost-fill::before {
  content: "";
}

.bi-signpost-split-fill::before {
  content: "";
}

.bi-signpost-split::before {
  content: "";
}

.bi-signpost::before {
  content: "";
}

.bi-sim-fill::before {
  content: "";
}

.bi-sim::before {
  content: "";
}

.bi-skip-backward-btn-fill::before {
  content: "";
}

.bi-skip-backward-btn::before {
  content: "";
}

.bi-skip-backward-circle-fill::before {
  content: "";
}

.bi-skip-backward-circle::before {
  content: "";
}

.bi-skip-backward-fill::before {
  content: "";
}

.bi-skip-backward::before {
  content: "";
}

.bi-skip-end-btn-fill::before {
  content: "";
}

.bi-skip-end-btn::before {
  content: "";
}

.bi-skip-end-circle-fill::before {
  content: "";
}

.bi-skip-end-circle::before {
  content: "";
}

.bi-skip-end-fill::before {
  content: "";
}

.bi-skip-end::before {
  content: "";
}

.bi-skip-forward-btn-fill::before {
  content: "";
}

.bi-skip-forward-btn::before {
  content: "";
}

.bi-skip-forward-circle-fill::before {
  content: "";
}

.bi-skip-forward-circle::before {
  content: "";
}

.bi-skip-forward-fill::before {
  content: "";
}

.bi-skip-forward::before {
  content: "";
}

.bi-skip-start-btn-fill::before {
  content: "";
}

.bi-skip-start-btn::before {
  content: "";
}

.bi-skip-start-circle-fill::before {
  content: "";
}

.bi-skip-start-circle::before {
  content: "";
}

.bi-skip-start-fill::before {
  content: "";
}

.bi-skip-start::before {
  content: "";
}

.bi-slack::before {
  content: "";
}

.bi-slash-circle-fill::before {
  content: "";
}

.bi-slash-circle::before {
  content: "";
}

.bi-slash-square-fill::before {
  content: "";
}

.bi-slash-square::before {
  content: "";
}

.bi-slash::before {
  content: "";
}

.bi-sliders::before {
  content: "";
}

.bi-smartwatch::before {
  content: "";
}

.bi-snow::before {
  content: "";
}

.bi-snow2::before {
  content: "";
}

.bi-snow3::before {
  content: "";
}

.bi-sort-alpha-down-alt::before {
  content: "";
}

.bi-sort-alpha-down::before {
  content: "";
}

.bi-sort-alpha-up-alt::before {
  content: "";
}

.bi-sort-alpha-up::before {
  content: "";
}

.bi-sort-down-alt::before {
  content: "";
}

.bi-sort-down::before {
  content: "";
}

.bi-sort-numeric-down-alt::before {
  content: "";
}

.bi-sort-numeric-down::before {
  content: "";
}

.bi-sort-numeric-up-alt::before {
  content: "";
}

.bi-sort-numeric-up::before {
  content: "";
}

.bi-sort-up-alt::before {
  content: "";
}

.bi-sort-up::before {
  content: "";
}

.bi-soundwave::before {
  content: "";
}

.bi-speaker-fill::before {
  content: "";
}

.bi-speaker::before {
  content: "";
}

.bi-speedometer::before {
  content: "";
}

.bi-speedometer2::before {
  content: "";
}

.bi-spellcheck::before {
  content: "";
}

.bi-square-fill::before {
  content: "";
}

.bi-square-half::before {
  content: "";
}

.bi-square::before {
  content: "";
}

.bi-stack::before {
  content: "";
}

.bi-star-fill::before {
  content: "";
}

.bi-star-half::before {
  content: "";
}

.bi-star::before {
  content: "";
}

.bi-stars::before {
  content: "";
}

.bi-stickies-fill::before {
  content: "";
}

.bi-stickies::before {
  content: "";
}

.bi-sticky-fill::before {
  content: "";
}

.bi-sticky::before {
  content: "";
}

.bi-stop-btn-fill::before {
  content: "";
}

.bi-stop-btn::before {
  content: "";
}

.bi-stop-circle-fill::before {
  content: "";
}

.bi-stop-circle::before {
  content: "";
}

.bi-stop-fill::before {
  content: "";
}

.bi-stop::before {
  content: "";
}

.bi-stoplights-fill::before {
  content: "";
}

.bi-stoplights::before {
  content: "";
}

.bi-stopwatch-fill::before {
  content: "";
}

.bi-stopwatch::before {
  content: "";
}

.bi-subtract::before {
  content: "";
}

.bi-suit-club-fill::before {
  content: "";
}

.bi-suit-club::before {
  content: "";
}

.bi-suit-diamond-fill::before {
  content: "";
}

.bi-suit-diamond::before {
  content: "";
}

.bi-suit-heart-fill::before {
  content: "";
}

.bi-suit-heart::before {
  content: "";
}

.bi-suit-spade-fill::before {
  content: "";
}

.bi-suit-spade::before {
  content: "";
}

.bi-sun-fill::before {
  content: "";
}

.bi-sun::before {
  content: "";
}

.bi-sunglasses::before {
  content: "";
}

.bi-sunrise-fill::before {
  content: "";
}

.bi-sunrise::before {
  content: "";
}

.bi-sunset-fill::before {
  content: "";
}

.bi-sunset::before {
  content: "";
}

.bi-symmetry-horizontal::before {
  content: "";
}

.bi-symmetry-vertical::before {
  content: "";
}

.bi-table::before {
  content: "";
}

.bi-tablet-fill::before {
  content: "";
}

.bi-tablet-landscape-fill::before {
  content: "";
}

.bi-tablet-landscape::before {
  content: "";
}

.bi-tablet::before {
  content: "";
}

.bi-tag-fill::before {
  content: "";
}

.bi-tag::before {
  content: "";
}

.bi-tags-fill::before {
  content: "";
}

.bi-tags::before {
  content: "";
}

.bi-telegram::before {
  content: "";
}

.bi-telephone-fill::before {
  content: "";
}

.bi-telephone-forward-fill::before {
  content: "";
}

.bi-telephone-forward::before {
  content: "";
}

.bi-telephone-inbound-fill::before {
  content: "";
}

.bi-telephone-inbound::before {
  content: "";
}

.bi-telephone-minus-fill::before {
  content: "";
}

.bi-telephone-minus::before {
  content: "";
}

.bi-telephone-outbound-fill::before {
  content: "";
}

.bi-telephone-outbound::before {
  content: "";
}

.bi-telephone-plus-fill::before {
  content: "";
}

.bi-telephone-plus::before {
  content: "";
}

.bi-telephone-x-fill::before {
  content: "";
}

.bi-telephone-x::before {
  content: "";
}

.bi-telephone::before {
  content: "";
}

.bi-terminal-fill::before {
  content: "";
}

.bi-terminal::before {
  content: "";
}

.bi-text-center::before {
  content: "";
}

.bi-text-indent-left::before {
  content: "";
}

.bi-text-indent-right::before {
  content: "";
}

.bi-text-left::before {
  content: "";
}

.bi-text-paragraph::before {
  content: "";
}

.bi-text-right::before {
  content: "";
}

.bi-textarea-resize::before {
  content: "";
}

.bi-textarea-t::before {
  content: "";
}

.bi-textarea::before {
  content: "";
}

.bi-thermometer-half::before {
  content: "";
}

.bi-thermometer-high::before {
  content: "";
}

.bi-thermometer-low::before {
  content: "";
}

.bi-thermometer-snow::before {
  content: "";
}

.bi-thermometer-sun::before {
  content: "";
}

.bi-thermometer::before {
  content: "";
}

.bi-three-dots-vertical::before {
  content: "";
}

.bi-three-dots::before {
  content: "";
}

.bi-toggle-off::before {
  content: "";
}

.bi-toggle-on::before {
  content: "";
}

.bi-toggle2-off::before {
  content: "";
}

.bi-toggle2-on::before {
  content: "";
}

.bi-toggles::before {
  content: "";
}

.bi-toggles2::before {
  content: "";
}

.bi-tools::before {
  content: "";
}

.bi-tornado::before {
  content: "";
}

.bi-trash-fill::before {
  content: "";
}

.bi-trash::before {
  content: "";
}

.bi-trash2-fill::before {
  content: "";
}

.bi-trash2::before {
  content: "";
}

.bi-tree-fill::before {
  content: "";
}

.bi-tree::before {
  content: "";
}

.bi-triangle-fill::before {
  content: "";
}

.bi-triangle-half::before {
  content: "";
}

.bi-triangle::before {
  content: "";
}

.bi-trophy-fill::before {
  content: "";
}

.bi-trophy::before {
  content: "";
}

.bi-tropical-storm::before {
  content: "";
}

.bi-truck-flatbed::before {
  content: "";
}

.bi-truck::before {
  content: "";
}

.bi-tsunami::before {
  content: "";
}

.bi-tv-fill::before {
  content: "";
}

.bi-tv::before {
  content: "";
}

.bi-twitch::before {
  content: "";
}

.bi-twitter::before {
  content: "";
}

.bi-type-bold::before {
  content: "";
}

.bi-type-h1::before {
  content: "";
}

.bi-type-h2::before {
  content: "";
}

.bi-type-h3::before {
  content: "";
}

.bi-type-italic::before {
  content: "";
}

.bi-type-strikethrough::before {
  content: "";
}

.bi-type-underline::before {
  content: "";
}

.bi-type::before {
  content: "";
}

.bi-ui-checks-grid::before {
  content: "";
}

.bi-ui-checks::before {
  content: "";
}

.bi-ui-radios-grid::before {
  content: "";
}

.bi-ui-radios::before {
  content: "";
}

.bi-umbrella-fill::before {
  content: "";
}

.bi-umbrella::before {
  content: "";
}

.bi-union::before {
  content: "";
}

.bi-unlock-fill::before {
  content: "";
}

.bi-unlock::before {
  content: "";
}

.bi-upc-scan::before {
  content: "";
}

.bi-upc::before {
  content: "";
}

.bi-upload::before {
  content: "";
}

.bi-vector-pen::before {
  content: "";
}

.bi-view-list::before {
  content: "";
}

.bi-view-stacked::before {
  content: "";
}

.bi-vinyl-fill::before {
  content: "";
}

.bi-vinyl::before {
  content: "";
}

.bi-voicemail::before {
  content: "";
}

.bi-volume-down-fill::before {
  content: "";
}

.bi-volume-down::before {
  content: "";
}

.bi-volume-mute-fill::before {
  content: "";
}

.bi-volume-mute::before {
  content: "";
}

.bi-volume-off-fill::before {
  content: "";
}

.bi-volume-off::before {
  content: "";
}

.bi-volume-up-fill::before {
  content: "";
}

.bi-volume-up::before {
  content: "";
}

.bi-vr::before {
  content: "";
}

.bi-wallet-fill::before {
  content: "";
}

.bi-wallet::before {
  content: "";
}

.bi-wallet2::before {
  content: "";
}

.bi-watch::before {
  content: "";
}

.bi-water::before {
  content: "";
}

.bi-whatsapp::before {
  content: "";
}

.bi-wifi-1::before {
  content: "";
}

.bi-wifi-2::before {
  content: "";
}

.bi-wifi-off::before {
  content: "";
}

.bi-wifi::before {
  content: "";
}

.bi-wind::before {
  content: "";
}

.bi-window-dock::before {
  content: "";
}

.bi-window-sidebar::before {
  content: "";
}

.bi-window::before {
  content: "";
}

.bi-wrench::before {
  content: "";
}

.bi-x-circle-fill::before {
  content: "";
}

.bi-x-circle::before {
  content: "";
}

.bi-x-diamond-fill::before {
  content: "";
}

.bi-x-diamond::before {
  content: "";
}

.bi-x-octagon-fill::before {
  content: "";
}

.bi-x-octagon::before {
  content: "";
}

.bi-x-square-fill::before {
  content: "";
}

.bi-x-square::before {
  content: "";
}

.bi-x::before {
  content: "";
}

.bi-youtube::before {
  content: "";
}

.bi-zoom-in::before {
  content: "";
}

.bi-zoom-out::before {
  content: "";
}

.bi-bank::before {
  content: "";
}

.bi-bank2::before {
  content: "";
}

.bi-bell-slash-fill::before {
  content: "";
}

.bi-bell-slash::before {
  content: "";
}

.bi-cash-coin::before {
  content: "";
}

.bi-check-lg::before {
  content: "";
}

.bi-coin::before {
  content: "";
}

.bi-currency-bitcoin::before {
  content: "";
}

.bi-currency-dollar::before {
  content: "";
}

.bi-currency-euro::before {
  content: "";
}

.bi-currency-exchange::before {
  content: "";
}

.bi-currency-pound::before {
  content: "";
}

.bi-currency-yen::before {
  content: "";
}

.bi-dash-lg::before {
  content: "";
}

.bi-exclamation-lg::before {
  content: "";
}

.bi-file-earmark-pdf-fill::before {
  content: "";
}

.bi-file-earmark-pdf::before {
  content: "";
}

.bi-file-pdf-fill::before {
  content: "";
}

.bi-file-pdf::before {
  content: "";
}

.bi-gender-ambiguous::before {
  content: "";
}

.bi-gender-female::before {
  content: "";
}

.bi-gender-male::before {
  content: "";
}

.bi-gender-trans::before {
  content: "";
}

.bi-headset-vr::before {
  content: "";
}

.bi-info-lg::before {
  content: "";
}

.bi-mastodon::before {
  content: "";
}

.bi-messenger::before {
  content: "";
}

.bi-piggy-bank-fill::before {
  content: "";
}

.bi-piggy-bank::before {
  content: "";
}

.bi-pin-map-fill::before {
  content: "";
}

.bi-pin-map::before {
  content: "";
}

.bi-plus-lg::before {
  content: "";
}

.bi-question-lg::before {
  content: "";
}

.bi-recycle::before {
  content: "";
}

.bi-reddit::before {
  content: "";
}

.bi-safe-fill::before {
  content: "";
}

.bi-safe2-fill::before {
  content: "";
}

.bi-safe2::before {
  content: "";
}

.bi-sd-card-fill::before {
  content: "";
}

.bi-sd-card::before {
  content: "";
}

.bi-skype::before {
  content: "";
}

.bi-slash-lg::before {
  content: "";
}

.bi-translate::before {
  content: "";
}

.bi-x-lg::before {
  content: "";
}

.bi-safe::before {
  content: "";
}

.bi-apple::before {
  content: "";
}

.bi-microsoft::before {
  content: "";
}

.bi-windows::before {
  content: "";
}

.bi-behance::before {
  content: "";
}

.bi-dribbble::before {
  content: "";
}

.bi-line::before {
  content: "";
}

.bi-medium::before {
  content: "";
}

.bi-paypal::before {
  content: "";
}

.bi-pinterest::before {
  content: "";
}

.bi-signal::before {
  content: "";
}

.bi-snapchat::before {
  content: "";
}

.bi-spotify::before {
  content: "";
}

.bi-stack-overflow::before {
  content: "";
}

.bi-strava::before {
  content: "";
}

.bi-wordpress::before {
  content: "";
}

.bi-vimeo::before {
  content: "";
}

.bi-activity::before {
  content: "";
}

.bi-easel2-fill::before {
  content: "";
}

.bi-easel2::before {
  content: "";
}

.bi-easel3-fill::before {
  content: "";
}

.bi-easel3::before {
  content: "";
}

.bi-fan::before {
  content: "";
}

.bi-fingerprint::before {
  content: "";
}

.bi-graph-down-arrow::before {
  content: "";
}

.bi-graph-up-arrow::before {
  content: "";
}

.bi-hypnotize::before {
  content: "";
}

.bi-magic::before {
  content: "";
}

.bi-person-rolodex::before {
  content: "";
}

.bi-person-video::before {
  content: "";
}

.bi-person-video2::before {
  content: "";
}

.bi-person-video3::before {
  content: "";
}

.bi-person-workspace::before {
  content: "";
}

.bi-radioactive::before {
  content: "";
}

.bi-webcam-fill::before {
  content: "";
}

.bi-webcam::before {
  content: "";
}

.bi-yin-yang::before {
  content: "";
}

.bi-bandaid-fill::before {
  content: "";
}

.bi-bandaid::before {
  content: "";
}

.bi-bluetooth::before {
  content: "";
}

.bi-body-text::before {
  content: "";
}

.bi-boombox::before {
  content: "";
}

.bi-boxes::before {
  content: "";
}

.bi-dpad-fill::before {
  content: "";
}

.bi-dpad::before {
  content: "";
}

.bi-ear-fill::before {
  content: "";
}

.bi-ear::before {
  content: "";
}

.bi-envelope-check-1::before {
  content: "";
}

.bi-envelope-check-fill::before {
  content: "";
}

.bi-envelope-check::before {
  content: "";
}

.bi-envelope-dash-1::before {
  content: "";
}

.bi-envelope-dash-fill::before {
  content: "";
}

.bi-envelope-dash::before {
  content: "";
}

.bi-envelope-exclamation-1::before {
  content: "";
}

.bi-envelope-exclamation-fill::before {
  content: "";
}

.bi-envelope-exclamation::before {
  content: "";
}

.bi-envelope-plus-fill::before {
  content: "";
}

.bi-envelope-plus::before {
  content: "";
}

.bi-envelope-slash-1::before {
  content: "";
}

.bi-envelope-slash-fill::before {
  content: "";
}

.bi-envelope-slash::before {
  content: "";
}

.bi-envelope-x-1::before {
  content: "";
}

.bi-envelope-x-fill::before {
  content: "";
}

.bi-envelope-x::before {
  content: "";
}

.bi-explicit-fill::before {
  content: "";
}

.bi-explicit::before {
  content: "";
}

.bi-git::before {
  content: "";
}

.bi-infinity::before {
  content: "";
}

.bi-list-columns-reverse::before {
  content: "";
}

.bi-list-columns::before {
  content: "";
}

.bi-meta::before {
  content: "";
}

.bi-mortorboard-fill::before {
  content: "";
}

.bi-mortorboard::before {
  content: "";
}

.bi-nintendo-switch::before {
  content: "";
}

.bi-pc-display-horizontal::before {
  content: "";
}

.bi-pc-display::before {
  content: "";
}

.bi-pc-horizontal::before {
  content: "";
}

.bi-pc::before {
  content: "";
}

.bi-playstation::before {
  content: "";
}

.bi-plus-slash-minus::before {
  content: "";
}

.bi-projector-fill::before {
  content: "";
}

.bi-projector::before {
  content: "";
}

.bi-qr-code-scan::before {
  content: "";
}

.bi-qr-code::before {
  content: "";
}

.bi-quora::before {
  content: "";
}

.bi-quote::before {
  content: "";
}

.bi-robot::before {
  content: "";
}

.bi-send-check-fill::before {
  content: "";
}

.bi-send-check::before {
  content: "";
}

.bi-send-dash-fill::before {
  content: "";
}

.bi-send-dash::before {
  content: "";
}

.bi-send-exclamation-1::before {
  content: "";
}

.bi-send-exclamation-fill::before {
  content: "";
}

.bi-send-exclamation::before {
  content: "";
}

.bi-send-fill::before {
  content: "";
}

.bi-send-plus-fill::before {
  content: "";
}

.bi-send-plus::before {
  content: "";
}

.bi-send-slash-fill::before {
  content: "";
}

.bi-send-slash::before {
  content: "";
}

.bi-send-x-fill::before {
  content: "";
}

.bi-send-x::before {
  content: "";
}

.bi-send::before {
  content: "";
}

.bi-steam::before {
  content: "";
}

.bi-terminal-dash-1::before {
  content: "";
}

.bi-terminal-dash::before {
  content: "";
}

.bi-terminal-plus::before {
  content: "";
}

.bi-terminal-split::before {
  content: "";
}

.bi-ticket-detailed-fill::before {
  content: "";
}

.bi-ticket-detailed::before {
  content: "";
}

.bi-ticket-fill::before {
  content: "";
}

.bi-ticket-perforated-fill::before {
  content: "";
}

.bi-ticket-perforated::before {
  content: "";
}

.bi-ticket::before {
  content: "";
}

.bi-tiktok::before {
  content: "";
}

.bi-window-dash::before {
  content: "";
}

.bi-window-desktop::before {
  content: "";
}

.bi-window-fullscreen::before {
  content: "";
}

.bi-window-plus::before {
  content: "";
}

.bi-window-split::before {
  content: "";
}

.bi-window-stack::before {
  content: "";
}

.bi-window-x::before {
  content: "";
}

.bi-xbox::before {
  content: "";
}

.bi-ethernet::before {
  content: "";
}

.bi-hdmi-fill::before {
  content: "";
}

.bi-hdmi::before {
  content: "";
}

.bi-usb-c-fill::before {
  content: "";
}

.bi-usb-c::before {
  content: "";
}

.bi-usb-fill::before {
  content: "";
}

.bi-usb-plug-fill::before {
  content: "";
}

.bi-usb-plug::before {
  content: "";
}

.bi-usb-symbol::before {
  content: "";
}

.bi-usb::before {
  content: "";
}

.bi-boombox-fill::before {
  content: "";
}

.bi-displayport-1::before {
  content: "";
}

.bi-displayport::before {
  content: "";
}

.bi-gpu-card::before {
  content: "";
}

.bi-memory::before {
  content: "";
}

.bi-modem-fill::before {
  content: "";
}

.bi-modem::before {
  content: "";
}

.bi-motherboard-fill::before {
  content: "";
}

.bi-motherboard::before {
  content: "";
}

.bi-optical-audio-fill::before {
  content: "";
}

.bi-optical-audio::before {
  content: "";
}

.bi-pci-card::before {
  content: "";
}

.bi-router-fill::before {
  content: "";
}

.bi-router::before {
  content: "";
}

.bi-ssd-fill::before {
  content: "";
}

.bi-ssd::before {
  content: "";
}

.bi-thunderbolt-fill::before {
  content: "";
}

.bi-thunderbolt::before {
  content: "";
}

.bi-usb-drive-fill::before {
  content: "";
}

.bi-usb-drive::before {
  content: "";
}

.bi-usb-micro-fill::before {
  content: "";
}

.bi-usb-micro::before {
  content: "";
}

.bi-usb-mini-fill::before {
  content: "";
}

.bi-usb-mini::before {
  content: "";
}

.bi-cloud-haze2::before {
  content: "";
}

.bi-device-hdd-fill::before {
  content: "";
}

.bi-device-hdd::before {
  content: "";
}

.bi-device-ssd-fill::before {
  content: "";
}

.bi-device-ssd::before {
  content: "";
}

.bi-displayport-fill::before {
  content: "";
}

.bi-mortarboard-fill::before {
  content: "";
}

.bi-mortarboard::before {
  content: "";
}

.bi-terminal-x::before {
  content: "";
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl,
.container-xxxl {
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 768px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl,
  .container-xxxl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl,
  .container-xxxl {
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media (min-width: 1800px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl,
  .container-xxxl {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media (min-width: 2400px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl,
  .container-xxxl {
    padding-left: 18%;
    padding-right: 18%;
  }
}

@media (min-width: 3000px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl,
  .container-xxxl {
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media (min-width: 992px) {
  .order-lg-6 {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8 !important;
    -ms-flex-order: 7 !important;
    order: 7 !important;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9 !important;
    -ms-flex-order: 8 !important;
    order: 8 !important;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10 !important;
    -ms-flex-order: 9 !important;
    order: 9 !important;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11 !important;
    -ms-flex-order: 10 !important;
    order: 10 !important;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12 !important;
    -ms-flex-order: 11 !important;
    order: 11 !important;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13 !important;
    -ms-flex-order: 12 !important;
    order: 12 !important;
  }

  .order-lg-13 {
    -webkit-box-ordinal-group: 14 !important;
    -ms-flex-order: 13 !important;
    order: 13 !important;
  }

  .order-lg-14 {
    -webkit-box-ordinal-group: 15 !important;
    -ms-flex-order: 14 !important;
    order: 14 !important;
  }

  .order-lg-15 {
    -webkit-box-ordinal-group: 16 !important;
    -ms-flex-order: 15 !important;
    order: 15 !important;
  }

  .order-lg-16 {
    -webkit-box-ordinal-group: 17 !important;
    -ms-flex-order: 16 !important;
    order: 16 !important;
  }
}

/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/

body {
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: #222;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-display: swap;
}

@media (min-width: 768px) {
  body {
    font-size: 1.5rem;
    line-height: 1.85rem;
  }
}

a {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  font-display: swap;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;
}

a:hover {
  text-decoration: none;
  color: #222;
}

.make-links-fancy a {
  color: #888;
  font-weight: 400;
  font-display: swap;
}

.make-links-fancy a:hover {
  color: #000;
}

.bold,
strong {
  font-weight: 700;
}

.sub-heading {
  font-size: 1.25rem;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  font-display: swap;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  font-display: swap;
}

.h1,
h1 {
  font-size: 2.369rem;
}

@media (min-width: 992px) {
  .h1,
  h1 {
    font-size: 3.157rem;
  }
}

.h2,
h2 {
  font-size: 1.777rem;
}

@media (min-width: 992px) {
  .h2,
  h2 {
    font-size: 2.369rem;
  }
}

.h3,
h3 {
  font-size: 1.333rem;
}

@media (min-width: 992px) {
  .h3,
  h3 {
    font-size: 1.777rem;
  }
}

.h4,
h4 {
  font-size: 1rem;
}

@media (min-width: 992px) {
  .h4,
  h4 {
    font-size: 1.333rem;
  }
}

.h5,
h5 {
  font-size: 1rem;
}

@media (min-width: 992px) {
  .h5,
  h5 {
    font-size: 1.25rem;
  }
}

@media (max-width: 767.98px) {
  .display-1 {
    font-size: calc(1.625rem + 3.5vw);
  }
}

@media (max-width: 767.98px) {
  .display-2 {
    font-size: calc(1.575rem + 3vw);
  }
}

@media (max-width: 767.98px) {
  .display-3 {
    font-size: calc(1.525rem + 2.3vw);
  }
}

.reduce-line-height {
  line-height: 1 !important;
}

a.animated-link,
.nav-item a,
.make-links-fancy a,
.widget_nav_menu ul li a {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  transition: color 0.3s cubic-bezier(0.5, 0.5, 0.3, 1);
  top: 7px;
  font-display: swap;
}

a.animated-link::before,
.nav-item a::before,
.make-links-fancy a::before,
.widget_nav_menu ul li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: currentColor;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s cubic-bezier(0.5, 0.5, 0.3, 1);
  font-display: swap;
}

a.animated-link:hover::before,
.make-links-fancy a:hover::before,
.nav-item a:hover::before,
.widget_nav_menu ul li a:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
  font-display: swap;
}

a.btn::before,
.range-headless .headless-product-card .headless-add-to-cart a.headless-cart-button::before,
.search-form a.search-submit::before {
  display: none;
  font-display: swap;
}

.page-container a:not(.btn),
.page-title-container a:not(.btn) {
  font-weight: 400;
  color: #888;
}

.page-container a:not(.btn):hover,
.page-title-container a:not(.btn):hover {
  color: #9db09b;
}

.background-image {
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.background-white {
  background-color: #fff;
}

.background-color-trans-white {
  background-color: rgba(255, 255, 255, 0.75);
}

.background-black {
  background-color: #000;
}

.background-black.background-gradient {
  background: #000;
  background: linear-gradient(145deg, #000 0%, #262626 100%);
}

.background-trans-black {
  background-color: rgba(0, 0, 0, 0.75);
}

.background-primary {
  background-color: #000;
}

.background-primary.background-gradient {
  background: #000;
  background: linear-gradient(145deg, #000 0%, #262626 100%);
}

.background-secondary {
  background-color: #222;
}

.background-secondary.background-gradient {
  background: #222;
  background: linear-gradient(145deg, #222 0%, #484848 100%);
}

.background-dark {
  background-color: #1A1616;
}

.background-dark.background-gradient {
  background: #1A1616;
  background: linear-gradient(145deg, #1A1616 0%, #433939 100%);
}

.background-gray {
  background-color: #888;
}

.background-gray.background-gradient {
  background: #888;
  background: linear-gradient(145deg, #888 0%, #aeaeae 100%);
}

.background-accent {
  background-color: #9db09b;
}

.background-accent.background-gradient {
  background: #9db09b;
  background: linear-gradient(145deg, #9db09b 0%, #c7d2c6 100%);
}

.background-accent-2 {
  background-color: #a0ab9d;
}

.background-accent-2.background-gradient {
  background: #a0ab9d;
  background: linear-gradient(145deg, #a0ab9d 0%, #c8cec6 100%);
}

.background-cream {
  background-color: #FFF5D1;
}

.background-cream.background-gradient {
  background: #FFF5D1;
  background: linear-gradient(145deg, #FFF5D1 0%, white 100%);
}

.background-light {
  background-color: #f5f5f5;
}

.background-light.background-gradient {
  background: #f5f5f5;
  background: linear-gradient(145deg, #f5f5f5 0%, white 100%);
}

.color-primary {
  color: #000;
}

.color-secondary {
  color: #222;
}

.color-body {
  color: #222;
}

.color-light {
  color: #f5f5f5;
}

.color-cream {
  color: #FFF5D1;
}

.color-accent-2 {
  color: #a0ab9d;
}

.color-dark {
  color: #1A1616;
}

.color-gray {
  color: #888;
}

.color-accent {
  color: #9db09b;
}

.color-white,
.white {
  color: white !important;
}

.color-black {
  color: black !important;
}

body {
  background: #fff;
  overflow-x: hidden;
}

.main *::selection {
  color: #fff;
  background: #000;
}

.main *::selection {
  color: #fff;
  background: #000;
}

a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

[data-aos*=flip] {
  -webkit-backface-visibility: hidden !important;
}

ul {
  list-style-type: square;
}

#pojo-a11y-toolbar {
  height: 100%;
  top: 0 !important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0 !important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}

/** custom classes */

.cursor-hover:hover {
  cursor: pointer;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 50vw;
}

@media (min-width: 768px) {
  .min-height {
    min-height: 33vw;
  }
}

@media (min-width: 992px) {
  .min-height {
    min-height: 350px;
  }
}

hr {
  background-color: rgba(0, 0, 0, 0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden !important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.larger {
  font-size: 125%;
  line-height: 105%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.text-shadow {
  text-shadow: 0 0 60px rgba(0, 0, 0, 0.5);
}

.text-shadow-light {
  text-shadow: 0 0 60px rgba(0, 0, 0, 0.15);
}

.text-shadow-heavy {
  text-shadow: 0 0 60px rgba(0, 0, 0, 0.85);
}

.max-w-75 {
  max-width: 75%;
}

.cursor-pointer {
  cursor: pointer;
}

.rotated-section-title {
  left: 1rem;
  top: auto;
  bottom: 40%;
  text-align: left !important;
}

.rotated-section-title span {
  transform-origin: 0 0;
  transform: translateY(100%) rotate(-90deg);
  width: 600px;
}

@media (min-width: 992px) {
  .small-on-lg-screens {
    font-size: 0.875em;
  }
}

.min-height-smaller {
  min-height: 33vh;
}

@media (min-width: 768px) {
  .min-height-smaller {
    min-height: 300px;
  }
}

@media (max-width: 767.98px) {
  .min-height-md-down {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100% !important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px #000;
}

.large-icon * {
  font-size: 2.5em;
}

@media (min-width: 768px) {
  .large-icon * {
    font-size: 3em;
  }
}

@media (min-width: 768px) {
  .large-icon * {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;
}

.grow-bkg.grow-this-bkg,
.grow-bkg .grow-this-bkg {
  background-size: 100% !important;
  transition: all 0.2s ease-in;
}

.grow-bkg.grow-this-bkg:hover,
.grow-bkg .grow-this-bkg:hover {
  transition: all 0.2s ease-in;
  background-size: 110% !important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px #000;
}

.circle.small-bio {
  padding: 30px;
  border: solid 5px #fff;
}

.notunder1500 {
  display: none;
}

@media (min-width: 1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

.negative-top-header {
  margin-top: -47px;
}

@media (min-width: 768px) {
  .negative-top-header {
    margin-top: -115px;
  }
}

@media (min-width: 992px) {
  .negative-top-header {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
}

@media (min-width: 992px) {
  .clip-oblique {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.img-wrap img {
  max-height: 75px;
  margin: 10px;
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}

.absolute-link {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #1A1616, black);
  opacity: 0.85;
}

.canvas.light-fade {
  background: linear-gradient(45deg, #000, #1a1a1a, #000);
  opacity: 0.6;
}

@media (min-width: 768px) {
  .sticky {
    position: sticky !important;
    top: 160px !important;
  }
}

.img-cover-col {
  background-size: cover;
  background-position: center;
}

@media (max-width: 575.98px) {
  .img-cover-col {
    display: none !important;
  }
}

@media (max-width: 575.98px) {
  .sticky-top {
    position: static;
  }
}

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}

@media (max-width: 1799px) {
  h1.h4,
  .h4.h1,
  h1.h4 small,
  .h4.h1 small,
  h1.h4 .small,
  .h4.h1 .small,
  h2.h3,
  .h3.h2,
  h3.h5,
  .h5.h3,
  h5.secondary,
  .secondary.h5 {
    display: none;
  }
}

h1.h4,
.h4.h1 {
  font-size: 1.1rem;
  background: #f2f2f2;
  padding: 10px;
  text-align: center;
}

h2.h3,
.h3.h2 {
  font-size: 1.1rem;
  background: #f2f2f2;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

h3.h5,
.h5.h3 {
  font-size: 1.1rem;
  background: #c0c0c0;
  color: #000;
  padding: 10px;
  text-align: center;
}

.main h1.h4 small:after,
.main .h4.h1 small:after,
.main h1.h4 .small:after,
.main .h4.h1 .small:after {
  content: " and surrounding communities";
}

.hidden {
  display: none !important;
}

.bodyimgright,
.container img.bodyimgright,
.plugin-img,
.sidebar img.bodyimgright {
  float: right;
  max-width: 30%;
}

.seo {
  display: none;
}

@media (min-width: 1800px) {
  .seo {
    display: block;
  }
}

.btn,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button,
.search-form .search-submit,
.comment-form input[type=submit] {
  text-transform: uppercase;
  font-weight: bold;
}

.btn a,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button a,
.search-form .search-submit a,
.comment-form input[type=submit] a {
  color: #fff !important;
}

.btn:hover,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:hover,
.search-form .search-submit:hover,
.comment-form input[type=submit]:hover {
  background: #000;
}

button {
  cursor: pointer !important;
}

button:focus {
  outline: 0;
}

.btn.btn-primary,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button,
.search-form .btn-primary.search-submit,
.comment-form input.btn-primary[type=submit],
.btn.primary-button,
.search-form .primary-button.search-submit,
.comment-form input.primary-button[type=submit],
.btn.secondary-button .cta.nav-item a,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a,
.search-form .secondary-button.search-submit .cta.nav-item a,
.comment-form input.secondary-button[type=submit] .cta.nav-item a,
.primary-btn,
.primary-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.gform_legacy_markup_wrapper .gform_footer input.button,
.gform_legacy_markup_wrapper .gform_footer input[type=submit],
.gform_legacy_markup_wrapper .gform_page_footer input.button,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit],
.gform_wrapper.gravity-theme .gform_footer button,
.gform_wrapper.gravity-theme .gform_footer input,
.gform_wrapper.gravity-theme .gform_page_footer button,
.gform_wrapper.gravity-theme .gform_page_footer input {
  display: inline-block;
  padding: 0.5rem 1.5rem 0.4rem;
  border-radius: 0;
  background: #000;
  color: #fff;
  transition-duration: 350ms;
  line-height: 1;
  font-size: auto;
  text-align: center;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  border: 4px solid #000;
}

.btn.btn-primary:hover,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:hover,
.search-form .btn-primary.search-submit:hover,
.comment-form input.btn-primary[type=submit]:hover,
.btn.primary-button:hover,
.comment-form input.primary-button[type=submit]:hover,
.btn.secondary-button .cta.nav-item a:hover,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a:hover,
.search-form .secondary-button.search-submit .cta.nav-item a:hover,
.comment-form input.secondary-button[type=submit] .cta.nav-item a:hover,
.primary-btn:hover,
.primary-button:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.gform_legacy_markup_wrapper .gform_footer input.button:hover,
.gform_legacy_markup_wrapper .gform_footer input[type=submit]:hover,
.gform_legacy_markup_wrapper .gform_page_footer input.button:hover,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit]:hover,
.gform_wrapper.gravity-theme .gform_footer button:hover,
.gform_wrapper.gravity-theme .gform_footer input:hover,
.gform_wrapper.gravity-theme .gform_page_footer button:hover,
.gform_wrapper.gravity-theme .gform_page_footer input:hover {
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  color: #000;
  border: 4px solid #000;
}

.btn.btn-primary:hover .theme-button-icon,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:hover .theme-button-icon,
.search-form .btn-primary.search-submit:hover .theme-button-icon,
.comment-form input.btn-primary[type=submit]:hover .theme-button-icon,
.btn.primary-button:hover .theme-button-icon,
.btn.secondary-button .cta.nav-item a:hover .theme-button-icon,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a:hover .theme-button-icon,
.search-form .secondary-button.search-submit .cta.nav-item a:hover .theme-button-icon,
.comment-form input.secondary-button[type=submit] .cta.nav-item a:hover .theme-button-icon,
.primary-btn:hover .theme-button-icon,
.primary-button:hover .theme-button-icon,
.woocommerce #respond input#submit:hover .theme-button-icon,
.woocommerce a.button:hover .theme-button-icon,
.woocommerce button.button:hover .theme-button-icon,
.woocommerce input.button:hover .theme-button-icon,
.gform_legacy_markup_wrapper .gform_footer input.button:hover .theme-button-icon,
.gform_legacy_markup_wrapper .gform_footer input[type=submit]:hover .theme-button-icon,
.gform_legacy_markup_wrapper .gform_page_footer input.button:hover .theme-button-icon,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit]:hover .theme-button-icon,
.gform_wrapper.gravity-theme .gform_footer button:hover .theme-button-icon,
.gform_wrapper.gravity-theme .gform_footer input:hover .theme-button-icon,
.gform_wrapper.gravity-theme .gform_page_footer button:hover .theme-button-icon,
.gform_wrapper.gravity-theme .gform_page_footer input:hover .theme-button-icon {
  transform: translateX(5px);
}

.btn.btn-primary.primary-btn-transparent,
.range-headless .headless-product-card .headless-add-to-cart .primary-btn-transparent.headless-cart-button,
.search-form .btn-primary.primary-btn-transparent.search-submit,
.comment-form input.btn-primary.primary-btn-transparent[type=submit],
.btn.primary-button.primary-btn-transparent,
.comment-form input.primary-button.primary-btn-transparent[type=submit],
.btn.secondary-button .cta.nav-item a.primary-btn-transparent,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.primary-btn-transparent,
.search-form .secondary-button.search-submit .cta.nav-item a.primary-btn-transparent,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.primary-btn-transparent,
.primary-btn.primary-btn-transparent,
.primary-button.primary-btn-transparent,
.woocommerce #respond input#submit.primary-btn-transparent,
.woocommerce a.button.primary-btn-transparent,
.woocommerce button.button.primary-btn-transparent,
.woocommerce input.button.primary-btn-transparent,
.gform_legacy_markup_wrapper .gform_footer input.button.primary-btn-transparent,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].primary-btn-transparent,
.gform_legacy_markup_wrapper .gform_page_footer input.button.primary-btn-transparent,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].primary-btn-transparent,
.gform_wrapper.gravity-theme .gform_footer button.primary-btn-transparent,
.gform_wrapper.gravity-theme .gform_footer input.primary-btn-transparent,
.gform_wrapper.gravity-theme .gform_page_footer button.primary-btn-transparent,
.gform_wrapper.gravity-theme .gform_page_footer input.primary-btn-transparent {
  background: transparent;
  border: 4px solid #000;
  color: #000;
}

.btn.btn-primary.primary-btn-transparent:hover,
.range-headless .headless-product-card .headless-add-to-cart .primary-btn-transparent.headless-cart-button:hover,
.search-form .btn-primary.primary-btn-transparent.search-submit:hover,
.comment-form input.btn-primary.primary-btn-transparent[type=submit]:hover,
.btn.primary-button.primary-btn-transparent:hover,
.btn.secondary-button .cta.nav-item a.primary-btn-transparent:hover,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.primary-btn-transparent:hover,
.search-form .secondary-button.search-submit .cta.nav-item a.primary-btn-transparent:hover,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.primary-btn-transparent:hover,
.primary-btn.primary-btn-transparent:hover,
.primary-button.primary-btn-transparent:hover,
.woocommerce #respond input#submit.primary-btn-transparent:hover,
.woocommerce a.button.primary-btn-transparent:hover,
.woocommerce button.button.primary-btn-transparent:hover,
.woocommerce input.button.primary-btn-transparent:hover,
.gform_legacy_markup_wrapper .gform_footer input.button.primary-btn-transparent:hover,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].primary-btn-transparent:hover,
.gform_legacy_markup_wrapper .gform_page_footer input.button.primary-btn-transparent:hover,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].primary-btn-transparent:hover,
.gform_wrapper.gravity-theme .gform_footer button.primary-btn-transparent:hover,
.gform_wrapper.gravity-theme .gform_footer input.primary-btn-transparent:hover,
.gform_wrapper.gravity-theme .gform_page_footer button.primary-btn-transparent:hover,
.gform_wrapper.gravity-theme .gform_page_footer input.primary-btn-transparent:hover {
  background: #000;
  border: 4px solid #000;
  color: white;
}

.btn.btn-primary.light,
.range-headless .headless-product-card .headless-add-to-cart .light.headless-cart-button,
.search-form .btn-primary.light.search-submit,
.comment-form input.btn-primary.light[type=submit],
.btn.primary-button.light,
.comment-form input.primary-button.light[type=submit],
.btn.secondary-button .cta.nav-item a.light,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.light,
.search-form .secondary-button.search-submit .cta.nav-item a.light,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.light,
.primary-btn.light,
.primary-button.light,
.woocommerce #respond input#submit.light,
.woocommerce a.button.light,
.woocommerce button.button.light,
.woocommerce input.button.light,
.gform_legacy_markup_wrapper .gform_footer input.button.light,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].light,
.gform_legacy_markup_wrapper .gform_page_footer input.button.light,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].light,
.gform_wrapper.gravity-theme .gform_footer button.light,
.gform_wrapper.gravity-theme .gform_footer input.light,
.gform_wrapper.gravity-theme .gform_page_footer button.light,
.gform_wrapper.gravity-theme .gform_page_footer input.light {
  background: linear-gradient(148deg, #efefef 50%, #f5f5f5);
  color: #222 !important;
  text-shadow: none;
}

.btn.btn-primary.accent,
.range-headless .headless-product-card .headless-add-to-cart .accent.headless-cart-button,
.search-form .btn-primary.accent.search-submit,
.comment-form input.btn-primary.accent[type=submit],
.btn.primary-button.accent,
.comment-form input.primary-button.accent[type=submit],
.btn.secondary-button .cta.nav-item a.accent,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.accent,
.search-form .secondary-button.search-submit .cta.nav-item a.accent,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.accent,
.primary-btn.accent,
.primary-button.accent,
.woocommerce #respond input#submit.accent,
.woocommerce a.button.accent,
.woocommerce button.button.accent,
.woocommerce input.button.accent,
.gform_legacy_markup_wrapper .gform_footer input.button.accent,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].accent,
.gform_legacy_markup_wrapper .gform_page_footer input.button.accent,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].accent,
.gform_wrapper.gravity-theme .gform_footer button.accent,
.gform_wrapper.gravity-theme .gform_footer input.accent,
.gform_wrapper.gravity-theme .gform_page_footer button.accent,
.gform_wrapper.gravity-theme .gform_page_footer input.accent {
  background: #9db09b;
  color: #fff !important;
  text-shadow: 0;
  border-color: #9db09b !important;
}

.btn.btn-primary.accent i,
.range-headless .headless-product-card .headless-add-to-cart .accent.headless-cart-button i,
.search-form .btn-primary.accent.search-submit i,
.comment-form input.btn-primary.accent[type=submit] i,
.btn.primary-button.accent i,
.btn.secondary-button .cta.nav-item a.accent i,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.accent i,
.search-form .secondary-button.search-submit .cta.nav-item a.accent i,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.accent i,
.primary-btn.accent i,
.primary-button.accent i,
.woocommerce #respond input#submit.accent i,
.woocommerce a.button.accent i,
.woocommerce button.button.accent i,
.woocommerce input.button.accent i,
.gform_legacy_markup_wrapper .gform_footer input.button.accent i,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].accent i,
.gform_legacy_markup_wrapper .gform_page_footer input.button.accent i,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].accent i,
.gform_wrapper.gravity-theme .gform_footer button.accent i,
.gform_wrapper.gravity-theme .gform_footer input.accent i,
.gform_wrapper.gravity-theme .gform_page_footer button.accent i,
.gform_wrapper.gravity-theme .gform_page_footer input.accent i {
  color: #fff !important;
}

.btn.btn-primary.accent a[href^=tel],
.range-headless .headless-product-card .headless-add-to-cart .accent.headless-cart-button a[href^=tel],
.search-form .btn-primary.accent.search-submit a[href^=tel],
.comment-form input.btn-primary.accent[type=submit] a[href^=tel],
.btn.primary-button.accent a[href^=tel],
.btn.secondary-button .cta.nav-item a.accent a[href^=tel],
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.accent a[href^=tel],
.search-form .secondary-button.search-submit .cta.nav-item a.accent a[href^=tel],
.comment-form input.secondary-button[type=submit] .cta.nav-item a.accent a[href^=tel],
.primary-btn.accent a[href^=tel],
.primary-button.accent a[href^=tel],
.woocommerce #respond input#submit.accent a[href^=tel],
.woocommerce a.button.accent a[href^=tel],
.woocommerce button.button.accent a[href^=tel],
.woocommerce input.button.accent a[href^=tel],
.gform_legacy_markup_wrapper .gform_footer input.button.accent a[href^=tel],
.gform_legacy_markup_wrapper .gform_footer input[type=submit].accent a[href^=tel],
.gform_legacy_markup_wrapper .gform_page_footer input.button.accent a[href^=tel],
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].accent a[href^=tel],
.gform_wrapper.gravity-theme .gform_footer button.accent a[href^=tel],
.gform_wrapper.gravity-theme .gform_footer input.accent a[href^=tel],
.gform_wrapper.gravity-theme .gform_page_footer button.accent a[href^=tel],
.gform_wrapper.gravity-theme .gform_page_footer input.accent a[href^=tel] {
  color: #fff !important;
}

.btn.btn-primary.accent:hover,
.range-headless .headless-product-card .headless-add-to-cart .accent.headless-cart-button:hover,
.search-form .btn-primary.accent.search-submit:hover,
.comment-form input.btn-primary.accent[type=submit]:hover,
.btn.primary-button.accent:hover,
.btn.secondary-button .cta.nav-item a.accent:hover,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.accent:hover,
.search-form .secondary-button.search-submit .cta.nav-item a.accent:hover,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.accent:hover,
.primary-btn.accent:hover,
.primary-button.accent:hover,
.woocommerce #respond input#submit.accent:hover,
.woocommerce a.button.accent:hover,
.woocommerce button.button.accent:hover,
.woocommerce input.button.accent:hover,
.gform_legacy_markup_wrapper .gform_footer input.button.accent:hover,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].accent:hover,
.gform_legacy_markup_wrapper .gform_page_footer input.button.accent:hover,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].accent:hover,
.gform_wrapper.gravity-theme .gform_footer button.accent:hover,
.gform_wrapper.gravity-theme .gform_footer input.accent:hover,
.gform_wrapper.gravity-theme .gform_page_footer button.accent:hover,
.gform_wrapper.gravity-theme .gform_page_footer input.accent:hover {
  background: white !important;
  border-color: #9db09b !important;
  color: #000 !important;
}

.btn.btn-primary.dark-btn,
.range-headless .headless-product-card .headless-add-to-cart .dark-btn.headless-cart-button,
.search-form .btn-primary.dark-btn.search-submit,
.comment-form input.btn-primary.dark-btn[type=submit],
.btn.primary-button.dark-btn,
.comment-form input.primary-button.dark-btn[type=submit],
.btn.secondary-button .cta.nav-item a.dark-btn,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.dark-btn,
.search-form .secondary-button.search-submit .cta.nav-item a.dark-btn,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.dark-btn,
.primary-btn.dark-btn,
.primary-button.dark-btn,
.woocommerce #respond input#submit.dark-btn,
.woocommerce a.button.dark-btn,
.woocommerce button.button.dark-btn,
.woocommerce input.button.dark-btn,
.gform_legacy_markup_wrapper .gform_footer input.button.dark-btn,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].dark-btn,
.gform_legacy_markup_wrapper .gform_page_footer input.button.dark-btn,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].dark-btn,
.gform_wrapper.gravity-theme .gform_footer button.dark-btn,
.gform_wrapper.gravity-theme .gform_footer input.dark-btn,
.gform_wrapper.gravity-theme .gform_page_footer button.dark-btn,
.gform_wrapper.gravity-theme .gform_page_footer input.dark-btn {
  background: #1A1616;
  color: #fff !important;
  text-shadow: 0;
  border-color: #1A1616;
}

.btn.btn-primary.dark-btn:hover,
.range-headless .headless-product-card .headless-add-to-cart .dark-btn.headless-cart-button:hover,
.search-form .btn-primary.dark-btn.search-submit:hover,
.comment-form input.btn-primary.dark-btn[type=submit]:hover,
.btn.primary-button.dark-btn:hover,
.btn.secondary-button .cta.nav-item a.dark-btn:hover,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.dark-btn:hover,
.search-form .secondary-button.search-submit .cta.nav-item a.dark-btn:hover,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.dark-btn:hover,
.primary-btn.dark-btn:hover,
.primary-button.dark-btn:hover,
.woocommerce #respond input#submit.dark-btn:hover,
.woocommerce a.button.dark-btn:hover,
.woocommerce button.button.dark-btn:hover,
.woocommerce input.button.dark-btn:hover,
.gform_legacy_markup_wrapper .gform_footer input.button.dark-btn:hover,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].dark-btn:hover,
.gform_legacy_markup_wrapper .gform_page_footer input.button.dark-btn:hover,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].dark-btn:hover,
.gform_wrapper.gravity-theme .gform_footer button.dark-btn:hover,
.gform_wrapper.gravity-theme .gform_footer input.dark-btn:hover,
.gform_wrapper.gravity-theme .gform_page_footer button.dark-btn:hover,
.gform_wrapper.gravity-theme .gform_page_footer input.dark-btn:hover {
  background: #433939;
}

.btn.btn-primary.dark-btn i,
.range-headless .headless-product-card .headless-add-to-cart .dark-btn.headless-cart-button i,
.search-form .btn-primary.dark-btn.search-submit i,
.comment-form input.btn-primary.dark-btn[type=submit] i,
.btn.primary-button.dark-btn i,
.btn.secondary-button .cta.nav-item a.dark-btn i,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.dark-btn i,
.search-form .secondary-button.search-submit .cta.nav-item a.dark-btn i,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.dark-btn i,
.primary-btn.dark-btn i,
.primary-button.dark-btn i,
.woocommerce #respond input#submit.dark-btn i,
.woocommerce a.button.dark-btn i,
.woocommerce button.button.dark-btn i,
.woocommerce input.button.dark-btn i,
.gform_legacy_markup_wrapper .gform_footer input.button.dark-btn i,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].dark-btn i,
.gform_legacy_markup_wrapper .gform_page_footer input.button.dark-btn i,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].dark-btn i,
.gform_wrapper.gravity-theme .gform_footer button.dark-btn i,
.gform_wrapper.gravity-theme .gform_footer input.dark-btn i,
.gform_wrapper.gravity-theme .gform_page_footer button.dark-btn i,
.gform_wrapper.gravity-theme .gform_page_footer input.dark-btn i {
  color: #fff !important;
}

.btn.btn-primary.dark-btn a[href^=tel],
.range-headless .headless-product-card .headless-add-to-cart .dark-btn.headless-cart-button a[href^=tel],
.search-form .btn-primary.dark-btn.search-submit a[href^=tel],
.comment-form input.btn-primary.dark-btn[type=submit] a[href^=tel],
.btn.primary-button.dark-btn a[href^=tel],
.btn.secondary-button .cta.nav-item a.dark-btn a[href^=tel],
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.dark-btn a[href^=tel],
.search-form .secondary-button.search-submit .cta.nav-item a.dark-btn a[href^=tel],
.comment-form input.secondary-button[type=submit] .cta.nav-item a.dark-btn a[href^=tel],
.primary-btn.dark-btn a[href^=tel],
.primary-button.dark-btn a[href^=tel],
.woocommerce #respond input#submit.dark-btn a[href^=tel],
.woocommerce a.button.dark-btn a[href^=tel],
.woocommerce button.button.dark-btn a[href^=tel],
.woocommerce input.button.dark-btn a[href^=tel],
.gform_legacy_markup_wrapper .gform_footer input.button.dark-btn a[href^=tel],
.gform_legacy_markup_wrapper .gform_footer input[type=submit].dark-btn a[href^=tel],
.gform_legacy_markup_wrapper .gform_page_footer input.button.dark-btn a[href^=tel],
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].dark-btn a[href^=tel],
.gform_wrapper.gravity-theme .gform_footer button.dark-btn a[href^=tel],
.gform_wrapper.gravity-theme .gform_footer input.dark-btn a[href^=tel],
.gform_wrapper.gravity-theme .gform_page_footer button.dark-btn a[href^=tel],
.gform_wrapper.gravity-theme .gform_page_footer input.dark-btn a[href^=tel] {
  color: #fff !important;
}

.btn.btn-primary.secondary,
.range-headless .headless-product-card .headless-add-to-cart .secondary.headless-cart-button,
.search-form .btn-primary.secondary.search-submit,
.comment-form input.btn-primary.secondary[type=submit],
.btn.primary-button.secondary,
.comment-form input.primary-button.secondary[type=submit],
.btn.secondary-button .cta.nav-item a.secondary,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.secondary,
.search-form .secondary-button.search-submit .cta.nav-item a.secondary,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.secondary,
.primary-btn.secondary,
.primary-button.secondary,
.woocommerce #respond input#submit.secondary,
.woocommerce a.button.secondary,
.woocommerce button.button.secondary,
.woocommerce input.button.secondary,
.gform_legacy_markup_wrapper .gform_footer input.button.secondary,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].secondary,
.gform_legacy_markup_wrapper .gform_page_footer input.button.secondary,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].secondary,
.gform_wrapper.gravity-theme .gform_footer button.secondary,
.gform_wrapper.gravity-theme .gform_footer input.secondary,
.gform_wrapper.gravity-theme .gform_page_footer button.secondary,
.gform_wrapper.gravity-theme .gform_page_footer input.secondary {
  background: #000;
  color: #fff !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}

.btn.btn-primary.secondary a[href^=tel],
.range-headless .headless-product-card .headless-add-to-cart .secondary.headless-cart-button a[href^=tel],
.search-form .btn-primary.secondary.search-submit a[href^=tel],
.comment-form input.btn-primary.secondary[type=submit] a[href^=tel],
.btn.primary-button.secondary a[href^=tel],
.btn.secondary-button .cta.nav-item a.secondary a[href^=tel],
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.secondary a[href^=tel],
.search-form .secondary-button.search-submit .cta.nav-item a.secondary a[href^=tel],
.comment-form input.secondary-button[type=submit] .cta.nav-item a.secondary a[href^=tel],
.primary-btn.secondary a[href^=tel],
.primary-button.secondary a[href^=tel],
.woocommerce #respond input#submit.secondary a[href^=tel],
.woocommerce a.button.secondary a[href^=tel],
.woocommerce button.button.secondary a[href^=tel],
.woocommerce input.button.secondary a[href^=tel],
.gform_legacy_markup_wrapper .gform_footer input.button.secondary a[href^=tel],
.gform_legacy_markup_wrapper .gform_footer input[type=submit].secondary a[href^=tel],
.gform_legacy_markup_wrapper .gform_page_footer input.button.secondary a[href^=tel],
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].secondary a[href^=tel],
.gform_wrapper.gravity-theme .gform_footer button.secondary a[href^=tel],
.gform_wrapper.gravity-theme .gform_footer input.secondary a[href^=tel],
.gform_wrapper.gravity-theme .gform_page_footer button.secondary a[href^=tel],
.gform_wrapper.gravity-theme .gform_page_footer input.secondary a[href^=tel] {
  color: #fff !important;
}

.btn.btn-primary.secondary i,
.range-headless .headless-product-card .headless-add-to-cart .secondary.headless-cart-button i,
.search-form .btn-primary.secondary.search-submit i,
.comment-form input.btn-primary.secondary[type=submit] i,
.btn.primary-button.secondary i,
.btn.secondary-button .cta.nav-item a.secondary i,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.secondary i,
.search-form .secondary-button.search-submit .cta.nav-item a.secondary i,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.secondary i,
.primary-btn.secondary i,
.primary-button.secondary i,
.woocommerce #respond input#submit.secondary i,
.woocommerce a.button.secondary i,
.woocommerce button.button.secondary i,
.woocommerce input.button.secondary i,
.gform_legacy_markup_wrapper .gform_footer input.button.secondary i,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].secondary i,
.gform_legacy_markup_wrapper .gform_page_footer input.button.secondary i,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].secondary i,
.gform_wrapper.gravity-theme .gform_footer button.secondary i,
.gform_wrapper.gravity-theme .gform_footer input.secondary i,
.gform_wrapper.gravity-theme .gform_page_footer button.secondary i,
.gform_wrapper.gravity-theme .gform_page_footer input.secondary i {
  color: #fff !important;
}

.btn.btn-primary.dark-btn,
.range-headless .headless-product-card .headless-add-to-cart .dark-btn.headless-cart-button,
.search-form .btn-primary.dark-btn.search-submit,
.comment-form input.btn-primary.dark-btn[type=submit],
.btn.primary-button.dark-btn,
.comment-form input.primary-button.dark-btn[type=submit],
.btn.secondary-button .cta.nav-item a.dark-btn,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.dark-btn,
.search-form .secondary-button.search-submit .cta.nav-item a.dark-btn,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.dark-btn,
.primary-btn.dark-btn,
.primary-button.dark-btn,
.woocommerce #respond input#submit.dark-btn,
.woocommerce a.button.dark-btn,
.woocommerce button.button.dark-btn,
.woocommerce input.button.dark-btn,
.gform_legacy_markup_wrapper .gform_footer input.button.dark-btn,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].dark-btn,
.gform_legacy_markup_wrapper .gform_page_footer input.button.dark-btn,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].dark-btn,
.gform_wrapper.gravity-theme .gform_footer button.dark-btn,
.gform_wrapper.gravity-theme .gform_footer input.dark-btn,
.gform_wrapper.gravity-theme .gform_page_footer button.dark-btn,
.gform_wrapper.gravity-theme .gform_page_footer input.dark-btn {
  background: #1A1616 !important;
  color: #fff !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

.btn.btn-primary.white-btn,
.range-headless .headless-product-card .headless-add-to-cart .white-btn.headless-cart-button,
.search-form .btn-primary.white-btn.search-submit,
.comment-form input.btn-primary.white-btn[type=submit],
.btn.primary-button.white-btn,
.comment-form input.primary-button.white-btn[type=submit],
.btn.secondary-button .cta.nav-item a.white-btn,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.white-btn,
.search-form .secondary-button.search-submit .cta.nav-item a.white-btn,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.white-btn,
.primary-btn.white-btn,
.primary-button.white-btn,
.woocommerce #respond input#submit.white-btn,
.woocommerce a.button.white-btn,
.woocommerce button.button.white-btn,
.woocommerce input.button.white-btn,
.gform_legacy_markup_wrapper .gform_footer input.button.white-btn,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].white-btn,
.gform_legacy_markup_wrapper .gform_page_footer input.button.white-btn,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].white-btn,
.gform_wrapper.gravity-theme .gform_footer button.white-btn,
.gform_wrapper.gravity-theme .gform_footer input.white-btn,
.gform_wrapper.gravity-theme .gform_page_footer button.white-btn,
.gform_wrapper.gravity-theme .gform_page_footer input.white-btn {
  background: #fff;
  color: #000 !important;
}

.btn.btn-primary.white-btn:hover,
.range-headless .headless-product-card .headless-add-to-cart .white-btn.headless-cart-button:hover,
.search-form .btn-primary.white-btn.search-submit:hover,
.comment-form input.btn-primary.white-btn[type=submit]:hover,
.btn.primary-button.white-btn:hover,
.btn.secondary-button .cta.nav-item a.white-btn:hover,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.white-btn:hover,
.search-form .secondary-button.search-submit .cta.nav-item a.white-btn:hover,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.white-btn:hover,
.primary-btn.white-btn:hover,
.primary-button.white-btn:hover,
.woocommerce #respond input#submit.white-btn:hover,
.woocommerce a.button.white-btn:hover,
.woocommerce button.button.white-btn:hover,
.woocommerce input.button.white-btn:hover,
.gform_legacy_markup_wrapper .gform_footer input.button.white-btn:hover,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].white-btn:hover,
.gform_legacy_markup_wrapper .gform_page_footer input.button.white-btn:hover,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].white-btn:hover,
.gform_wrapper.gravity-theme .gform_footer button.white-btn:hover,
.gform_wrapper.gravity-theme .gform_footer input.white-btn:hover,
.gform_wrapper.gravity-theme .gform_page_footer button.white-btn:hover,
.gform_wrapper.gravity-theme .gform_page_footer input.white-btn:hover {
  color: white !important;
  background: #000 !important;
}

.btn.btn-primary.top-margin,
.range-headless .headless-product-card .headless-add-to-cart .top-margin.headless-cart-button,
.search-form .btn-primary.top-margin.search-submit,
.comment-form input.btn-primary.top-margin[type=submit],
.btn.primary-button.top-margin,
.comment-form input.primary-button.top-margin[type=submit],
.btn.secondary-button .cta.nav-item a.top-margin,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.top-margin,
.search-form .secondary-button.search-submit .cta.nav-item a.top-margin,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.top-margin,
.primary-btn.top-margin,
.primary-button.top-margin,
.woocommerce #respond input#submit.top-margin,
.woocommerce a.button.top-margin,
.woocommerce button.button.top-margin,
.woocommerce input.button.top-margin,
.gform_legacy_markup_wrapper .gform_footer input.button.top-margin,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].top-margin,
.gform_legacy_markup_wrapper .gform_page_footer input.button.top-margin,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].top-margin,
.gform_wrapper.gravity-theme .gform_footer button.top-margin,
.gform_wrapper.gravity-theme .gform_footer input.top-margin,
.gform_wrapper.gravity-theme .gform_page_footer button.top-margin,
.gform_wrapper.gravity-theme .gform_page_footer input.top-margin {
  margin-top: 15px;
}

.btn.btn-primary.no-arrow,
.range-headless .headless-product-card .headless-add-to-cart .no-arrow.headless-cart-button,
.search-form .btn-primary.no-arrow.search-submit,
.comment-form input.btn-primary.no-arrow[type=submit],
.btn.primary-button.no-arrow,
.comment-form input.primary-button.no-arrow[type=submit],
.btn.secondary-button .cta.nav-item a.no-arrow,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.no-arrow,
.search-form .secondary-button.search-submit .cta.nav-item a.no-arrow,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.no-arrow,
.primary-btn.no-arrow,
.primary-button.no-arrow,
.woocommerce #respond input#submit.no-arrow,
.woocommerce a.button.no-arrow,
.woocommerce button.button.no-arrow,
.woocommerce input.button.no-arrow,
.gform_legacy_markup_wrapper .gform_footer input.button.no-arrow,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].no-arrow,
.gform_legacy_markup_wrapper .gform_page_footer input.button.no-arrow,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].no-arrow,
.gform_wrapper.gravity-theme .gform_footer button.no-arrow,
.gform_wrapper.gravity-theme .gform_footer input.no-arrow,
.gform_wrapper.gravity-theme .gform_page_footer button.no-arrow,
.gform_wrapper.gravity-theme .gform_page_footer input.no-arrow {
  padding: 0.5em 1em 0.5em 1em !important !important;
}

.btn.btn-primary.no-arrow:after,
.range-headless .headless-product-card .headless-add-to-cart .no-arrow.headless-cart-button:after,
.search-form .btn-primary.no-arrow.search-submit:after,
.comment-form input.btn-primary.no-arrow[type=submit]:after,
.btn.primary-button.no-arrow:after,
.btn.secondary-button .cta.nav-item a.no-arrow:after,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.no-arrow:after,
.search-form .secondary-button.search-submit .cta.nav-item a.no-arrow:after,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.no-arrow:after,
.primary-btn.no-arrow:after,
.primary-button.no-arrow:after,
.woocommerce #respond input#submit.no-arrow:after,
.woocommerce a.button.no-arrow:after,
.woocommerce button.button.no-arrow:after,
.woocommerce input.button.no-arrow:after,
.gform_legacy_markup_wrapper .gform_footer input.button.no-arrow:after,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].no-arrow:after,
.gform_legacy_markup_wrapper .gform_page_footer input.button.no-arrow:after,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].no-arrow:after,
.gform_wrapper.gravity-theme .gform_footer button.no-arrow:after,
.gform_wrapper.gravity-theme .gform_footer input.no-arrow:after,
.gform_wrapper.gravity-theme .gform_page_footer button.no-arrow:after,
.gform_wrapper.gravity-theme .gform_page_footer input.no-arrow:after {
  display: none;
}

.btn.btn-primary.smaller,
.range-headless .headless-product-card .headless-add-to-cart .smaller.headless-cart-button,
.search-form .btn-primary.smaller.search-submit,
.comment-form input.btn-primary.smaller[type=submit],
.btn.primary-button.smaller,
.comment-form input.primary-button.smaller[type=submit],
.btn.secondary-button .cta.nav-item a.smaller,
.range-headless .headless-product-card .headless-add-to-cart .secondary-button.headless-cart-button .cta.nav-item a.smaller,
.search-form .secondary-button.search-submit .cta.nav-item a.smaller,
.comment-form input.secondary-button[type=submit] .cta.nav-item a.smaller,
.primary-btn.smaller,
.primary-button.smaller,
.woocommerce #respond input#submit.smaller,
.woocommerce a.button.smaller,
.woocommerce button.button.smaller,
.woocommerce input.button.smaller,
.gform_legacy_markup_wrapper .gform_footer input.button.smaller,
.gform_legacy_markup_wrapper .gform_footer input[type=submit].smaller,
.gform_legacy_markup_wrapper .gform_page_footer input.button.smaller,
.gform_legacy_markup_wrapper .gform_page_footer input[type=submit].smaller,
.gform_wrapper.gravity-theme .gform_footer button.smaller,
.gform_wrapper.gravity-theme .gform_footer input.smaller,
.gform_wrapper.gravity-theme .gform_page_footer button.smaller,
.gform_wrapper.gravity-theme .gform_page_footer input.smaller {
  font-size: 14px;
  padding: 0.5rem 1rem;
}

.cta.nav-item a {
  padding: 0 15px;
  margin-top: 8px;
}

.cta.nav-item a:after {
  display: none;
}

.secondary-btn,
.secondary-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background: #000;
  color: white;
  text-shadow: 1px 1px 0 black;
  text-transform: uppercase;
  border-radius: 2px;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;
  text-decoration: none;
  -webkit-box-shadow: 2px 2px 0 1px black;
  box-shadow: 2px 2px 0 1px black;
  font-weight: 700;
}

@media (min-width: 992px) {
  .secondary-btn,
  .secondary-button {
    padding: 10px 25px;
    font-size: 18px;
  }
}

.secondary-btn:hover,
.secondary-button:hover {
  opacity: 0.8;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.primary-btn.small,
.primary-button.small,
.secondary-btn.small,
.secondary-button.small {
  font-size: 14px !important;
  padding: 0.5em 0.75em 0.5em 0.75em !important;
  margin-bottom: 0;
}

.primary-btn.small:after,
.primary-button.small:after,
.secondary-btn.small:after,
.secondary-button.small:after {
  display: none;
}

.button-shadow {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
}

button.btn.btn-primary.btn-xs[data-target="#secondaryGeos"],
.range-headless .headless-product-card .headless-add-to-cart button.btn-xs[data-target="#secondaryGeos"].headless-cart-button,
.search-form button.btn-primary.btn-xs[data-target="#secondaryGeos"].search-submit {
  display: block;
  width: 100%;
  border: 0;
}

div#secondaryGeos {
  background: #9db09b;
  text-align: center;
  outline: 0 !important;
}

div#secondaryGeos h4,
div#secondaryGeos .h4 {
  display: inline-block;
  margin: 10px;
  color: #fff;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 15px;
}

a.arrow-btn {
  color: #1A1616 !important;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  transition: all 0.2s ease-out;
  display: inline-block;
  min-height: 51px !important;
  background: transparent;
  text-transform: uppercase;
  border: 0px;
}

a.arrow-btn span {
  display: inline-block;
  transition: all 0.2s ease-out;
}

a.arrow-btn:before {
  content: "→";
  height: 30px;
  width: 30px;
  border: solid 1px;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: #000;
  background: rgba(0, 0, 0, 0);
  transition: all 0.2s ease-out;
}

@media (min-width: 768px) {
  a.arrow-btn:before {
    height: 50px;
    width: 50px;
  }
}

a.arrow-btn.primary:before {
  background: linear-gradient(#000, #620101);
  border-color: #000;
  transition: all 0.2s ease-out;
}

a.arrow-btn:hover {
  transition: all 0.2s ease-out;
}

a.arrow-btn:hover span {
  transform: translateX(10px);
  transition: all 0.2s ease-out;
}

a.arrow-btn:hover:before {
  background: linear-gradient(#1A1616, #000);
  border-color: #1A1616;
  transition: all 0.2s ease-out;
  margin-right: 25px;
  color: #fff;
}

a.arrow-btn.white-btn {
  color: #fff !important;
}

a.arrow-btn.white-btn:before {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

a.arrow-btn.white-btn:hover:before {
  background: #fff;
  color: #000;
}

.orange-button,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button {
  background: #ff9e1b !important;
  color: #fff !important;
  border: 4px solid #ff9e1b !important;
}

.orange-button:hover,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:hover,
.orange-button:active,
.range-headless .headless-product-card .headless-add-to-cart .headless-cart-button:active {
  background: #ff9e1b !important;
  color: #fff !important;
  border: 4px solid #ff9e1b !important;
  opacity: 0.8 !important;
}

.comment-list ol {
  list-style: none;
}

/** Search form */

.search-form label {
  font-weight: normal;
}

.comment-form input[type=email],
.comment-form input[type=text],
.comment-form input[type=url],
.comment-form textarea,
.form-control,
.search-form .search-field {
  font-size: 22px;
}

label.gfield_label .gfield_required,
legend.gfield_label .gfield_required {
  color: #9db09b;
}

.gform_wrapper .gfield_label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 8px;
  display: inline-block;
  padding: 0;
}

.gform_wrapper .gfield input.large,
.gform_wrapper .gfield select.large,
.gform_wrapper textarea {
  width: 100%;
  font-size: 15px;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 0;
  margin: 0;
}

.gform_wrapper .gform_footer input {
  background: #000;
  border: 4px solid #000;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: auto;
  font-weight: 600;
  line-height: 1;
  padding: 0.5rem 1.5rem 0.4rem;
  text-align: center;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
}

.gfield_validation_message {
  color: red;
  font-size: 16px;
  font-weight: bold;
}

/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

/** Media alignment */

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: 0.75rem auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: 0.75rem;
  height: auto;
}

@media (min-width: 576px) {
  .alignleft {
    float: left;
    margin-right: 0.75rem;
  }

  .alignright {
    float: right;
    margin-left: 0.75rem;
  }
}

/** Captions */

/** Text meant only for screen readers */

/** Import special layouts */

.accordion-button:hover {
  background: #f5f5f5;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background: #a0ab9d;
  box-shadow: none;
}

.accordion-button:not(.collapsed):after {
  filter: grayscale(1);
}

.accordion-button:focus {
  border-color: #9db09b;
  box-shadow: none;
}

#offcanvasContact {
  height: 90vh;
}

#offcanvasLocations {
  height: 90vh;
}

.newstandard-image-slider .splide__slide {
  height: 85vh;
  min-height: 40rem;
  max-height: 900px;
}

.newstandard-image-slider .splide__arrow {
  background: transparent;
  top: auto;
  bottom: 20px;
}

.newstandard-image-slider .splide__arrow svg {
  fill: #fff;
}

.newstandard-image-slider .splide__pagination {
  display: none;
}

.newstandard-image-slider .display-1 {
  line-height: 1;
}

@media (min-width: 1200px) {
  .newstandard-image-slider .display-1 {
    font-size: 8rem;
  }
}

@media (min-width: 1400px) {
  .newstandard-image-slider .display-1 {
    font-size: 9rem;
  }
}

.newstandard-image-slider .display-2 {
  line-height: 1;
}

@media (min-width: 1200px) {
  .newstandard-image-slider .display-2 {
    font-size: 6rem;
  }
}

@media (min-width: 1400px) {
  .newstandard-image-slider .display-2 {
    font-size: 7rem;
  }
}

.newstandard-image-slider .display-3 {
  line-height: 1;
}

.newstandard-image-slider-colorbar {
  background-color: #a0ab9d;
  background: linear-gradient(to right, #000 0%, #000 40%, #365046 40%, #365046 55%, #f5f5f5 55%, #f5f5f5 70%, #9db09b 70%, #9db09b 85%, #000 85%, #000 100%);
}

.range-splider .splide__slider .rpb-slider-arrows .splide__arrow {
  background: transparent;
  opacity: 1;
}

.range-splider .splide__slider .rpb-slider-arrows .splide__arrow svg {
  fill: #fff;
  color: #fff;
}

.range-splider .splide__slider .rpb-slider-arrows .splide__arrow--prev svg {
  transform: none;
}

@media -breakpoint-down (md) {
  .range-splider .splide__pagination {
    display: none;
  }
}

.range-splider .splide__pagination .splide__pagination__page.is-active {
  background: #000;
}

.range-splider.blurred-slides .splide__slide:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  backdrop-filter: saturate(180%) blur(2px);
  transition: all ease-in-out 350ms;
  opacity: 1;
}

.range-splider.blurred-slides .splide__slide.is-active:before {
  content: "";
  opacity: 0;
}

.range-splider-thumbnails .splide__track {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: white;
}

.range-splider-thumbnails .splide__track .splide__list .splide__slide {
  opacity: 0.3;
}

.range-splider-thumbnails .splide__track .splide__list .splide__slide.is-active {
  border: 0 !important;
  opacity: 1;
}

.rpb-logo-slider .splide__slider .rpb-slider-arrows .splide__arrow--prev {
  left: 0;
}

.rpb-logo-slider .splide__slider .rpb-slider-arrows .splide__arrow--next {
  right: 0;
}

.rpb-logo-slider .splide__slider .splide__track {
  margin-left: 3rem;
  margin-right: 3rem;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: transparent;
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: #fff;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: none;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.weed-widget {
  position: relative;
  height: 38rem;
  background-color: #fff;
}

@media (min-width: 992px) {
  .weed-widget {
    height: 80vh;
    min-height: 34rem;
  }
}

.weed-widget:not(.is-admin) {
  z-index: 1010;
}

.weed-widget .flickity-button {
  background-color: transparent;
  outline: none !important;
}

@media (max-width: 991.98px) {
  .weed-widget .flickity-button {
    top: 33%;
  }
}

@media (min-width: 992px) {
  .weed-widget .flickity-button.previous {
    left: 6rem;
  }

  .weed-widget .flickity-button.next {
    right: 6rem;
  }
}

.weed-widget__title--side {
  position: absolute;
  z-index: 1020;
  font-weight: 500;
  top: 1rem;
  width: 100%;
  text-align: center;
}

@media (min-width: 992px) {
  .weed-widget__title--side {
    transform-origin: 0 0;
    transform: translateY(100%) rotate(-90deg);
    left: 1rem;
    top: 60%;
    width: auto;
    text-align: left;
  }
}

.weed-widget__name {
  font-size: 4rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weed-widget__name h3,
.weed-widget__name .h3 {
  font-weight: bold;
  margin: 0;
  line-height: 20rem;
  letter-spacing: -5px;
}

@media (min-width: 992px) {
  .weed-widget__name h3,
  .weed-widget__name .h3 {
    line-height: 25rem;
  }
}

.weed-widget__name h3 .word,
.weed-widget__name .h3 .word {
  white-space: nowrap;
}

.weed-widget__name h3 .letter,
.weed-widget__name .h3 .letter {
  display: inline-block;
  line-height: 1;
  transform: rotateX(-90deg);
}

.weed-widget__arrows {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.weed-widget__arrow {
  appearance: none;
  border: 0 solid;
  border-radius: 0;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1;
  padding: 1rem 0.5rem;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}

.weed-widget__arrow:active {
  outline: none;
}

.weed-widget__arrow svg {
  transition: transform 150ms ease-in-out;
}

.weed-widget__arrow--left:hover svg {
  transform: translateX(-5px);
}

.weed-widget__arrow--right:hover svg {
  transform: translateX(5px);
}

.weed-widget__slides {
  width: 100%;
  overflow: hidden;
  height: 38rem;
}

@media (min-width: 992px) {
  .weed-widget__slides {
    height: 80vh;
    min-height: 34rem;
  }
}

.weed-widget__slides .weed-widget__lettering {
  opacity: 0;
}

.weed-widget__slides.flickity-enabled .weed-widget__lettering {
  opacity: 1;
}

.weed-widget__slide {
  position: relative;
  z-index: 1010;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  outline: none;
  width: 100%;
}

.weed-widget__slide .weed-widget__video {
  position: absolute;
  top: 1rem;
  bottom: 11rem;
  left: 0;
  right: 0;
  z-index: 1;
}

.weed-widget__slide .weed-widget__video video {
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .weed-widget__slide .weed-widget__video {
    bottom: 0;
  }
}

.weed-widget__slide .weed-widget__lettering .word {
  white-space: nowrap;
}

.weed-widget__slide .weed-widget__lettering .letter {
  display: inline-block;
  line-height: 1;
  transform-origin: 0 0;
  transform: rotateY(-90deg);
}

.weed-widget__slide .label {
  transform: translateY(-100%);
  display: inline-block;
}

.weed-widget__slide .char-label {
  transform: translateY(100%);
  display: inline-block;
}

.weed-widget__overlay {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 38rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .weed-widget__overlay {
    height: 80vh;
    min-height: 34rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.weed-widget__overlay-top {
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-grow: 1;
}

@media (max-width: 991.98px) {
  .weed-widget__overlay-top {
    display: block;
    width: 100%;
  }
}

.weed-widget__strain {
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  justify-content: space-evenly;
  overflow: hidden;
}

.weed-widget__strain .char-label {
  margin-left: 1rem;
}

@media (min-width: 992px) {
  .weed-widget__strain .char-label {
    margin-left: 0;
  }
}

.weed-widget__stats {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .weed-widget__stats {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

.weed-widget__stats .label {
  width: 5rem;
}

@media (min-width: 992px) {
  .weed-widget__stats .label {
    width: 7rem;
  }
}

.weed-widget__stats > div {
  overflow: hidden;
}

.weed-widget__stats > div .weed-widget__progress-bar--container > div {
  width: 0%;
  transition: width 750ms ease-out;
}

.weed-widget__stats > div:nth-child(1) .weed-widget__progress-bar--container > div {
  transition-delay: 150ms;
}

.weed-widget__stats > div:nth-child(2) .weed-widget__progress-bar--container > div {
  transition-delay: 300ms;
}

.weed-widget__stats > div:nth-child(3) .weed-widget__progress-bar--container > div {
  transition-delay: 450ms;
}

.weed-widget__characteristics {
  font-size: 1rem;
  font-weight: 500;
}

@media (min-width: 992px) {
  .weed-widget__characteristics {
    font-size: 1.5rem;
  }
}

.weed-widget__characteristics > div {
  overflow: hidden;
}

.weed-widget__progress-bar--container {
  position: relative;
  height: 0.5rem;
  margin-left: 1rem;
  flex-grow: 1;
}

.weed-widget__progress-bar--container > div {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #1a1616;
}

.weed-widget.is-admin {
  height: 600px;
}

.weed-widget.is-admin .weed-widget__slides,
.weed-widget.is-admin .weed-widget__overlay {
  width: 100%;
  height: 600px;
}

.weed-widget.is-admin .weed-widget__overlay {
  pointer-events: none;
}

.weed-widget.is-admin .weed-widget__title--side {
  z-index: 1;
}

.weed-widget.is-admin .letter {
  transform: rotateY(0deg) !important;
  opacity: 1;
}

.weed-widget.is-admin .label {
  transform: translateY(0%);
}

.weed-widget.is-admin .char-label {
  transform: translateY(0%);
}

@media (min-width: 992px) {
  .link-box-link .link-box-label {
    font-size: 125%;
    line-height: 105%;
  }

  .link-box-link .link-box-image-holder {
    padding: 10px;
    transition: all 0.2s ease-in;
  }

  .link-box-link:hover .link-box-image-holder {
    padding: 0;
  }
}

.locations-grid-location {
  height: 150px;
  min-height: 20vh;
  max-height: 400px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in;
}

.locations-grid-location .locations-grid-location-bg {
  margin: calc(var(--bs-gutter-x)*-0.5) 0px;
  transition: all 0.2s ease-in;
}

.locations-grid-location:hover {
  height: calc(150px + 2rem);
  min-height: calc(20vh + 2rem);
  max-height: calc(400px + 2rem);
  margin-top: 0;
  margin-bottom: 0;
}

.locations-grid-location:hover .locations-grid-location-bg {
  margin: calc(var(--bs-gutter-x)*-0.5) calc(var(--bs-gutter-x)*-0.5) 0px;
}

.jumbo-link-box-link {
  height: 250px;
  min-height: 40vh;
  max-height: 600px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in;
}

.jumbo-link-box-link .jumbo-link-box-bg {
  margin: calc(var(--bs-gutter-x)*-0.5) 0px;
  transition: all 0.2s ease-in;
}

.jumbo-link-box-link:hover {
  height: calc(250px + 2rem);
  min-height: calc(40vh + 2rem);
  max-height: calc(600px + 2rem);
  margin-top: 0;
  margin-bottom: 0;
}

.jumbo-link-box-link:hover .jumbo-link-box-bg {
  margin: calc(var(--bs-gutter-x)*-0.5) calc(var(--bs-gutter-x)*-0.5) 0px;
}

.side-by-side-image-background-column {
  min-height: 250px;
}

@media (min-width: 992px) {
  .side-by-side-image-background-column {
    min-height: 30vh;
  }
}

@media (min-width: 1200px) {
  .side-by-side-image-background-column {
    min-height: 35vh;
  }
}

.navbar .navbar-collapse {
  flex-wrap: wrap;
}

.header-topbar {
  line-height: 20px;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: default !important;
  letter-spacing: 0.5px;
  font-size: 15px;
  transition: all 350ms linear;
  font-size: 16px !important;
}

.header-topbar p {
  display: inline;
  margin: 0;
  margin-bottom: 0.5rem;
}

.header-topbar p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .header-topbar p {
    margin: 0;
    margin-bottom: 0;
  }
}

.header-topbar .nav-emergency {
  display: none;
  color: white;
  padding-right: 10px;
  font-size: 18px;
  font-weight: normal !important;
  text-transform: uppercase !important;
}

@media (min-width: 992px) {
  .header-topbar .nav-emergency {
    display: inline-block;
  }
}

.header-topbar .primary-btn {
  background: #111;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.05em;
  margin-right: 10px;
  text-shadow: none;
  box-shadow: none;
  border: 0px;
  padding: 5px 10px 5px 10px;
  text-transform: none;
}

.header-topbar .primary-btn:after {
  display: none;
}

.header-topbar .social-media-link {
  color: #fff;
  padding-right: 10px;
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.header-topbar .social-media-link:hover {
  color: #fff;
  opacity: 0.8;
}

.header-topbar .phone {
  display: inline-block;
  font-size: 15px;
  margin-left: 15px;
}

@media (min-width: 576px) {
  .header-topbar .phone {
    font-size: 18px;
  }
}

.header-topbar .phone .sub-heading {
  color: #9db09b;
  margin: 0px;
  letter-spacing: normal;
  font-size: 12px;
  display: block;
}

.header-topbar.topbar-attached {
  flex-basis: 100%;
}

.header-topbar.topbar-hide {
  display: none;
}

#topbarCloser {
  right: 2px;
  top: 2px;
  filter: invert(1);
  z-index: 9;
  font-weight: 400;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

#topbarCloser:hover {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.sticky-top {
  position: sticky;
}

.trans-nav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
}

.trans-nav:not(.trans-scrolling) .header-default {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
  text-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

.trans-nav:not(.trans-scrolling) .header-default .navbar-nav .nav-link {
  color: white;
}

.trans-nav:not(.trans-scrolling) .header-default .navbar-nav .nav-link:hover {
  color: #9db09b;
}

.trans-nav:not(.trans-scrolling) .header-default .navbar-nav .nav-item a {
  color: white;
}

.trans-nav:not(.trans-scrolling) .header-default .navbar-nav .nav-item i,
.trans-nav:not(.trans-scrolling) .header-default .navbar-nav .nav-item svg {
  color: white;
}

.shrink-on-scroll.nav-shrink .header-default .theme-logo {
  transition: all 100ms linear;
  max-height: 50px;
}

.reveal-phone {
  cursor: pointer;
}

.hidden-phone {
  background: #fff;
  display: none;
  padding: 15px;
  border-radius: 5px;
  position: absolute;
  top: 30px;
  width: 200px;
  z-index: 99999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  right: 0px;
  text-align: center;
}

.hidden-phone:before {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  position: absolute;
  top: -7px;
  left: 0px;
  right: 0px;
  margin: auto;
  position: absolute;
}

@media (min-width: 1500px) {
  /*.header-style-1-topbar {
        background: linear-gradient(60deg, #003c6e 66%, #f03e44 66%)!important;
    }*/
}

.header-default {
  background: #000;
  color: #fff;
  box-shadow: 0px 7px 15px -15px rgba(0, 0, 0, 0.15);
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 10001;
  width: 100%;
  flex-wrap: nowrap;
  transition: all 350ms ease-in-out;
}

.header-default .show .navbar-nav {
  height: calc(100vh - 150px);
  overflow: scroll;
}

@media (max-width: 575.98px) {
  .header-default .show .navbar-nav {
    padding-bottom: 200px !important;
  }
}

@media (min-width: 1200px) {
  .header-default .show .navbar-nav {
    height: auto;
    padding-bottom: 0px !important;
  }
}

.header-default .row.header-style-1-contain-row,
.header-default .header-style-1-contain-row.search-form {
  width: calc(100% + 30px);
}

.header-default .navbar-brand svg {
  height: 70px;
  width: 70px;
}

.header-default .navbar-brand svg path {
  fill: #fff;
}

@media (min-width: 1200px) {
  .header-default .navbar-brand svg {
    height: 50px;
    width: 50px;
  }
}

@media (min-width: 1200px) {
  .header-default li.dropdown:last-child .dropdown-menu {
    right: 0px;
    left: auto;
  }
}

.header-default .header-social a.header-social-anchor {
  color: #fff;
  transition-duration: 350ms;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: none !important;
  font-size: 25px;
}

.header-default .header-social a.header-social-anchor i {
  font-size: 25px;
}

.header-default .header-social a.header-social-anchor svg {
  vertical-align: middle;
}

.header-default .header-social a.header-social-anchor:hover,
.header-default .header-social a.header-social-anchor:focus {
  opacity: 0.8;
  color: #fff;
}

.header-default .dynamic-order-now {
  font-size: 16px;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (max-width: 1399.98px) {
  .header-default .dynamic-order-now {
    font-size: 14px;
    padding-left: 7px;
    padding-right: 7px;
  }
}

.header-default .navbar-nav .nav-link {
  color: #fff;
  transition-duration: 350ms;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: none !important;
  padding: 5px 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.header-default .navbar-nav .nav-link:hover,
.header-default .navbar-nav .nav-link:focus {
  opacity: 1;
  color: #fff;
}

@media (min-width: 1200px) {
  .header-default .navbar-nav .nav-link {
    padding-left: 0.4em;
    padding-right: 0.4em;
    font-size: 14px;
    letter-spacing: 0.03em;
  }
}

@media (min-width: 1200px) and (min-width: 1400px) {
  .header-default .navbar-nav .nav-link {
    font-size: 17px;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

@media (min-width: 1200px) and (min-width: 1400px) and (min-width: 1400px) {
  .header-default .navbar-nav .nav-link {
    font-size: 18px;
  }
}

.header-default .navbar-nav .nav-link.dropdown-toggle::after {
  color: #fff !important;
}

.header-default .navbar-nav .active .nav-link,
.header-default .navbar-nav .active .nav-link:focus {
  color: #fff;
}

.header-default .navbar-toggler {
  border: none;
  outline: none !important;
  transition: all 0.2s ease-in;
}

.header-default .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (min-width: 768px) {
  .header-default .navbar-toggler {
    top: 25px;
  }
}

.header-default button.navbar-toggler.ml-auto.collapsed {
  outline: none;
}

@media (max-width: 1199.98px) {
  .header-default .dropdown.menu-item-has-children a {
    padding-left: 15px;
  }
}

.header-default .dropdown-menu {
  width: 100% !important;
  border: none;
  margin-top: 0px;
  transform: none !important;
  min-width: 16rem;
  background: #a0ab9d;
  padding: 0px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  border-radius: 0px !important;
}

@media (min-width: 1200px) {
  .header-default .dropdown-menu {
    position: absolute !important;
    display: block;
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.65);
    overflow: visible;
    top: 80% !important;
    transform: rotateX(-75deg);
    transform-origin: 0% 0% !important;
    left: 0 !important;
  }

  .header-default .dropdown-menu.show {
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    top: 100% !important;
    transform: rotateX(0deg);
  }
}

.header-default .dropdown-menu .nav-item {
  padding: 0px;
}

.header-default .dropdown-menu .nav-item .dropdown-item {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  transition: all 0.2s ease-in;
  font-weight: 600;
  white-space: normal;
}

.header-default .dropdown-menu .nav-item .dropdown-item:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.header-default .dropdown-menu .nav-item:last-child {
  border-bottom: none;
}

.header-default .dropdown-menu .dropdown .dropdown-toggle::after {
  position: absolute;
  top: 43%;
  right: 15px;
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media (max-width: 1199.98px) {
  .header-default .navbar-collapse .navbar-nav {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.1em;
  }

  .header-default .navbar-collapse .navbar-nav .nav-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .header-default .navbar-collapse .dropdown-menu {
    text-align: center;
    color: #222 !important;
    box-shadow: none;
  }

  .header-default .navbar-collapse .dropdown-menu .dropdown .dropdown-toggle::after {
    position: absolute;
    top: 20px;
    right: 15px;
    transform: rotate(0deg);
  }
}

@media (min-width: 1200px) {
  .header-default .navbar-nav .menu-item:hover .dropdown-menu {
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    top: 100% !important;
    transform: rotateX(0deg);
  }
}

header.sticky-top.scrolling .header-default.navbar-light .theme-logo {
  max-height: 85px;
  transition: none;
}

@media (min-width: 768px) {
  header.sticky-top.scrolling .header-default.navbar-light .theme-logo {
    max-height: 115px;
  }
}

@media (min-width: 768px) {
  header.sticky-top.scrolling .header-default.navbar-light .navbar-toggler {
    top: 32px;
  }
}

.header-top .sub-heading {
  font-size: 12px;
}

.header-style-1-offcanvas .offcanvas-header {
  background: #000;
  color: white;
}

@media (min-width: 992px) {
  .rpb-mega-menu {
    min-height: 350px;
  }
}

#defaultOffcanvas .navbar-nav .nav-link {
  color: #fff;
  transition-duration: 350ms;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: none !important;
  padding: 15px 0;
  font-weight: 600;
  text-transform: uppercase;
}

#defaultOffcanvas .navbar-nav .nav-link:hover,
#defaultOffcanvas .navbar-nav .nav-link:focus {
  opacity: 1;
  color: #fff;
}

#defaultOffcanvas .dropdown-menu {
  background: #a0ab9d;
  width: 100%;
  border-radius: 0px !important;
}

#defaultOffcanvas .dropdown-menu .dropdown-item {
  color: #fff;
  transition-duration: 350ms;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: none !important;
  font-weight: 600;
  text-transform: uppercase;
}

#defaultOffcanvas .dropdown-menu .dropdown-item:hover,
#defaultOffcanvas .dropdown-menu .dropdown-item:focus {
  opacity: 1;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

#defaultOffcanvas .dynamic-order-now-holder {
  display: block;
  padding-top: 15px;
}

#defaultOffcanvas .dynamic-order-now-holder .dynamic-order-now {
  display: block;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .header-centered {
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .header-centered .navbar-nav li {
    display: flex;
    align-items: center;
  }
}

.is-mega {
  position: relative;
}

.is-mega .navbar-collapse > div {
  position: relative;
}

.is-mega .has-mega-menu {
  position: unset;
}

.is-mega .has-mega-menu .dropdown-menu {
  min-height: 350px;
}

.is-mega .has-mega-menu .dropdown-menu .mega-title {
  flex-basis: 100% !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  padding-bottom: 10px !important;
  margin-bottom: 10px !important;
}

.is-mega .has-mega-menu .dropdown-menu .mega-title a {
  font-size: 24px;
  text-transform: none;
  color: #9db09b;
}

.is-mega .has-mega-menu .dropdown-menu .mega-title a:hover {
  background: transparent !important;
  color: #9db09b;
}

@media (max-width: 1199.98px) {
  .is-mega .has-mega-menu .dropdown-menu .mega-title {
    display: none !important;
  }
}

.is-mega.mega-full-background .dropdown-menu {
  background: transparent;
  display: block;
}

.is-mega.mega-full-background .mega-menu-image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
}

.is-mega.mega-partial-background .has-mega-menu:hover .dropdown-menu {
  display: flex;
}

.is-mega.mega-partial-background .has-mega-menu .dropdown-menu {
  background: #a0ab9d;
  background: linear-gradient(145deg, #a0ab9d 0%, #788774 100%);
  padding-right: 60%;
  padding-left: 15px;
  flex-flow: wrap;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: none;
  left: 25px !important;
  right: auto !important;
  min-width: 950px;
}

@media (min-width: 1400px) {
  .is-mega.mega-partial-background .has-mega-menu .dropdown-menu {
    min-width: 950px;
  }
}

.is-mega.mega-partial-background .has-mega-menu .dropdown-menu.show {
  display: flex;
}

.is-mega.mega-partial-background .has-mega-menu .dropdown-menu li {
  flex-basis: 50%;
  border-bottom: 0;
  padding-right: 20px;
}

.is-mega.mega-partial-background .has-mega-menu .dropdown-menu .dropdown-item {
  background: transparent !important;
  line-height: 1 !important;
  padding-bottom: 15px !important;
}

.is-mega.mega-partial-background .has-mega-menu .dropdown-menu .dropdown-item:before {
  left: 1.5rem !important;
  width: 60% !important;
}

.is-mega.mega-partial-background .has-mega-menu .mega-menu-image {
  position: absolute;
  width: 40%;
  height: 100%;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1199.98px) {
  .mega-title {
    display: none !important;
  }
}

/* purgecss end ignore */

.rpb-hero {
  z-index: 0;
  position: relative;
  /** embed video styles**/
  /** youtube video styles **/
}

.rpb-hero p:last-of-type {
  margin-bottom: 0;
}

.rpb-hero .absolute-slider,
.rpb-hero .hero-bkg-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #101010;
}

.rpb-hero .absolute-slider .slick-list,
.rpb-hero .absolute-slider .slick-slide,
.rpb-hero .absolute-slider .slick-track,
.rpb-hero .hero-bkg-slider .slick-list,
.rpb-hero .hero-bkg-slider .slick-slide,
.rpb-hero .hero-bkg-slider .slick-track {
  width: 100%;
  height: 100%;
}

.rpb-hero .absolute-slider .slick-list *,
.rpb-hero .absolute-slider .slick-slide *,
.rpb-hero .absolute-slider .slick-track *,
.rpb-hero .hero-bkg-slider .slick-list *,
.rpb-hero .hero-bkg-slider .slick-slide *,
.rpb-hero .hero-bkg-slider .slick-track * {
  height: 100%;
}

.rpb-hero .hero-video {
  overflow: hidden !important;
}

.rpb-hero .hero-video .autoplay-video {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 0%) !important;
  -o-transform: translate(-50%, 0%) !important;
  transform: translate(-50%, 0%) !important;
  width: auto;
  min-width: 100%;
  min-height: 100%;
}

.rpb-hero .inline-YTPlayer {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.rpb-hero .hero-bkg-youtube-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rpb-hero-slider .splide__slider .splide__track .splide__list .splide__slide {
  padding-right: 4rem;
  padding-left: 4rem;
}

.half-content {
  position: relative;
  background-color: #fff;
}

.half-content .canvas {
  opacity: 0;
}

@media (min-width: 992px) {
  .half-content .canvas {
    opacity: 0.75;
  }
}

.half-content .half-image {
  position: relative;
  pointer-events: none;
  display: block;
  margin: 1rem auto;
  width: 100%;
  height: 350px;
  background-size: cover;
  background-position: center;
}

@media (min-width: 768px) {
  .half-content .half-image {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    margin: 0;
  }

  .half-content .half-image.img-left {
    right: auto;
    left: 0;
  }
}

.whole-content .whole-image {
  position: relative;
  display: block;
  margin: 1rem auto;
  height: 350px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

@media (min-width: 768px) {
  .whole-content .whole-image {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    right: 0;
    top: 0;
  }
}

@media (min-width: 992px) {
  .whole-content .whole-image {
    width: 100%;
  }
}

.whole-content .whole-content-row {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.whole-content .whole-content-row .hc-content-col {
  background: #fff;
}

@media (min-width: 768px) {
  .whole-content .whole-content-row .hc-content-col {
    background: rgba(255, 255, 255, 0.95);
  }
}

@media (min-width: 768px) {
  .whole-content .hc-content-row .hc-content-col {
    background: rgba(255, 255, 255, 0.95);
  }
}

.rpb-timeline {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
}

.rpb-timeline:after :after {
  content: "";
  display: table;
  clear: both;
}

/* --------------------------------

Main components

-------------------------------- */

.rpb-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}

.rpb-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25px;
  height: 100%;
  width: 1px;
  background: #bdbdbd;
}

@media only screen and (min-width: 1170px) {
  .rpb-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .rpb-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.timeline-box-container {
  position: relative;
  margin: 2em 0;
}

.timeline-box-container:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-box-container:first-child {
  margin-top: 0;
}

.timeline-box-container:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .timeline-box-container {
    margin: 4em 0;
  }

  .timeline-box-container:first-child {
    margin-top: 0;
  }

  .timeline-box-container:last-child {
    margin-bottom: 0;
  }
}

.rpb-timeline-img {
  position: absolute;
  top: 8px;
  left: 21px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 8px #f7f7f7;
}

.rpb-timeline-img {
  background: #bdbdbd;
}

@media only screen and (min-width: 1170px) {
  .rpb-timeline-img {
    width: 9px;
    height: 9px;
    left: 50%;
    margin-left: -6px;
    margin-top: 15px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

.timeline-box-inner {
  position: relative;
  margin-left: 60px;
  margin-right: 30px;
  box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
}

.timeline-box-inner .timeline-header {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 400;
  background: #000;
}

.timeline-box-inner .timeline-header .number-box {
  display: inline;
  float: left;
  margin: 15px;
  padding: 10px;
  font-size: 35px;
  line-height: 35px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.17);
}

.timeline-box-inner .timeline-header .h4 {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: inherit;
  letter-spacing: 2px;
  margin: 0;
  padding-bottom: 6px;
  font-weight: bold;
  line-height: 1rem;
}

@media (min-width: 576px) {
  .timeline-box-inner .timeline-header .h4 {
    font-size: 165%;
    line-height: 1.2rem;
  }
}

.timeline-box-inner .timeline-header .h4 span {
  display: block;
  font-size: 0.6rem;
  margin: 0;
  font-weight: normal;
}

@media (min-width: 576px) {
  .timeline-box-inner .timeline-header .h4 span {
    font-size: 0.8rem;
  }
}

.timeline-box-inner .timeline-content {
  background: white;
  font-size: 14px;
  line-height: 24px;
  padding: 1rem;
}

.timeline-box-inner:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-box-inner p,
.timeline-box-inner .timeline-date {
  font-size: 13px;
  font-size: 0.8125rem;
}

.timeline-box-inner .timeline-date {
  display: inline-block;
  font-weight: bold;
}

@media (max-width: 1199.98px) {
  .timeline-box-inner .timeline-date {
    float: right;
    padding: 0.5rem 1rem;
  }
}

.timeline-box-inner p {
  margin-bottom: 0;
}

.timeline-box-inner::before {
  content: "";
  position: absolute;
  top: 7px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #000;
}

@media only screen and (min-width: 768px) {
  .timeline-box-inner h2,
  .timeline-box-inner .h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }

  .timeline-box-inner p {
    font-size: 16px;
    font-size: 1rem;
  }

  .timeline-box-inner .cd-read-more,
  .timeline-box-inner .timeline-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media (min-width: 1200px) {
  .timeline-box-inner {
    margin-left: 0;
    width: 36%;
    margin: 0 5%;
  }

  .timeline-box-inner::before {
    top: 18px;
    left: 100%;
    border-color: transparent;
    border-left-color: #000;
  }

  .timeline-box-inner .timeline-date {
    position: absolute;
    width: 100%;
    left: 110%;
    top: 15px;
    font-size: 16px;
    font-size: 1rem;
  }

  .timeline-box-container:nth-child(even) .timeline-box-inner {
    float: right;
  }

  .timeline-box-container:nth-child(even) .timeline-box-inner::before {
    top: 18px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #000;
  }

  .timeline-box-container:nth-child(even) .timeline-box-inner .cd-read-more {
    float: right;
  }

  .timeline-box-container:nth-child(even) .timeline-box-inner .timeline-date {
    left: auto;
    right: 110%;
    text-align: right;
  }
}

.rpb-cards .card {
  border: 0;
}

.rpb-cards .vertically-aligned-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rpb-cards .bg-img-card {
  display: none;
}

.rpb-cards.bootstrap-cards-slider .splide__slider .rpb-slider-arrows .splide__arrow--prev {
  left: 0;
}

.rpb-cards.bootstrap-cards-slider .splide__slider .rpb-slider-arrows .splide__arrow--next {
  right: 0;
}

.rpb-cards.bootstrap-cards-slider .splide__slider .splide__track {
  margin-left: 3rem;
  margin-right: 3rem;
}

.rpb-cards.bootstrap-cards-slider .splide__slider .splide__pagination {
  bottom: -2.5rem;
}

.rpb-accordion .accordion-item .accordion-body p:last-of-type {
  margin-bottom: 0;
}

.rpb-photo-gallery .gallery-lightbox .photo-gallery-photo:hover {
  cursor: pointer;
}

.inner-page-section {
  padding-top: 75px;
  padding-bottom: 60px;
}

.home .inner-page-section,
.contact-us .inner-page-section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.page-container .inner-page-section {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media (min-width: 992px) {
  .page-container .inner-page-section {
    padding-top: 0px;
  }
}

.accordion .card {
  background: transparent;
  border: none;
}

.accordion .card .card-header {
  margin-top: 10px;
}

.accordion .card .card-header .card-title {
  padding: 15px;
}

.accordion .card .card-header:after {
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
  content: "-";
  float: right;
  transition: all 0.3s ease-in;
  padding: 15px;
}

.accordion .card .card-header.collapsed:after {
  content: "+";
  color: #9db09b;
}

.accordion .card .card-body {
  padding: 2.25rem;
}

.ip-content-slider .carousel-control-prev,
.ip-content-slider .carousel-control-next,
.ip-image-slider .carousel-control-prev,
.ip-image-slider .carousel-control-next {
  width: 2rem;
}

.ip-content-slider .carousel-control-prev:hover,
.ip-content-slider .carousel-control-next:hover,
.ip-image-slider .carousel-control-prev:hover,
.ip-image-slider .carousel-control-next:hover {
  width: 3rem;
}

.ip-content-slider .carousel-indicators li,
.ip-image-slider .carousel-indicators li {
  transition: all 0.3s ease-in;
}

.ip-image-slider .carousel-item {
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
}

.ip-image-slider .carousel-item .carousel-caption {
  text-align: center;
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
}

.ip-image-gallery.ip-mason-gallery {
  column-gap: 20px;
  column-count: 2;
}

@media (min-width: 768px) {
  .ip-image-gallery.ip-mason-gallery {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .ip-image-gallery.ip-mason-gallery {
    column-count: 3;
  }
}

.ip-image-gallery.ip-mason-gallery .gallery-image {
  margin-bottom: 10px;
  margin-top: 10px;
}

.ip-image-gallery.ip-mason-gallery .gallery-image .card-img-top {
  border-radius: 0;
  border: 0;
}

.ip-image-gallery.ip-flex-gallery .gallery-image {
  background-size: cover;
  background-position: center;
  height: 300px;
  width: calc(100% - 20px);
  margin: 10px;
}

@media (min-width: 768px) {
  .ip-image-gallery.ip-flex-gallery .gallery-image {
    height: 200px;
    width: calc(50% - 20px);
  }
}

@media (min-width: 992px) {
  .ip-image-gallery.ip-flex-gallery .gallery-image {
    height: 300px;
    width: calc(50% - 20px);
  }
}

@media (min-width: 1200px) {
  .ip-image-gallery.ip-flex-gallery .gallery-image {
    height: 200px;
    width: calc(33.33% - 20px);
  }
}

.ip-image-gallery .gallery-image {
  transition: all 0.3s ease-in;
  box-shadow: 0 0 10px -8px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border: 0;
}

.ip-image-gallery .gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px -8px rgba(0, 0, 0, 0.2);
}

.card-img-top {
  min-height: 250px;
  width: 100%;
  background-position: 50%;
  background-size: cover;
  border-radius: 0px;
}

.card-group {
  /*> .card + .card {
    margin-left: 1% !important;
  } */
}

.card-group .card {
  /*flex-basis: 98%;*/
  /*margin: 1% !important;*/
  /*@include media-breakpoint-up(md) {
    flex-basis: 48%;
    max-width: 50%;
  }
  @include media-breakpoint-up(lg) {
    flex-basis: 31.3%;
    max-width: 50%;
  }*/
  background: #fff;
  background-position: 50%;
  background-size: cover;
  border: 0;
  border-radius: 0;
}

.card-group .card .card-img-top {
  min-height: 250px;
  width: 100%;
  background-position: 50%;
  background-size: cover;
  border-radius: 0px;
}

.card-group .card .card-body .card-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 800;
}

@media (min-width: 992px) {
  .card-group .card .card-body .card-title {
    font-size: 22px;
  }
}

.card-group .card .card-footer {
  padding: 1rem 0;
  border-radius: 0;
  background: transparent;
  border: 0;
}

.card-group .card.light-text {
  background-color: #1A1616;
}

.card-group .card.light-text .card-body,
.card-group .card.light-text .card-body * {
  color: #f5f5f5;
}

.card-group .card.light-text .card-body input,
.card-group .card.light-text .card-body select {
  color: #1A1616 !important;
}

.card-group .card.light-text .card-footer {
  padding: 1rem 0;
  border-radius: 0;
  background: rgba(26, 22, 22, 0);
  border: 0;
  box-shadow: -10px 0 10px -8px rgba(26, 22, 22, 0.5);
}

.embed-container {
  box-shadow: 0 0 30px -8px rgba(0, 0, 0, 0.25);
}

#review-slider .five-stars {
  color: gold;
}

.about-feature {
  margin-top: 50px;
}

.about-feature .about-feature-1 {
  background: #000;
}

.about-feature .about-feature-2 {
  background: #111;
}

.about-feature .about-feature-3 {
  background: #000;
}

.about-feature .block {
  color: #fff;
  width: 33.33%;
  padding: 5%;
  float: left;
}

.item-title {
  color: #f5f5f5;
}

.service-icon.company {
  font-size: 60px;
}

.rpb-customer-reviews .reviews-heading-icon {
  font-size: 60px;
}

.rpb-customer-reviews .customer-reviews-slider .splide__slider .splide__track {
  margin-left: 3rem;
  margin-right: 3rem;
}

.rpb-customer-reviews .customer-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow--prev {
  left: 0;
}

.rpb-customer-reviews .customer-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow--next {
  right: 0;
}

.rpb-customer-reviews .customer-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow {
  background: #000;
}

.rpb-customer-reviews .customer-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow svg {
  color: white;
}

.rpb-customer-reviews.reviews-style-1 .customer-reviews-slider {
  text-align: center;
}

.rpb-customer-reviews.reviews-style-1 .customer-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow {
  background: #000;
}

.rpb-customer-reviews.reviews-style-1 .customer-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow svg {
  color: white;
}

.rpb-customer-reviews.reviews-style-2 .customer-reviews-slider {
  text-align: center;
}

@media (min-width: 992px) {
  .rpb-customer-reviews.reviews-style-2 .customer-reviews-slider {
    text-align: left;
  }
}

.rpb-customer-reviews.reviews-style-2 .customer-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow {
  background: transparent;
}

.rpb-customer-reviews.reviews-style-2 .customer-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow svg {
  color: #888;
}

.rpb-customer-reviews.reviews-style-2 .location-single-review-name {
  font-size: 20px;
}

@media (min-width: 992px) {
  .rpb-customer-reviews.reviews-style-2 .location-single-review-name {
    font-size: 26px;
  }
}

.rpb-customer-reviews .location-single-review .location-single-review-icon {
  font-size: 50px;
  margin-bottom: 1rem;
  opacity: 0.15;
}

.rpb-customer-reviews .location-single-review .location-single-review-stars {
  margin-bottom: 1rem;
}

.rpb-customer-reviews .location-single-review .location-single-review-stars i,
.rpb-customer-reviews .location-single-review .location-single-review-stars svg {
  color: #F78F18;
}

.rpb-customer-reviews .location-single-review .location-single-review-content {
  font-style: italic;
  margin-bottom: 1rem;
}

.rpb-customer-reviews .location-single-review .location-single-review-name span {
  padding: 10px 30px;
  border-radius: 999px;
  text-transform: uppercase;
  color: white;
}

.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper ul.gform_fields li.gfield {
  padding-right: 0;
}

.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper .gform_footer input.button,
.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper .gform_footer input[type=submit],
.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper .gform_page_footer input.button,
.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper .gform_page_footer input[type=submit] {
  background: white;
  border: 4px solid white;
  color: #000;
}

.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper .gform_footer input.button:hover,
.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper .gform_footer input[type=submit]:hover,
.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper .gform_page_footer input.button:hover,
.rpb-customer-reviews .customer-reviews-form .gform_legacy_markup_wrapper .gform_page_footer input[type=submit]:hover {
  background: #262626;
  border: 4px solid #262626;
  color: white;
}

.rpb-team .team-member-single {
  transition: all 350ms;
}

@media (min-width: 992px) {
  .rpb-team .team-member-single {
    padding: 20px;
  }
}

.rpb-team .team-member-single:hover {
  cursor: pointer;
  background: white;
}

.rpb-team .team-member-single:hover .team-modal-button {
  background: #000;
  color: white;
}

.rpb-team .team-member-single .team-member-image {
  padding-top: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 50%;
}

@media (min-width: 992px) {
  .rpb-team .team-member-single .team-member-image {
    padding-top: 90%;
    max-width: 90%;
  }
}

.rpb-team .team-member-single .team-modal-button {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background: white;
  color: #000;
  transition-duration: 350ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rpb-team .team-member-single .team-modal-button i,
.rpb-team .team-member-single .team-modal-button svg {
  font-size: 26px;
}

.rpb-team .team-member-single .team-modal-button:hover {
  background: #000;
  color: white;
}

.rpb-team .team-member-single .team-member-content p {
  margin-bottom: 0.5rem;
}

.rpb-team .team-member-single .team-member-content p:last-of-type {
  margin-bottom: 0;
}

.team-bio-modal .modal-content {
  background-color: transparent;
}

.team-bio-modal .modal-body {
  display: flex;
}

.team-bio-modal .modal-body .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.team-bio-modal .modal-body .bio-modal-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  flex-basis: 25%;
}

.team-bio-modal .modal-body .bio-modal-content {
  background-color: white;
}

@media (min-width: 992px) {
  .team-bio-modal .modal-body .bio-modal-content {
    flex-basis: 75%;
  }
}

.team-bio-modal .modal-body .bio-modal-content .bio-modal-job {
  color: #555;
}

.team-bio-modal .modal-body .bio-modal-content .bio-modal-bio {
  overflow-y: scroll;
  max-height: 200px;
}

@media (min-width: 992px) {
  .team-bio-modal .modal-body .bio-modal-content .bio-modal-bio {
    max-height: 300px;
  }
}

.rpb-blog-feed.feed-style-1 .blog-page-postcontent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rpb-blog-feed.feed-style-1 .blog-page-postcontent .circle-link {
  position: absolute;
  top: 0;
  right: 0;
}

.rpb-blog-feed.feed-style-1 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow--prev {
  left: 0;
}

.rpb-blog-feed.feed-style-1 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow--next {
  right: 0;
}

.rpb-blog-feed.feed-style-1 .blog-feed-slider .splide__slider .splide__track {
  margin-left: 3rem;
  margin-right: 3rem;
  padding-top: 30px;
}

.rpb-blog-feed.feed-style-1 .blog-feed-slider .splide__slider .splide__pagination {
  bottom: -2.5rem;
}

.rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

@media (min-width: 992px) {
  .rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows {
    position: absolute;
    display: block;
    top: -100px;
    right: 0;
    margin-top: 0;
  }
}

.rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow {
  display: inline-block;
  position: relative;
  transform: none;
  height: 50px;
  width: 50px;
  border: 1 px solid rgba(0, 0, 0, 0.25);
  transition: all 350ms ease-in-out;
}

.rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow:hover {
  background: rgba(0, 0, 0, 0.05);
}

.rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow i,
.rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow svg {
  color: #191919;
  display: flex;
  margin: 0 auto;
  opacity: 0.5;
}

.rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow--prev {
  left: auto;
  margin-right: 5px;
}

@media (min-width: 992px) {
  .rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow--prev {
    margin-right: 0;
  }
}

.rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow--next {
  right: auto;
  margin-left: 5px;
}

@media (min-width: 992px) {
  .rpb-blog-feed.feed-style-2 .blog-feed-slider .splide__slider .rpb-slider-arrows .splide__arrow--next {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .rpb-blog-feed.feed-style-2 .blog-post-content-row {
    min-height: 325px;
  }
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-featured-image {
  height: 100%;
  width: 100%;
  min-height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  position: relative;
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-featured-image .blog-date {
  color: #191919;
  font-size: 13px;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 10px;
  background: #fff;
  max-width: 120px;
  border-radius: 0 4px 0 0;
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-featured-image .blog-date i {
  text-shadow: 0 0 20px #dda01e;
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content {
  position: relative;
  display: flex;
  flex-direction: column;
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .post-content-inner {
  background: white;
  padding: 20px;
}

@media (min-width: 992px) {
  .rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .post-content-inner {
    background: transparent;
    padding: 0 1.5rem 0 0.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .blog-title {
  font-weight: bold;
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .blog-excerpt {
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  opacity: 0.8;
}

@media (min-width: 992px) {
  .rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .blog-excerpt {
    margin-top: 0;
  }
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .blog-excerpt a {
  color: inherit;
  font-weight: 400;
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .blog-excerpt a:hover {
  color: inherit;
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .blog-cats {
  margin-top: auto;
}

.rpb-blog-feed.feed-style-2 .blog-post-content-row .post-content .blog-cats .blog-cat {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 11px;
  display: inline-block;
  margin-bottom: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 2px 10px;
  border-radius: 50px;
  transition: all 0.25s;
  background: rgba(0, 0, 0, 0.025);
}

.sidebar .widget {
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
}

.sidebar .widget:last-child {
  border-bottom: 0px;
}

.sidebar .widget h3,
.sidebar .widget .h3 {
  padding: 15px;
}

@media (max-width: 991.98px) {
  .sidebar .widget .primary-btn {
    font-size: 13px;
    line-height: 1.5em;
  }
}

.sidebar .widget .gform_wrapper input,
.sidebar .widget .gform_wrapper textarea,
.sidebar .widget .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
  background: #efefef;
  height: 30px;
  font-size: 13px !important;
  color: #888;
  border: solid 1px #ccc;
}

.sidebar .widget .gform_wrapper input[type=submit] {
  background: #000 !important;
  background: #00aeef;
  color: #fff;
  height: auto !important;
  border: 0px !important;
}

.sidebar .widget .boxed-header {
  padding: 15px;
  background: #222;
  color: #fff !important;
}

.sidebar .widget li.gfield {
  margin-top: 5px;
}

.sidebar .widget li.gfield ::placeholder {
  color: #888;
}

.sidebar .widget li.gfield input {
  border: solid 1px #ccc !important;
}

.sidebar .widget li#field_2_2,
.sidebar .widget li#field_2_1 {
  margin-top: 0px;
}

.sidebar .widget .menu {
  padding-left: 0px;
}

.sidebar .widget .textwidget {
  padding: 15px;
}

.contact .widget.gform_widget-2 {
  display: none !important;
}

.sidebar {
  margin-bottom: 0px;
}

.sidebar .menu {
  margin-bottom: 0;
}

.sidebar .menu li {
  list-style: none;
  border-bottom: solid 1px #efefef;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0.75rem 0;
}

.sidebar .menu li:last-child {
  border-bottom: 0;
}

.sidebar .widget_search {
  background: transparent;
  box-shadow: none;
}

.sidebar .widget_search .search-form {
  margin: 0;
}

.sidebar .widget_search .search-form label {
  padding: 0;
}

.footer-topper {
  height: 17rem;
}

@media (min-width: 768px) {
  .footer-topper {
    height: 34rem;
  }
}

.site-footer .super-small {
  font-size: 13px;
  line-height: 13px;
}

.site-footer.footer-style-standard .footer-col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.site-footer.footer-style-standard .footer-col ul li {
  padding-bottom: 7.5px;
}

.site-footer.footer-style-standard .footer-col ul li:last-of-type {
  padding-bottom: 0;
}

.site-footer.footer-style-standard .footer-col ul li a {
  text-decoration: none;
  font-weight: normal;
}

.site-footer.footer-style-standard .footer-col .footer-info i,
.site-footer.footer-style-standard .footer-col .footer-info svg {
  min-width: 30px;
}

.site-footer.footer-style-standard .footer-col .gform_wrapper .gform_description {
  margin-bottom: 1rem;
  display: block;
}

@media (min-width: 992px) {
  .site-footer.footer-style-minimal .footer-col .widget {
    display: inline;
    float: right;
  }
}

@media (min-width: 992px) {
  .site-footer.footer-style-minimal .footer-col .widget div {
    display: inline;
  }
}

.site-footer.footer-style-minimal .footer-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

@media (min-width: 992px) {
  .site-footer.footer-style-minimal .footer-col ul {
    display: inline;
    text-align: left;
  }
}

.site-footer.footer-style-minimal .footer-col ul li {
  margin-bottom: 1rem;
}

.site-footer.footer-style-minimal .footer-col ul li:last-of-type {
  margin-right: 0;
  margin-bottom: 0;
}

.site-footer.footer-style-minimal .footer-col ul li a {
  text-decoration: none;
  font-weight: normal;
}

@media (min-width: 992px) {
  .site-footer.footer-style-minimal .footer-col ul li {
    display: inline;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

.footer-brands {
  border-top: 1px solid #f5f5f5;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 30px;
}

.footer-brands img {
  max-width: 100%;
}

.footer-social-link:hover {
  opacity: 0.8;
}

#copyright {
  font-size: 18px;
  line-height: 25px;
}

.page-container .page-main {
  background: #f5f5f5;
  padding: 25px;
  padding-top: 60px;
  padding-bottom: 60px;
  line-height: 1.9;
}

.page-container .page-sidebar {
  background: #cfcfcf;
  padding-top: 60px;
  padding-bottom: 60px;
}

.page-container .page-sidebar .sidebar-carrier {
  margin-top: 40px;
  text-align: center;
}

.page-container .page-sidebar .sidebar-carrier img {
  max-width: 100%;
}

.sidebar-contact .sb-contact-title {
  background: #222;
  color: white;
  text-align: center;
  padding: 20px 5px;
  font-weight: 700;
  font-size: 24px;
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
  text-transform: uppercase;
}

.sidebar-contact .sb-contact-form {
  background: transparent;
  padding: 20px;
  background: white;
  border-left: 3px solid #828282;
  border-right: 3px solid #828282;
  border-bottom: 3px solid #828282;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.sidebar-contact .sb-contact-form label {
  display: none !important;
}

.sidebar-contact .sb-contact-form input,
.sidebar-contact .sb-contact-form textarea {
  font-weight: 700;
  font-size: 16px;
  color: #1A1616;
  width: 100%;
  background: transparent;
  text-align: left;
  padding: 0.75em 0.65em 0.75em 0;
  border-right: 0;
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #4f4f4f;
  margin: 0 0 0 0;
  transition: all 0.25s ease-in-out;
  max-height: 175px;
  margin-bottom: 20px;
}

.sidebar-contact .sb-contact-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  font-size: 16px;
  font-family: inherit;
  padding: 0.75em 0.65em 0.75em 0;
  letter-spacing: normal;
}

.sidebar-contact .sb-contact-form input[type=submit] {
  background: #222;
  color: white;
  border: none;
  padding: 15px 25px;
  border-radius: 3px;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;
}

.sidebar-contact .sb-contact-form input[type=submit]:hover {
  background: #2f2f2f;
  cursor: pointer;
}

.error-page {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: white;
  min-height: 750px;
}

.error-page .error-title {
  font-size: 150px;
  font-weight: bold;
}

.error-page .error-title img {
  max-height: 175px;
}

.error-page .error-subtitle {
  font-weight: normal;
}

.error-page .error-subtitle a {
  text-decoration: underline;
}

.error-page .error-subtitle a:hover {
  color: #333333;
}

.single-search-result {
  background: white;
}

.single-search-result p:last-of-type {
  margin-bottom: 0;
}

.page-header.default-banner {
  padding-top: 25vw;
  padding-bottom: 15px;
  overflow: hidden;
  position: relative;
}

@media (min-width: 992px) {
  .page-header.default-banner {
    padding-top: 6vw;
    padding-bottom: 15px;
  }
}

.page-header.default-banner h1,
.page-header.default-banner .h1,
.page-header.default-banner h2,
.page-header.default-banner .h2 {
  position: relative;
  z-index: 2;
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
  text-align: center;
}

.page-header.default-banner h1:before,
.page-header.default-banner .h1:before,
.page-header.default-banner h2:before,
.page-header.default-banner .h2:before {
  display: none;
}

@media (min-width: 992px) {
  .page-header.default-banner h1,
  .page-header.default-banner .h1,
  .page-header.default-banner h2,
  .page-header.default-banner .h2 {
    text-align: left;
  }
}

.page-title h1,
.page-title .h1,
.page-title h2,
.page-title .h2 {
  margin-bottom: 0px !important;
  position: relative;
  z-index: 2;
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.page-title h1:before,
.page-title .h1:before,
.page-title h2:before,
.page-title .h2:before {
  display: none !important;
}

@media (min-width: 992px) {
  .negative-widget {
    margin-top: -108px;
  }
}

.page-header.center-banner {
  height: 33vh;
  font-size: 24px;
  color: #333;
}

.page-header.center-banner h1,
.page-header.center-banner .h1 {
  font-size: 1.5em;
  color: #222;
}

.page-header.center-banner h1:after,
.page-header.center-banner .h1:after {
  content: "";
  width: 200px;
  height: 2px;
  background: #9db09b;
  display: block;
  margin: 20px auto 0px;
}

@media (min-width: 768px) {
  .page-header.center-banner h1,
  .page-header.center-banner .h1 {
    font-size: 2.5em;
  }
}

@media (min-width: 1200px) {
  .flex-nav {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 575.98px) {
  .content-page-title {
    font-size: 18px !important;
  }
}

.ecommerce-header {
  padding-top: 6vw;
  padding-bottom: 15px;
}

.blog-post-header {
  min-height: 300px;
}

.single-post .blog-post-content .blog-featured-image {
  margin-bottom: 1rem;
  border-radius: 6px;
}

@media (min-width: 992px) {
  .single-post .blog-post-content .blog-featured-image {
    float: right;
    max-width: 45%;
    margin-left: 1rem;
  }
}

.date-block {
  width: 100px;
  margin-top: -30px;
  box-shadow: 0px 8px 3px -5px rgba(0, 0, 0, 0.25);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.date-block:before {
  content: "";
  height: 15px;
  width: 15px;
  position: absolute;
  background: #051b11;
  left: -15px;
  top: 0px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.date-block:after {
  content: "";
  height: 15px;
  width: 15px;
  position: absolute;
  background: #051b11;
  right: -14px;
  top: 0px;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

.circle-link {
  border-radius: 100%;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  border: double 5px;
  height: 40px;
  width: 40px;
  opacity: 0.5;
}

.blog-page-postcontent:hover {
  cursor: pointer;
}

body#tinymce {
  margin: 12px !important;
}

.hero-style-1 .hero-main-col .hero-headline {
  font-size: 24px;
  text-shadow: 1px 1px 0 #303030;
  font-weight: 700;
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
}

@media (min-width: 992px) {
  .hero-style-1 .hero-main-col .hero-headline {
    font-size: 55px;
  }
}

.hero-style-1 .hero-main-col .hero-subline {
  font-size: 18px;
  text-shadow: 1px 1px 0 #303030;
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
}

@media (min-width: 992px) {
  .hero-style-1 .hero-main-col .hero-subline {
    font-size: 36px;
  }
}

.hero-style-1 .hero-main-col .hero-button {
  margin-top: 45px;
}

.coupon-wrapper .coupon-col {
  border: 0;
  box-shadow: 0;
  border-radius: 0;
  overflow: hidden;
}

.coupon-wrapper .coupon-col .coupon-outer {
  padding: 1rem;
  border: 2px dashed #000;
}

.coupon-wrapper .coupon-col .coupon-outer * {
  transition: all 0.3s ease-in;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner {
  padding: 0;
  background: #f5f5f5;
  border-radius: 3px;
  box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.1);
  top: 0;
  position: relative;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-header {
  background: #1A1616;
  color: #fff;
  margin-bottom: 1.5rem;
  padding: 1rem 0.5rem calc(0.5rem + 15px);
  border-radius: 3px 3px 0 0;
  position: relative;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-header .coupon-title {
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 32px;
}

@media (max-width: 575.98px) {
  .coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-header .coupon-title {
    font-size: 24px;
  }
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-middle {
  padding: 0.25rem 1rem;
  color: #000;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-middle .coupon-subtitle {
  font-weight: 700;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-middle .coupon-text {
  padding: 1rem 2rem;
  font-size: 16px;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-middle .coupon-text p {
  margin-bottom: 0;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-footer {
  padding: 0.25rem 2rem;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-footer .counpon-fine-print {
  color: #b5b5b5;
  font-size: 12px;
  margin: 1rem auto;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-footer .coupon-expiration {
  font-size: 16px;
  font-weight: bold;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-print {
  background: #1A1616;
  color: white;
  padding: 5px 15px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.coupon-wrapper .coupon-col .coupon-outer .coupon-inner .coupon-print:hover {
  cursor: pointer;
}

.coupon-wrapper .coupon-col .coupon-outer:hover .coupon-inner {
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.8);
  top: -5px;
}

@page {
  margin: 3.5cm;
}

@media print {
  .coupon-outer {
    cursor: pointer !important;
    padding: 1rem;
    border: 2px dashed #000;
    max-width: 50%;
    margin: 25% auto;
    display: block;
    left: 0;
    right: 0;
    position: absolute;
  }

  .coupon-outer * {
    transition: all 0.3s ease-in;
  }

  .coupon-outer .coupon-inner {
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 3px;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.1);
    top: 0;
  }

  .coupon-outer .coupon-inner:after {
    content: "✂";
    position: absolute;
    top: -10px;
    left: -10px;
    border-radius: 100%;
    background: #9db09b;
    height: 30px;
    width: 30px;
    vertical-align: middle;
    line-height: 30px;
    color: #fff;
    transition: all 0.3s ease-in;
  }

  .coupon-outer .coupon-inner .coupon-header .coupon-title {
    font-family: "Walden", "Tinos", Georgia, Arial, serif;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 32px;
  }

  .coupon-outer .coupon-inner .coupon-middle .coupon-subtitle {
    font-weight: 700;
  }

  .coupon-outer .coupon-inner .coupon-footer .counpon-fine-print {
    color: #cfcfcf;
    font-size: 11px;
    margin: 0;
  }

  .coupon-outer:hover .coupon-inner {
    box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.8);
    top: -5px;
  }
}

.Coupon {
  min-height: 250px;
  border: 4px dashed #000;
}

.ticket {
  height: 100%;
  right: 0px;
  top: 0px;
}

.ball {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  z-index: 1;
  background-color: white;
  width: 14px;
  height: 30px;
  margin-bottom: 5px;
}

.code {
  width: fit-content;
  background-color: #E81100;
  color: white;
  border: #E81100 3px solid;
}

.code:hover {
  background-color: transparent;
  color: #E81100;
}

.zip-code-search-box p:last-of-type {
  margin-bottom: 1rem;
}

.zip-code-search-box .zcs-content .zcs-content-step {
  margin-bottom: 1rem;
}

.zip-code-search-box .zcs-content .zcs-hidden-step {
  display: none;
}

.zip-code-search-box .zcs-content .zcs-location-row {
  display: none;
}

.zip-code-search-box .zip-code-form input[type=text],
.zip-code-search-box .zip-code-form input[type=number] {
  height: 50px;
}

.zip-code-search-box .zip-code-form .zipcode-error {
  color: red;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .mso-map-container {
    padding: 0 !important;
  }
}

.mso-map-container .mso-map {
  position: relative;
  transition: all 150ms;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .mso-map-container .mso-map {
    border-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.mso-map-container .mso-map .gm-style-pbc {
  opacity: 0.015 !important;
}

.mso-map-container .mso-map .mso-iframe {
  min-height: 300px;
  width: 100%;
  order: 1;
}

@media (min-width: 1200px) {
  .mso-map-container .mso-map .mso-iframe {
    border-radius: 20px;
    order: 2;
    min-height: 750px;
  }
}

.mso-map-container .scrollbar-primary::-webkit-scrollbar {
  width: 8px;
  background-color: #888;
}

.mso-map-container .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #000;
}

.mso-map-container .fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  border-radius: 0 !important;
  padding: 0;
  margin: 0;
}

.mso-map-container .fullscreen .mso-iframe {
  height: 100%;
}

.mso-map-container .fullscreen .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list {
  max-height: 100%;
}

.mso-map-container .store-locator-search-box .mso-searchbar {
  width: 100%;
  border-radius: 30px;
  background: white;
  border: 0;
}

.mso-map-container .store-locator-fullscreen-button {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: inherit;
  z-index: 10;
}

.mso-map-container .store-locator-fullscreen-button .msocompress {
  display: none;
}

@media (min-width: 992px) {
  .mso-map-container .store-locator-fullscreen-button {
    display: block;
  }
}

.mso-map-container .store-locator-fullscreen-button .store-locator-fullscreen-in {
  background: rgba(0, 0, 0, 0.12);
  -webkit-backdrop-filter: saturate(180%) blur(8px);
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  backdrop-filter: saturate(180%) blur(8px);
  padding-bottom: 10px !important;
  transition: all 150ms ease;
}

.mso-map-container .store-locator-fullscreen-button .store-locator-fullscreen-in:hover {
  background: #000;
}

.mso-map-container .store-locator-fullscreen-button .store-locator-fullscreen-in:hover a {
  color: white;
}

.mso-map-container .store-locator-fullscreen-button .store-locator-fullscreen-in a {
  color: #000;
  font-size: 25px;
}

.mso-map-container .store-locator-sidebar-container {
  order: 2;
}

.mso-map-container .store-locator-sidebar-container .google_logo {
  width: 66px;
  height: 26px;
  position: absolute;
  bottom: 2px;
  left: 5px;
  display: none;
  z-index: 99;
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-sidebar-container {
    width: 400px;
    order: 1;
    position: absolute;
    top: 0;
    bottom: 25px;
    left: 0;
    z-index: 10;
    padding-left: inherit;
  }

  .mso-map-container .store-locator-sidebar-container .google_logo {
    display: block;
  }
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar {
  background: rgba(0, 0, 0, 0.12);
  -webkit-backdrop-filter: saturate(180%) blur(8px);
  backdrop-filter: saturate(180%) blur(8px);
}

@media (max-width: 1199.98px) {
  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar {
    padding-top: 45px !important;
  }
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar {
    border-radius: 20px;
    border-top-right-radius: 0;
  }
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-searchbar {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-searchbar {
    flex-wrap: nowrap;
  }
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-searchbar .store-locator-search-box {
  flex: 0 0 100%;
  display: flex;
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-searchbar .store-locator-search-box {
    flex: 1 1 100%;
  }
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-searchbar .mso-search-filter {
  display: flex;
  flex: 0 1 auto;
}

@media (max-width: 1199.98px) {
  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-searchbar .mso-search-filter {
    flex: 0 0 100%;
  }
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar #showAllLoc {
  font-size: 14px;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .all-locations-div {
  margin-bottom: -10px;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-sidebar-address {
  font-size: 16px;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-sidebar-more {
  font-size: 13px;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-sidebar-full-details {
  font-size: 16px;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list {
  padding: 0;
  max-height: 350px;
  overflow-y: scroll;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list::-webkit-scrollbar-thumb {
  background: #000;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list::-webkit-scrollbar-track {
  background: #888;
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list {
  scrollbar-face-color: #000;
  scrollbar-track-color: #888;
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list {
    max-height: 625px;
    direction: rtl;
  }

  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list::-webkit-scrollbar-thumb {
    background: #000;
  }

  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list::-webkit-scrollbar-track {
    background: #888;
  }

  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list {
    scrollbar-face-color: #000;
    scrollbar-track-color: #888;
  }
}

.mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list .loc_item .mso-full-details-button:hover {
  color: #000 !important;
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-sidebar-container .store-locator-sidebar .mso-scroll-list .loc_item {
    direction: ltr;
  }
}

.mso-map-container .store-locator-filter-container {
  opacity: 0;
  width: 100%;
  visibility: hidden;
  transition: all 500ms ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  padding-left: inherit;
  background: #000;
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-filter-container {
    width: 0;
    bottom: 25px;
    background: #1459ba;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, black 49%);
    border-radius: 20px;
    border-top-right-radius: 0;
  }
}

.mso-map-container .store-locator-filter-container .mso-filter-left {
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  visibility: hidden;
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-filter-container .mso-filter-left {
    width: 50%;
    visibility: visible;
  }
}

.mso-map-container .store-locator-filter-container .mso-filter-left:hover {
  cursor: pointer;
}

.mso-map-container .store-locator-filter-container .mso-filter-left .mso-filter-back {
  padding-top: 100px !important;
  visibility: hidden;
}

.mso-map-container .store-locator-filter-container .mso-filter-left .mso-filter-back .mso-filter-back-icon {
  font-size: 90px;
}

.mso-map-container .store-locator-filter-container .mso-filter-left .mso-filter-back .mso-filter-back-icon i,
.mso-map-container .store-locator-filter-container .mso-filter-left .mso-filter-back .mso-filter-back-icon svg {
  opacity: 0.85;
}

.mso-map-container .store-locator-filter-container .mso-filter-left .mso-filter-back .mso-filter-back-text {
  font-size: 18px;
  opacity: 0.85;
}

.mso-map-container .store-locator-filter-container .mso-filter-right {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

@media (min-width: 1200px) {
  .mso-map-container .store-locator-filter-container .mso-filter-right {
    width: 50%;
  }
}

.mso-map-container .store-locator-filter-container .store-locator-filter .mso-filter {
  background: rgba(255, 255, 255, 0.62);
  -webkit-backdrop-filter: saturate(180%) blur(8px);
  border-radius: 20px;
  backdrop-filter: saturate(180%) blur(8px);
  color: #1A1616;
}

.mso-map-container .store-locator-filter-container .store-locator-filter .mso-filter label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
}

.mso-map-container .store-locator-filter-container .store-locator-filter .mso-filter input[type=text],
.mso-map-container .store-locator-filter-container .store-locator-filter .mso-filter select {
  border-radius: 15px;
}

.pac-container {
  color: #c55a5a;
  border-radius: 20px;
  border: none;
  background-color: white;
  padding: 0.75rem;
  margin-top: 5px;
  z-index: 99999;
}

textarea:focus,
input:focus {
  outline: none;
}

/*
class for each line of the result
*/

.pac-item {
  font-size: 12px;
  color: #002a77;
  font-family: Arial, Helvetica, sans-serif;
}

.location_name {
  color: #1f67cd;
  font-weight: 600;
  font-size: 1.477rem;
}

.tab.collapsed .location_name {
  font-weight: 400;
  color: black;
}

.row_container {
  padding-right: 8vw;
}

@media (max-width: 991.98px) {
  .row_container {
    padding-right: 0;
  }
}

.left_container {
  margin-right: 1rem;
}

@media (max-width: 991.98px) {
  .left_container {
    margin-right: 0;
  }
}

.search_container {
  padding-left: 8vw;
}

@media (max-width: 991.98px) {
  .search_container {
    padding-left: 0;
  }
}

.search_container #searchTextField {
  border-radius: 18px;
  border: none;
  background-color: #f5f5f5;
  padding: 0.75rem 1.5rem;
  text-align: center;
  transition: border 2s ease-in-out;
}

.search_container #searchTextField:focus {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.nothing_container {
  color: #1359ba;
}

@media (max-width: 1199.98px) {
  .nothing_container {
    padding-left: 0;
  }
}

.all_loc_container {
  text-align: center;
}

.all_loc_container button {
  width: fit-content;
}

.list_container ul {
  margin: 0;
}

.list_container ul .loc_item {
  color: black;
  border: none;
  border-radius: 10px;
  width: auto;
  transition: all 150ms ease-in-out;
}

.list_container ul .loc_item:hover {
  background: #000;
  background: linear-gradient(145deg, #000 0%, #262626 100%);
  color: white !important;
}

.list_container ul .loc_item:hover .h5,
.list_container ul .loc_item:hover .mso-sidebar-more,
.list_container ul .loc_item:hover a {
  color: white !important;
}

.list_container ul .loc_item:hover .mso-full-details-button:hover {
  color: #1A1616 !important;
}

.my_location {
  text-align: center;
  padding-left: 4px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
}

.list-btn:hover {
  background-color: black;
  color: white;
}

.shade {
  content: "";
  display: block;
  position: absolute;
  width: 370px;
  height: 72px;
  background-image: linear-gradient(to top, rgba(245, 245, 245, 0), #f5f5f5);
  pointer-events: none;
}

.gm-style iframe + div {
  border: none !important;
}

.gm-style-iw + div {
  display: none;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  color: white;
  background-color: #000;
  transition-duration: 30ms;
  -webkit-transition-duration: 30ms;
}

.btn-circle:hover {
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857;
  background-color: #1f67cd;
  color: white;
  transform: scale(1.2);
  box-shadow: -1px -1px 13px 0px rgba(0, 0, 0, 0.3);
}

.map_btn {
  background: none padding-box white;
  display: table-cell;
  border: 0px;
  margin: 0px;
  padding: 0px 17px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  direction: ltr;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: #565656;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 66px;
  margin: 10px;
}

.map_btn:hover {
  background: none padding-box #ebebeb;
  color: black;
}

.list-group-item.active {
  background-color: #000 !important;
  border-color: #000 !important;
  color: white !important;
}

.list-group-item.active .h5,
.list-group-item.active .mso-sidebar-more,
.list-group-item.active a {
  color: white !important;
}

@media (min-width: 1200px) {
  .move {
    left: 56% !important;
  }
}

.location {
  position: relative;
}

.single-location .breadcrumb {
  font-size: 14px;
}

.single-location .location-cannabis-license {
  font-size: 12px;
  color: #555;
}

.single-location .single-location-info-content {
  word-wrap: break-word;
}

.single-location .single-location-info-content p {
  margin-bottom: 2rem;
}

.single-location .single-location-info-content p i,
.single-location .single-location-info-content p svg {
  color: #000;
}

.single-location .single-location-info-content p .content-icon {
  padding-bottom: 1rem;
  padding-right: 10px;
}

.single-location .single-location-info-content p.info-address a {
  font-weight: 400;
  color: inherit;
}

.single-location .single-location-info-content p.info-address a:hover {
  text-decoration: underline;
}

.single-location .single-location-info-content p.info-directions:hover {
  text-decoration: underline;
  color: #000;
}

.single-location .single-location-info-content p.info-phone a[href^=tel] {
  font-weight: 400;
  color: #000;
}

.single-location .single-location-info-content p.info-phone a[href^=tel]:hover {
  text-decoration: underline;
}

.single-location .single-location-info-content p.info-email a[href^=mailto] {
  font-weight: 400;
  color: #000;
}

.single-location .single-location-info-content p.info-email a[href^=mailto]:hover {
  text-decoration: underline;
}

.single-location .single-location-info-content p:last-of-type {
  margin-bottom: 0;
}

.single-location .location-hr {
  width: 100%;
  height: 1px;
  background: #dbd4d4;
  content: "";
  margin: 2rem 0;
  box-sizing: content-box;
}

@media (min-width: 992px) {
  .single-location .location-hours .location-hours-table {
    max-width: 75%;
  }
}

@media (min-width: 1400px) {
  .single-location .location-hours .location-hours-table {
    max-width: 50%;
  }
}

.single-location .location-hours .location-hours-table .location-hours-row {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.single-location .single-location-general-content p {
  margin-bottom: 1rem;
}

.single-location .single-location-general-content p:last-of-type {
  margin-bottom: 0;
}

.single-location .location-social-media .location-social-single {
  padding-right: 25px;
  width: auto;
}

.single-location .location-social-media .location-social-single a {
  color: #000;
  font-weight: 400;
}

.single-location .location-social-media .location-social-single a i,
.single-location .location-social-media .location-social-single a svg {
  font-size: 150%;
}

.single-location .location-social-media .location-social-single a:hover {
  color: #1A1616;
}

.single-location .location-social-media .location-social-single .social-text {
  display: none;
}

.single-location .location-reviews-container {
  background: white;
}

.single-location .location-reviews-container .location-reviews .location-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow {
  background: #000;
}

.single-location .location-reviews-container .location-reviews .location-reviews-slider .splide__slider .rpb-slider-arrows .splide__arrow svg {
  color: white;
}

.single-location .location-reviews-container .location-reviews .location-reviews-slider .location-single-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.single-location .location-reviews-container .location-reviews .location-reviews-slider .location-single-review .location-single-review-icon {
  font-size: 50px;
  margin-bottom: 1rem;
  opacity: 0.15;
}

.single-location .location-reviews-container .location-reviews .location-reviews-slider .location-single-review .location-single-review-content {
  font-style: italic;
  margin-bottom: 1rem;
  padding-left: 60px;
  padding-right: 60px;
}

.single-location .location-reviews-container .location-reviews .location-reviews-slider .location-single-review .location-single-review-name span {
  padding: 10px 30px;
  border-radius: 999px;
  text-transform: uppercase;
  color: white;
}

.single-location .location-photos-wrapper {
  height: 350px;
}

@media (min-width: 992px) {
  .single-location .location-photos-wrapper {
    height: 40%;
    min-height: 350px;
  }
}

.single-location .single-location-map {
  height: 350px;
}

@media (min-width: 992px) {
  .single-location .single-location-map {
    height: 60%;
    min-height: 350px;
  }
}

.single-location .single-location-map iframe {
  width: 100%;
  height: 350px;
}

@media (min-width: 992px) {
  .single-location .single-location-map iframe {
    height: 100%;
    min-height: 350px;
  }
}

.single-location .location-menu {
  position: relative;
  z-index: 9999;
}

.single-location-alert {
  background: #9db09b;
  color: #000;
  margin-bottom: 0;
  border: 0;
  color: white;
  border-radius: 0;
}

.single-location-alert p:last-of-type {
  margin-bottom: 0;
}

.single-location-alert .btn-close {
  color: white;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  padding: 0;
  height: 100%;
}

.wc-nav-icon {
  padding-top: 5px;
}

.cart-contents:before {
  font-family: WooCommerce;
  content: "";
  font-size: 28px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  padding-right: 5px;
  vertical-align: bottom;
}

.cart-contents:hover {
  text-decoration: none;
}

.cart-contents-count {
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  padding: 1px 6px;
  line-height: 1;
  vertical-align: top;
}

.woocommerce a.remove {
  color: #1A1616 !important;
}

.woocommerce a.remove:hover {
  background: #1A1616 !important;
}

#add_payment_method .cart-collaterals .cart_totals tr td,
#add_payment_method .cart-collaterals .cart_totals tr th,
.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th,
.woocommerce-checkout .cart-collaterals .cart_totals tr td,
.woocommerce-checkout .cart-collaterals .cart_totals tr th {
  border-top: 0;
}

.woocommerce-notices-wrapper .woocommerce-message,
.woocommerce-notices-wrapper .woocommerce-info {
  border: 1px solid white;
  background: white;
  text-align: center;
  display: block;
}

.woocommerce-notices-wrapper .woocommerce-message:before,
.woocommerce-notices-wrapper .woocommerce-info:before {
  content: "";
  color: #000;
  position: relative;
  top: auto;
  left: auto;
}

@media (min-width: 992px) {
  .woocommerce-notices-wrapper .woocommerce-message,
  .woocommerce-notices-wrapper .woocommerce-info {
    text-align: left;
    line-height: 1.8;
  }
}

.woocommerce-notices-wrapper .woocommerce-message .button,
.woocommerce-notices-wrapper .woocommerce-info .button {
  margin: 0;
  width: 100%;
  margin-top: 1rem;
  line-height: 1;
}

@media (min-width: 992px) {
  .woocommerce-notices-wrapper .woocommerce-message .button,
  .woocommerce-notices-wrapper .woocommerce-info .button {
    width: auto;
    margin-top: 0;
  }
}

#add_payment_method #payment ul.payment_methods li,
.woocommerce-cart #payment ul.payment_methods li,
.woocommerce-checkout #payment ul.payment_methods li {
  line-height: 1.2;
}

.woocommerce ul.products li.product .price {
  font-size: 1rem;
}

.woocommerce a.button,
.woocommerce .widget_price_filter .price_slider_amount .button,
.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  display: block;
  padding: 10px 15px;
  border-radius: 30px;
  background: #000;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  float: none;
  margin-top: 1rem;
}

.woocommerce a.button:hover,
.woocommerce .widget_price_filter .price_slider_amount .button:hover,
.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover {
  text-decoration: none;
  cursor: pointer;
  background: #1a1a1a;
  color: white;
}

.woocommerce .checkout-button {
  padding: 20px 15px !important;
}

.woocommerce .widget_price_filter .price_slider_amount {
  text-align: left;
  line-height: 1;
  font-size: 1rem;
}

.basebuild-breadcrumbs {
  color: #1A1616;
  font-weight: 600;
  font-size: 1rem;
}

.basebuild-breadcrumbs a {
  font-weight: 600;
  color: #888;
  font-size: 1rem;
}

.basebuild-breadcrumbs .bc-separator {
  color: #888;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 0.75rem;
}

.star-rating {
  margin-bottom: 1rem;
}

.star-rating span:before,
.star-rating::before,
p.stars a:hover:after,
p.stars a:after {
  color: #000 !important;
}

.woocommerce-review-link {
  visibility: hidden;
}

.woocommerce-review-link .count {
  visibility: initial;
}

.woocommerce-review-link .count:before {
  content: "(";
}

.woocommerce-review-link .count:after {
  content: ")";
}

.woocommerce .quantity {
  border-radius: 30px;
  padding: 0.5rem 0;
  text-align: center;
  background: transparent;
  line-height: 1;
  border: 2px solid #000;
}

.woocommerce .quantity input[type=button] {
  background: transparent;
  border: 0;
  padding: 0;
}

.woocommerce .quantity input[type=number] {
  background: transparent;
  border: 0;
  padding-left: 1rem;
  width: 3rem;
}

.woocommerce nav.woocommerce-pagination ul {
  border: 0;
}

.woocommerce nav.woocommerce-pagination ul li {
  background: white;
  border-right-color: #f5f5f5;
}

.woocommerce nav.woocommerce-pagination ul li span.current {
  background: #000;
  color: white;
}

.woocommerce-products-header {
  background-size: cover;
  background-position: center center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .woocommerce-products-header {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.woocommerce-products-header .products-header-title {
  font-size: 2.5rem;
  line-height: 1.2;
  color: white;
  font-family: "Walden", "Tinos", Georgia, Arial, serif;
  padding: 0;
  margin: 0;
}

.woocommerce-shop .woocommerce-notices-wrapper {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

@media (min-width: 768px) {
  .woocommerce-shop .woocommerce-notices-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .woocommerce-shop .woocommerce-notices-wrapper {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media screen and (min-width: 1800px) {
  .woocommerce-shop .woocommerce-notices-wrapper {
    max-width: unset;
    padding-left: 190px;
    padding-right: 190px;
  }
}

.basebuild-order-results {
  margin-bottom: 20px;
}

.basebuild-shop-main {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #f5f5f5;
}

.basebuild-shop-main .basebuild-shop-filter .basebuild-filter-title {
  background: #000;
  padding: 15px;
  color: white;
}

.basebuild-shop-main .basebuild-shop-filter .basebuild-filter-title p,
.basebuild-shop-main .basebuild-shop-filter .basebuild-filter-title .h3 {
  padding: 0;
  margin: 0;
  color: white;
}

.basebuild-shop-main .basebuild-shop-filter .basebuild-shop-filter-in {
  background: white;
  display: none;
}

@media (min-width: 1200px) {
  .basebuild-shop-main .basebuild-shop-filter .basebuild-shop-filter-in {
    background: transparent;
    display: block !important;
  }
}

.basebuild-shop-main .basebuild-shop-filter .basebuild-shop-filter-in ul {
  display: block;
  padding: 0;
  list-style: none;
}

.basebuild-shop-main .basebuild-shop-filter .basebuild-shop-filter-in ul .cat-item a {
  font-weight: 400;
  color: #1A1616;
  display: block;
  padding-bottom: 0.75rem;
}

.basebuild-shop-main .basebuild-shop-filter .basebuild-shop-filter-in ul .children {
  margin-left: 1rem;
}

.basebuild-shop-main .basebuild-shop-filter .basebuild-shop-filter-in .chosen-container-single .chosen-single {
  background: white;
  border-radius: 1px;
  border: 0;
}

.basebuild-shop-main .basebuild-shop-filter .woocommerce-sidebar .widget {
  margin-bottom: 3rem;
}

.basebuild-shop-main .basebuild-shop-filter .woocommerce-sidebar .widget .h4 {
  margin-bottom: 1.5rem;
}

.basebuild-shop-main .woocommerce ul.products li.product .woocommerce-loop-product__title {
  text-align: left;
}

.basebuild-shop-main .woocommerce .products .star-rating {
  margin: 0 auto;
  margin-top: 10px;
}

.basebuild-shop-main .woocommerce .products .star-rating:hover {
  color: #222;
}

.basebuild-shop-main .woocommerce ul.products li.product .price {
  text-align: left;
}

.woocommerce ul.products li.product a img {
  margin: 0 auto;
}

.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
  text-align: left;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  background-color: #000;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  background-color: #000;
}

.woocommerce .basebuild-products-container .products {
  display: flex;
  flex-wrap: wrap;
}

.woocommerce .basebuild-products-container .products li.product {
  width: auto;
  margin: 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-wrap: nowrap;
}

@media (min-width: 768px) {
  .woocommerce .basebuild-products-container .products li.product {
    flex-basis: 50%;
  }
}

@media (min-width: 1400px) {
  .woocommerce .basebuild-products-container .products li.product {
    flex-basis: 33.33%;
  }
}

.woocommerce .basebuild-products-container .products li.product .basebuild-add-to-cart-loop {
  margin-top: auto;
}

.woocommerce .basebuild-products-container .products li.product li.product:hover .star-rating {
  color: #000;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item {
  margin-left: 1rem;
  margin-right: 1rem;
  background: white;
  position: relative;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .onsale {
  left: auto;
  right: 0;
  margin: 0;
  background-color: #000;
  border-radius: 0;
  min-width: auto;
  min-height: auto;
  padding: 10px;
  line-height: 1;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .wishlist {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .basebuild-loop-content {
  padding: 1rem 1.5rem;
  position: relative;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .basebuild-loop-content a {
  color: #1A1616;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .basebuild-loop-content .basebuild-loop-cats a {
  font-size: 0.75rem;
  font-weight: 400;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .basebuild-loop-content .basebuild-add-to-cart-loop {
  text-align: left;
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .basebuild-loop-content .basebuild-add-to-cart-loop a {
  color: white;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .basebuild-loop-content ins {
  text-decoration: none;
}

.woocommerce .basebuild-products-container .products li.product .basebuild-loop-item .basebuild-loop-content del {
  display: none;
}

.woocommerce .basebuild-shop-single {
  background: transparent;
  position: relative;
}

.woocommerce .basebuild-shop-single .product {
  display: flex;
  flex-wrap: wrap;
}

.woocommerce .basebuild-shop-single .product .woocommerce-product-gallery {
  background: white;
  display: flex;
  align-self: flex-start;
  justify-content: center;
}

.woocommerce .basebuild-shop-single .product .onsale {
  left: auto;
  right: 0;
  top: 0;
  right: auto;
  margin: 0;
  background-color: #000;
  border-radius: 0;
  min-width: auto;
  min-height: auto;
  padding: 10px;
  line-height: 1;
}

.woocommerce .basebuild-shop-single .product .summary {
  margin-top: 0;
}

@media (min-width: 992px) {
  .woocommerce .basebuild-shop-single .product .summary {
    padding-left: 2rem;
  }
}

.woocommerce .basebuild-shop-single .product .summary .product_title {
  color: #1A1616;
  margin-bottom: 1.5rem;
}

.woocommerce .basebuild-shop-single .product .summary .woocommerce-product-details__short-description {
  margin-bottom: 2rem;
}

.woocommerce .basebuild-shop-single .product .summary ul.wcsatt-options-prompt-radios {
  padding: 0;
}

.woocommerce .basebuild-shop-single .product .summary .woocommerce-Price-amount {
  color: #1A1616;
}

.woocommerce .basebuild-shop-single .product .summary .price {
  font-weight: 600;
  color: #1A1616;
  float: left;
  padding-top: 0.75rem;
  padding-right: 3rem;
  line-height: 1;
}

.woocommerce .basebuild-shop-single .product .summary .price ins {
  text-decoration: none;
}

.woocommerce .basebuild-shop-single .product .summary .cart {
  display: flex;
  align-items: center;
}

@media (max-width: 1199.98px) {
  .woocommerce .basebuild-shop-single .product .summary .cart {
    width: 100%;
  }
}

.woocommerce .basebuild-shop-single .product .summary .cart button {
  border: 0;
  transition: all 350ms ease-in-out;
}

.woocommerce .basebuild-shop-single .product .summary .cart .quantity {
  margin-right: 1rem;
  padding: 0.5rem 1.5rem;
}

.woocommerce .basebuild-shop-single .product .summary .single-product-sku {
  font-weight: 400;
  font-size: 1rem;
  color: #888;
}

.woocommerce .basebuild-shop-single .product .summary .single-product-sku .sku-title {
  color: #1A1616;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs {
  margin-top: 4rem;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs {
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs:before {
  display: none;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs li.active {
  background: transparent;
  border: 0;
  border-bottom: 2px solid #1A1616;
  font-weight: 600;
  border-radius: 0;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs li.active:before,
.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs li.active:after {
  display: none;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs li.active a {
  font-weight: 600;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs li {
  color: #1A1616;
  border: 0;
  border-radius: 0;
  font-weight: 400;
  background: transparent;
  background-color: transparent;
  padding-left: 2rem;
  padding-right: 2rem;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs li:before,
.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs li:after {
  display: none;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs ul.tabs li a {
  font-weight: 400;
  color: #1A1616;
}

.woocommerce .basebuild-shop-single .product .woocommerce-tabs .comment-form {
  background: white;
  padding: 3rem;
  display: none;
}

.woocommerce .basebuild-shop-single .product .variations {
  display: flex;
}

.woocommerce .basebuild-shop-single .product .variations label {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
}

.woocommerce .basebuild-shop-single .product .variations .wc-default-select {
  font-size: 20px;
  height: 40px;
  border-radius: 0;
  border: 1px solid #3b3b3b;
  padding-left: 15px;
}

.woocommerce .basebuild-shop-single .product .variations .wc-default-select:focus-within,
.woocommerce .basebuild-shop-single .product .variations .wc-default-select:focus {
  border-radius: 0;
  border: 1px solid #3b3b3b;
  outline: none;
}

.woocommerce .basebuild-shop-single .product .variations label {
  font-weight: 600 !important;
}

.woocommerce .basebuild-shop-single .product .single_variation_wrap .price {
  display: inline-block;
  margin-bottom: 30px;
  padding: 0;
  background: transparent;
  font-size: 20px;
  color: #1A1616;
}

.woocommerce .basebuild-shop-single .product .single_variation_wrap .woocommerce-Price-amount {
  color: #1A1616;
}

.woocommerce .basebuild-shop-single .thumbnails img {
  width: auto !important;
  margin: 0 auto;
  max-height: 100px;
}

.woocommerce .basebuild-shop-single .product_meta {
  display: none;
}

@media (min-width: 768px) {
  .woocommerce .basebuild-shop-single .related .basebuild-products-container .products li.product {
    flex-basis: 50%;
  }
}

@media (min-width: 1400px) {
  .woocommerce .basebuild-shop-single .related .basebuild-products-container .products li.product {
    flex-basis: 25%;
  }
}

.single-product-main-image img {
  max-width: 100%;
  width: auto !important;
  margin: 0 auto;
  max-height: 400px;
}

.woocommerce #content div.product div.images,
.woocommerce div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .woocommerce #content div.product div.images,
  .woocommerce div.product div.images,
  .woocommerce-page #content div.product div.images,
  .woocommerce-page div.product div.images {
    flex-basis: 40%;
  }
}

.woocommerce #content div.product div.summary,
.woocommerce div.product div.summary,
.woocommerce-page #content div.product div.summary,
.woocommerce-page div.product div.summary {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .woocommerce #content div.product div.summary,
  .woocommerce div.product div.summary,
  .woocommerce-page #content div.product div.summary,
  .woocommerce-page div.product div.summary {
    flex-basis: 60%;
  }
}

.woocommerce-Reviews .commentlist {
  padding: 0;
}

.woocommerce-Reviews .commentlist li .comment-text {
  margin: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}

.woocommerce-Reviews .commentlist li .comment-text .woocommerce-review__author {
  color: #1A1616;
}

.woocommerce .basebuild-cart-row .basebuild-cart-table {
  font-weight: 600;
}

.woocommerce .basebuild-cart-row .basebuild-cart-table a {
  color: #1A1616;
}

.woocommerce .basebuild-cart-row .basebuild-cart-table .basebuild-cart-thumbnail a img {
  width: auto;
  max-height: 150px;
}

.woocommerce .basebuild-cart-totals {
  background: white;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals {
  float: none;
  width: 100%;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals .table {
  font-size: 1rem;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals .table > :not(caption) > * > * {
  padding-bottom: 1.5rem;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals th {
  text-transform: uppercase;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals .order-total th,
.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals .order-total td {
  padding-top: 1.5rem;
  border-top: 1px solid #666 !important;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals .order-total .amount {
  font-size: 1.6rem;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals tbody {
  border: 0 !important;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals .cart-totals-heading {
  padding-bottom: 1rem;
  border-bottom: 1px solid #666;
}

.woocommerce .basebuild-cart-totals .cart-collaterals .cart_totals a.checkout-button {
  margin-bottom: 0 !important;
}

.woocommerce .basebuild-cart-continue .btn,
.woocommerce .basebuild-cart-continue .range-headless .headless-product-card .headless-add-to-cart .headless-cart-button,
.range-headless .headless-product-card .headless-add-to-cart .woocommerce .basebuild-cart-continue .headless-cart-button,
.woocommerce .basebuild-cart-continue .comment-form input[type=submit],
.comment-form .woocommerce .basebuild-cart-continue input[type=submit],
.woocommerce .basebuild-cart-continue .search-form .search-submit,
.search-form .woocommerce .basebuild-cart-continue .search-submit {
  padding: 20px 15px;
  width: 100%;
}

.woocommerce-checkout .checkout-coupons .woocommerce-info {
  margin-bottom: 0 !important;
  border-top: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

.woocommerce-checkout .checkout-coupons .woocommerce-info:before {
  display: none;
}

.woocommerce-checkout .checkout-coupons .checkout_coupon {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0 auto;
  margin-top: 1rem;
  border-top: 0;
}

@media (min-width: 992px) {
  .woocommerce-checkout .checkout-coupons .checkout_coupon {
    width: 39%;
    min-width: 470px;
  }
}

.woocommerce-checkout .checkout-coupons .checkout_coupon .coupon-form-row {
  position: relative;
}

.woocommerce-checkout .checkout-coupons .checkout_coupon .coupon-form-row .input-text {
  height: 49px;
  padding: 13px 90px 10px 20px;
  width: 100%;
  border: 1px solid #666;
}

.woocommerce-checkout .checkout-coupons .checkout_coupon .coupon-form-row .input-text:focus-within,
.woocommerce-checkout .checkout-coupons .checkout_coupon .coupon-form-row .input-text:focus,
.woocommerce-checkout .checkout-coupons .checkout_coupon .coupon-form-row .input-text:active {
  border: 1px solid #666;
  outline: 0;
}

.woocommerce-checkout .checkout-coupons .checkout_coupon .coupon-form-row .button {
  background: none !important;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: auto !important;
  padding: 10px 20px !important;
  color: #1A1616;
}

.woocommerce-checkout .checkout-coupons .checkout_coupon .coupon-form-row .button i,
.woocommerce-checkout .checkout-coupons .checkout_coupon .coupon-form-row .button svg {
  color: #1A1616;
}

.woocommerce-checkout .basebuild-checkout {
  margin-top: 30px;
  margin-bottom: 2.5rem;
}

.woocommerce-checkout .basebuild-checkout input,
.woocommerce-checkout .basebuild-checkout .select2-container--default .select2-selection--single,
.woocommerce-checkout .basebuild-checkout textarea {
  height: 2.5rem;
  padding: 0.5rem;
  border: 1px solid #666;
  border-radius: 0;
}

.woocommerce-checkout .basebuild-checkout textarea {
  height: 4em;
}

.woocommerce-checkout .basebuild-checkout .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.5rem;
}

.woocommerce-checkout .basebuild-checkout .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: normal;
  padding-left: 0;
}

.woocommerce-checkout .basebuild-checkout .input-checkbox {
  height: auto;
}

.woocommerce-checkout .basebuild-checkout .woocommerce-additional-fields .woocommerce-input-wrapper {
  width: 100%;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .h4 {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .form-row-first,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .form-row-last,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .form-row-first,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .form-row-last,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .form-row-first,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .form-row-last {
  width: 50%;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .form-row-first .woocommerce-input-wrapper,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .form-row-last .woocommerce-input-wrapper,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .form-row-first .woocommerce-input-wrapper,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .form-row-last .woocommerce-input-wrapper,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .form-row-first .woocommerce-input-wrapper,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .form-row-last .woocommerce-input-wrapper {
  width: 100%;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .form-row,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .form-row,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .form-row {
  margin-bottom: 1rem;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .form-row label,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .form-row label,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .form-row label {
  width: 100%;
  line-height: 2;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .form-control,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .comment-form input[type=text],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper input[type=text],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .comment-form input[type=email],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper input[type=email],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .comment-form input[type=url],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper input[type=url],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .comment-form textarea,
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper textarea,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .search-form .search-field,
.search-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .search-field,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .form-control,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .comment-form input[type=text],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields input[type=text],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .comment-form input[type=email],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields input[type=email],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .comment-form input[type=url],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields input[type=url],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .comment-form textarea,
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields textarea,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .search-form .search-field,
.search-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .search-field,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .form-control,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .comment-form input[type=text],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields input[type=text],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .comment-form input[type=email],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields input[type=email],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .comment-form input[type=url],
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields input[type=url],
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .comment-form textarea,
.comment-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields textarea,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .search-form .search-field,
.search-form .woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .search-field {
  border: 1px solid #d2d2d2;
  border-radius: 2px;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper .woocommerce-input-wrapper,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields .woocommerce-input-wrapper,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields .woocommerce-input-wrapper {
  width: 100%;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-billing-fields__field-wrapper #billing_address_1_field,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-shipping-fields #billing_address_1_field,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-additional-fields #billing_address_1_field {
  margin-bottom: 5px;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details #ship-to-different-address {
  text-transform: none;
  margin-top: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  border: none;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .woocommerce-form__label-for-checkbox span {
  padding-left: 10px;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-row .basebuild-checkout-details .shipping_address {
  display: none;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .woocommerce-checkout-review-order {
  background: white;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .h4 {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table {
  border-radius: 0;
  border: 0;
  border-collapse: collapse;
  font-size: 1rem;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table thead {
  background: transparent;
  border: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table .product-name {
  width: 55%;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table .product-total {
  text-align: right;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table .cart-subtotal th,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table .order-total th,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table .shipping th {
  width: 55%;
  text-transform: uppercase;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table .cart-subtotal th,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shop_table .cart-subtotal td {
  padding-top: 3rem;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .cart_item,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .cart-subtotal,
.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .shipping {
  color: #1A1616;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .order-total {
  color: #1A1616;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review #payment {
  background: transparent;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .place-order .button {
  padding: 20px 15px;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .payment_method_stripe .form-row {
  margin-bottom: 0 !important;
  padding: 0;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .payment_method_stripe label[for=payment_method_stripe] {
  font-weight: 700;
  text-transform: uppercase;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .payment_method_stripe label[for=stripe-card-element] {
  width: 100%;
  line-height: 1;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .payment_method_stripe .stripe-card-group {
  width: 100%;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .payment_method_stripe label[for=stripe-exp-element] {
  width: 100%;
  line-height: 1;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .payment_method_stripe .wc-stripe-elements-field {
  width: 100%;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .payment_method_stripe label[for=stripe-cvc-element] {
  width: 100%;
  line-height: 1;
}

.woocommerce-checkout .basebuild-checkout .basebuild-checkout-review .payment_method_stripe .wc-stripe-elements-field {
  height: 40px;
  padding-top: 11px;
  border: 1px solid #d2d2d2;
}

.woocommerce-checkout .basebuild-checkout .woocommerce-privacy-policy-text p {
  font-size: 12px;
  line-height: 1;
  text-align: center;
}

.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th {
  border-top: 0;
}

.cannabis-menu-page .cannabis-menu-content p:last-of-type {
  margin-bottom: 0;
}

.age-gate-modal-backdrop-image {
  background-size: cover !important;
  background-position: center center !important;
}

.modal-background-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-background-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.age-gate-modal-backdrop-video.show {
  opacity: 1 !important;
}

.age-gate-modal {
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.age-gate-modal .modal-content {
  border-radius: 3px;
  background-color: #fff;
  border: none;
}

.age-gate-modal .modal-content .small,
.age-gate-modal .modal-content small {
  font-size: 14px;
  line-height: 14px;
}

.age-gate-modal .age-gate-modal-logo {
  text-align: center;
}

.age-gate-modal .age-gate-modal-logo img {
  max-width: 65%;
}

.age-gate-modal .h5 {
  font-weight: bold;
}

.age-gate-modal .modal-header {
  border: 0;
}

.age-gate-modal .modal-footer {
  border: 0;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.age-gate-modal .modal-body {
  padding-top: 0;
  padding-bottom: 0;
  font-family: "AkzidenzGrotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.age-gate-modal .age-gate-denied-body {
  display: none;
}

.range-headless {
  font-family: AkzidenzGrotesk, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.range-headless div,
.range-headless * {
  border-radius: 0 !important;
}

.range-headless .headless-shortcode-title {
  font-family: Walden, Tinos, Georgia, Arial, serif !important;
  font-size: 30px;
}

.range-headless .headless-strain-icon {
  font-size: 16px;
}

.range-headless .headless-product-card {
  box-shadow: none !important;
  border: 2px solid #9db09b;
  padding: 0 !important;
}

.range-headless .headless-product-card .headless-product-title {
  font-family: Walden, Tinos, Georgia, Arial, serif !important;
  line-height: 1;
  margin-bottom: 10px !important;
  padding-left: 15px;
  padding-right: 15px;
}

.range-headless .headless-product-card .headless-product-title a {
  color: black !important;
  font-weight: bold !important;
  font-size: 26px;
}

.range-headless .headless-product-card .headless-add-to-cart {
  background: black;
  padding: 21px !important;
  margin-left: -15px;
  margin-right: -15px;
  box-sizing: content-box;
}

.range-headless .filter-sidebar-title {
  font-family: Walden, Tinos, Georgia, Arial, serif !important;
}

.range-headless .filter-sidebar-module {
  background: #000 !important;
  color: white !important;
  border: 0 !important;
}

.range-headless .filter-sidebar-options label {
  margin-left: 10px;
  font-size: 17px;
}

.range-headless .headless-product-brands {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.range-headless .headless-product-brands,
.range-headless .headless-product-categories {
  color: #9db09b !important;
}

.range-headless .headless-product-bottom {
  margin-left: 15px;
  margin-right: 15px;
}

.range-headless .headless-hr {
  border-color: #9db09b !important;
}

.range-headless .headless-load-more {
  margin-top: 15px;
}

