// Backgrounds
.background-image {
	background-color: $white;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.background-white {
	background-color: #fff;
}
.background-color-trans-white {
	background-color: rgba(255, 255, 255, 0.75);
}
.background-black {
	background-color: #000;
	&.background-gradient {
		background: #000;
		background: linear-gradient(145deg, #000 0%, lighten(#000,15%) 100%);
	}
}
.background-trans-black {
	background-color: rgba(0, 0, 0, 0.75);
}
.background-primary {
	background-color: $brand-primary;
	&.background-gradient {
		background: $brand-primary;
		background: linear-gradient(145deg, $brand-primary 0%, lighten($brand-primary,15%) 100%);
	}
}
.background-secondary {
	background-color: $brand-secondary;
	&.background-gradient {
		background: $brand-secondary;
		background: linear-gradient(145deg, $brand-secondary 0%, lighten($brand-secondary,15%) 100%);
	}
}
.background-dark {
	background-color: $dark;
	&.background-gradient {
		background: $dark;
		background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%);
	}
}
.background-gray {
	background-color: $gray;
	&.background-gradient {
		background: $gray;
		background: linear-gradient(145deg, $gray 0%, lighten($gray,15%) 100%);
	}
}
.background-accent {
	background-color: $accent;
	&.background-gradient {
		background: $accent;
		background: linear-gradient(145deg, $accent 0%, lighten($accent,15%) 100%);
	}
}

.background-accent-2 {
	background-color: $accent-2;
	&.background-gradient {
		background: $accent-2;
		background: linear-gradient(145deg, $accent-2 0%, lighten($accent-2,15%) 100%);
	}
}

.background-cream {
	background-color: $cream;
	&.background-gradient {
		background: $cream;
		background: linear-gradient(145deg, $cream 0%, lighten($cream,15%) 100%);
	}
}

.background-light {
	background-color: $light;
	&.background-gradient {
		background: $light;
		background: linear-gradient(145deg, $light 0%, lighten($light,15%) 100%);
	}
}

// Font Colors
.color-primary {
	color: $brand-primary;
}
.color-secondary {
	color:$brand-secondary;
}
.color-body {
	color: $body-font;
}
.color-light {
	color:$light;
}
.color-cream {
	color:$cream;
}
.color-accent-2 {
	color:$accent-2;
}
.color-dark {
	color:$dark;
}
.color-gray {
	color:$gray;
}
.color-accent {
	color:$accent;
}
.color-white,.white {
	color:white!important;
}
.color-black {
	color:black!important;
}
