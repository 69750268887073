.jumbo-link-box-link {
	height: 250px;
	min-height: 40vh;
	max-height: 600px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	transition: all .2s ease-in;
	
	.jumbo-link-box-bg {
		margin: calc(var(--bs-gutter-x)*-0.5) 0px;
		transition: all .2s ease-in;
	}
	
	&:hover {
		height: calc(250px + 2rem);
		min-height: calc(40vh + 2rem);
		max-height: calc(600px + 2rem);
		margin-top: 0;
		margin-bottom: 0;

		.jumbo-link-box-bg {
			margin: calc(var(--bs-gutter-x)*-0.5) calc(var(--bs-gutter-x)*-0.5) 0px;
		}	
	}
}