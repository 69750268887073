.link-box-link {
	@include media-breakpoint-up(lg) {
		.link-box-label {
			font-size: 125%;
			line-height: 105%;
		}
		
		.link-box-image-holder {
			padding: 10px;
			transition: all 0.2s ease-in;
		}
		
		&:hover {
			.link-box-image-holder {
				padding: 0;
			}		
		}
	}
}

