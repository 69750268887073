// Default Slider Styles

.newstandard-image-slider {
	
	.splide__slide {
		height: 85vh;
		min-height: 40rem;
		max-height: 900px;
    }
    
    .splide__arrow {
    	background:transparent;
    	top: auto;
    	bottom: 20px;
    	svg {
    		fill:$white;
    	}
    }
    
    .splide__pagination {
    	display: none;
    }


	.display-1 {
	
	  line-height: 1;
		
	  @include media-breakpoint-up(xl) {
		font-size: 8rem;
	  }
	  @include media-breakpoint-up(xxl) {
		font-size: 9rem;
	  }
	}

	.display-2 {
	  
	  line-height: 1;
	  
	  @include media-breakpoint-up(xl) {
		font-size: 6rem;
	  }
	  @include media-breakpoint-up(xxl) {
		font-size: 7rem;
	  }
	}
  
	.display-3 { 
	  line-height: 1;
	}
}	

.newstandard-image-slider-colorbar {
	background-color: $accent-2;
	background: linear-gradient(to right,
		$black 0%,
		$black 40%,
		$accent-3 40.00000000001%,
		$accent-3 55%,
		$light 55.00000000001%,
		$light 70%,
		$accent 70.00000000001%,
		$accent 85%,
		$black 85.00000000001%,
		$black 100%
	);
}

.range-splider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow {
        background:transparent;
        opacity:1;
        svg {
          fill:$white;
          color:$white;
        }
      }
      .splide__arrow--prev {
        svg {
          transform:none;
        }
      }
      .splide__arrow--next {
      }
    }
  }
  .splide__pagination {
    @media-breakpoint-down(md) {
      display:none;
    }
    .splide__pagination__page.is-active {
      background:$brand-primary;
    }
  }
  &.blurred-slides {
    .splide__slide {
      &:before {
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.5);
        z-index: 1;
        backdrop-filter: saturate(180%) blur(2px);
        transition:all ease-in-out 350ms;
        opacity:1;
      }
      &.is-active {
        &:before {
          content:'';
          opacity:0;
        }
      }
    }
  }
}

// Thumbnail Slider
.range-splider-thumbnails {
  .splide__track {
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    background:white;
    .splide__list {
      .splide__slide {
        opacity:0.3;
        &.is-active {
          border:0!important;
          opacity:1;
        }
      }
    }
  }
}

// Logo Banner Slider
.rpb-logo-slider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow--prev {
        left:0;
      }
      .splide__arrow--next {
        right:0;
      }
    }
    .splide__track {
      margin-left:3rem;
      margin-right:3rem;
    }
  }
}