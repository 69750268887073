/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.comment-form input[type=email], .comment-form input[type=text], .comment-form input[type=url], .comment-form textarea, .form-control, .search-form .search-field {
	font-size: 22px;
}

label.gfield_label, legend.gfield_label {
  
  .gfield_required {
  	color: $accent;
  }
}

.gform_wrapper .gfield_label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top:8px;
  display: inline-block;
  padding: 0;
}

.gform_wrapper .gfield input.large, .gform_wrapper .gfield select.large, .gform_wrapper textarea {
  width: 100%;
  font-size: 15px;
  padding: 8px;
  margin-bottom: 0;
  margin-top: 0;
  margin:0;
}

.gform_wrapper .gform_footer input {
  background: #000;
  border: 4px solid #000;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-family: 'AkzidenzGrotesk','Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: auto;
  font-weight: 600;
  line-height: 1;
  padding: 0.5rem 1.5rem 0.4rem;
  text-align: center;
  -webkit-transition-duration: .35s;
  -o-transition-duration: .35s;
  transition-duration: .35s;
}

.gfield_validation_message {
  color:red;
  font-size:16px;
  font-weight:bold;
}