.locations-grid-location {
	height: 150px;
	min-height: 20vh;
	max-height: 400px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	transition: all .2s ease-in;
	
	.locations-grid-location-bg {
		margin: calc(var(--bs-gutter-x)*-0.5) 0px;
		transition: all .2s ease-in;
	}
	
	&:hover {
		height: calc(150px + 2rem);
		min-height: calc(20vh + 2rem);
		max-height: calc(400px + 2rem);
		margin-top: 0;
		margin-bottom: 0;

		.locations-grid-location-bg {
			margin: calc(var(--bs-gutter-x)*-0.5) calc(var(--bs-gutter-x)*-0.5) 0px;
		}	
	}
}